import React, { useState, useEffect } from 'react';
import JsSIP from 'jssip/lib/JsSIP';
import "../../css/softphone.css";
import { connect } from 'react-redux';
import ringtone from './ringtone.mp3';
import { store } from '../..';
import EatWithMeActions from '../../actions/EatWithMeActions';
import { getHomeDeliveryTableNumber, lockTable } from '../../js/admin';
import { afterLockTable, selectNewOrders } from '../../js/tables-overview';
import tableLayout from '../../js/table-layout';
import { I18n } from 'react-redux-i18n';
const audio = new Audio(ringtone);

export const setStatus = status => {
    store.dispatch({ type: "ipphonestatus", status: status })
}
export const setPhoneNumber = number => {
    store.dispatch({ type: "ipphonephonenumber", number: number })
}
export const setSession = session => {
    store.dispatch({ type: "ipphonesession", session: session })
}
export const setIncomingCallInProgress = status => {
    store.dispatch({ type: "ipphoneincomingcallinprogress", status: status })
}
export const setCallInProgress = status => {
    store.dispatch({ type: "ipphonecallinprogress", status: status })
}
export const setPhoneNumberToCall = number => {
    store.dispatch({ type: "ipphonenumbertocall", number: number })
}
export const setMakeACall = status => {
    store.dispatch({ type: "ipphonemakeacall", status: status })
}
/*
export const setMakeACall = status => {
    store.dispatch({ type: "ipphonemakeacall", status: status })
}*/
export const onIncomingCall = () => {
    audio.loop = true; // Loop the audio to simulate continuous ringing
    audio.play().catch((error) => console.error('Error playing ringtone:', error));
}

export const onCallAnswered = () => {
    audio.pause(); // Stop the ringing tone
    audio.currentTime = 0; // Reset the audio
}

export const makeCall = (phone, number) => {

    try {
        const session = phone.call((number).replaceAll("\+", ""), {
            mediaConstraints: { audio: true, video: false },
        });

        // Handle incoming media
        session.connection.addEventListener('track', (event) => {
            console.log('Incoming track:', event.track.kind);

            if (event.track.kind === 'audio') {
                const audio = new Audio();
                audio.srcObject = event.streams[0];
                audio.autoplay = true; // Automatically play the audio
                audio.play().catch((error) => {
                    console.error('Error playing audio:', error);
                });
            }
        });

        session.on('accepted', () => {
            setCallInProgress("outgoing-inprogress");
            //setStatus('Call in Progress');
        });

        session.on('rejected', () => {
            //setStatus('Idle');
            setCallInProgress(false);
            setMakeACall(false);
        });
        session.on('failed', () => {
            //setStatus('Idle');
            setCallInProgress(false);
            setMakeACall(false);
            setPhoneNumberToCall("");
        });

        session.on('ended', () => {
            //setStatus('Idle');
            setCallInProgress(false);
            setMakeACall(false);
            setPhoneNumberToCall("");
        });
        return session;
    } catch (Ex) {
        console.error(Ex, Ex);
    }

};

const Softphone = (props) => {
    const makeCall = () => {

        if (!props.ipphonemakeacall) {
            setMakeACall(true);
            return;
        }
        if (!props.ipphonenumbertocall) {
            setMakeACall(false);
            return;
        }

        if (!props.ipphonecallinprogress) {
            try {

                const session = props.phone.call((props.ipphonenumbertocall).replaceAll("\+", ""), {
                    mediaConstraints: { audio: true, video: false },
                });
                setSession(session);
                //setStatus('Calling...');
                setCallInProgress("outgoing");

                // Handle incoming media
                session.connection.addEventListener('track', (event) => {
                    console.log('Incoming track:', event.track.kind);

                    if (event.track.kind === 'audio') {
                        const audio = new Audio();
                        audio.srcObject = event.streams[0];
                        audio.autoplay = true; // Automatically play the audio
                        audio.play().catch((error) => {
                            console.error('Error playing audio:', error);
                        });
                    }
                });

                session.on('accepted', () => {
                    setCallInProgress("outgoing-inprogress");
                    //setStatus('Call in Progress');
                });

                session.on('rejected', () => {
                    //setStatus('Idle');
                    setCallInProgress(false);
                    setMakeACall(false);
                });
                session.on('failed', () => {
                    //setStatus('Idle');
                    setCallInProgress(false);
                    setMakeACall(false);
                    setPhoneNumberToCall("");
                });

                session.on('ended', () => {
                    //setStatus('Idle');
                    setCallInProgress(false);
                    setMakeACall(false);
                    setPhoneNumberToCall("");
                });
            } catch (Ex) {
                console.error(Ex);
            }
        }

    };


    const endCall = () => {
        if (props.session) {
            props.session.terminate();
            setSession(undefined);
            //setStatus('Idle');
            setCallInProgress(false);
            setIncomingCallInProgress(false);
            setMakeACall(false);
            setIncomingCallInProgress(false);
        }
    };
    const answerCall = () => {
        if (props.session) {
            onCallAnswered();
            setIncomingCallInProgress(false);
            setCallInProgress("incoming-inprogress");
            setPhoneNumber("");
            props.session.answer({
                mediaConstraints: { audio: true, video: false },
            });
            // Handle incoming media
            props.session.connection.addEventListener('track', (event) => {
                console.log('Incoming track:', event.track.kind);

                if (event.track.kind === 'audio') {
                    const audio = new Audio();
                    audio.srcObject = event.streams[0];
                    audio.autoplay = true; // Automatically play the audio
                    audio.play().catch((error) => {
                        console.error('Error playing audio:', error);
                    });
                }
            });
            (store || props.store).dispatch(EatWithMeActions.phoneselected(props.iphonephonenumber));
            tableLayout.selectTable(getHomeDeliveryTableNumber());
            lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
            selectNewOrders();

        }
    };

    var callerName = props.ipphonephonenumber;
    var address;

    /*
    if (props.makeACall) {
        callANumber("")
        makeCall(props.makeACall);
    }*/

    if (props?.voipphone) {
        const contact = props.ringing.find(p => p.number.replaceAll("\+", "") == props.ipphonephonenumber);

        if (contact && contact?.details?.name) {
            callerName = (contact?.details?.surname + " " + contact?.details?.name).trim();
            if (contact?.details?.address) {
                address = contact?.details?.postcode + " " + contact?.details?.town + ", " + contact?.details?.address
            }
        }
        return (
            <div id="softphone">
                {props.iphonestatus === "Connected" ? (
                    <React.Fragment>
                        <button class={"icon-phone btn btn-positive call " + (props.ipphonecallinprogress === "outgoing" ? "calling" : "")} style={{ color: "green" }} onClick={makeCall} disabled={props.ipphonecallinprogress !== false}>
                            {props.ipphonemakeacall &&
                                <input
                                    type="text"
                                    placeholder={I18n.t("admin_local.enter_phone_number")}
                                    value={props.ipphonenumbertocall}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => setPhoneNumberToCall(e.target.value)}
                                />}
                        </button>
                        <button class="icon-phone btn btn-positive phoneringing" onClick={answerCall} disabled={props.ipphonecallinprogress !== "incoming"}>
                            <div>
                                {callerName}<br />
                                <span style={{ fontSize: "50%" }}>{address}</span>
                            </div>
                        </button>
                        <button class="icon-phone btn btn-positive hangup" style={{ color: "red" }} onClick={endCall} disabled={props.ipphonecallinprogress === false}>
                        </button>
                    </React.Fragment>) :
                    <button class="icon-phone btn btn-positive call" style={{ color: "green" }} onClick={makeCall} disabled={true}>
                        {I18n.t("admin_local.voipphone-" + props.ipphonestatus)}
                    </button>}
            </div>
        );
    }
    return null;
};

export const callANumber = number => {
    store.dispatch(EatWithMeActions.makeACall(number));
}

const mapStateToProps = (props, state) => {
    if (props.rootReducer?.myStatus?.restaurant_settings && props.rootReducer.myStatus.restaurant_settings["enabled-features"]?.voipphone?.enabled) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
            deliveryUsers: props.rootReducer.deliveryUsers,
            ringing: props.rootReducer.ringing,
            phone: props.rootReducer.userAgent,
            phoneSelected: props.rootReducer.myStatus.restaurant_settings["enabled-features"]?.voipphone?.numbers.find(n => n.name === props.rootReducer.voipphoneSelected),
            makeACall: props.rootReducer.makeACall,
            session: props.rootReducer.ipphonesession,
            iphonestatus: props.rootReducer.ipphonestatus,
            ipphonephonenumber: props.rootReducer.ipphonephonenumber,
            ipphonecallinprogress: props.rootReducer.ipphonecallinprogress,
            ipphoneincomingcallinprogress: props.rootReducer.ipphoneincomingcallinprogress,
            phone: props.rootReducer.userAgent,
            ipphonenumbertocall: props.rootReducer.ipphonenumbertocall,
            ipphonemakeacall: props.rootReducer.ipphonemakeacall,
            voipphone: true
        };
    }
    return {};
};

export default connect(mapStateToProps)(Softphone);
