import React, { Component } from "react";
import FlexGrowSpace from "../widgets/FlexGrowSpace";
import BackButton from "./menus/BackButton";

class MenuItemHeader extends Component {
  state = {};
  render() {
    return (
      <div className="menuItemHeader-1" >
        <BackButton {...this.props} style={{}} />
        <FlexGrowSpace />
        <span className="myTitle">{this.props.header}</span>
      </div>
    );
  }
}

export default MenuItemHeader;
