import React, { Component } from "react";
import "@fullcalendar/common/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/timeline/main.css";
import "@fullcalendar/resource-timeline/main.css";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import $ from "jquery";
import auth, { post, messageDialog, ajaxCallStart, featureEnabled, get, ajaxCallEnd, stopPool, startBackgroundLocationTraking, stopBackgroundLocationTraking, getBackgroundLocation } from "../../js/auth";
import orderListUtil, { update, getTableOccupation, updateOrderList, updateHooks, updateTableMates, getOrderById } from "../../js/order-list-util";
import admin, { getMenuItem, getProductionLine, hasRole, resultHandler, selectTableNumberSelected, selectTableOccupationSelected } from "../../js/admin";
import "./style.css";
import { getTable } from "../../reducers/rootReducer";
import { allstates, allstates_but_cancelled } from "../../js/order-grouping";
import { userChanged, updateHeadings, tableMatePaid, ready, hideEditTable, stopIntervals, updateFooterButtons, showEditTable, hideSelectNewOrders, selectTableOccupation } from "../../js/tables-overview";
import ConfirmationDialog from "./ConfirmationDialog";
import EatWithMeActions from "../../actions/EatWithMeActions";
import { store } from "../..";
import { getPaymentMethod } from "../../js/tableMatePaid";

class ReservationComponent extends Component {
  state = {
    view: "listWeek",
    slotDuration: "00:15",
    date: moment().startOf("day").toDate(),
    selectedEvents: [],
    email: "",
    phone: "",
    comment: "",
    forward: true,
    deliveries: {},
    selectedTableOccupations: [],
  };

  constructor(props) {
    selectTableNumberSelected(0);
    super(props);
    this.myRef = React.createRef();
    this.myTopRef = React.createRef();
    this.state = {
      ...this.state,
      ...this.getSsEe(),
      booking: {
        start: moment(this.timeSelected),
        end: moment(this.timeSelected).add(2, "Hour"),
        groupId: "booking"
      }
    };
  }

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;
    orderListUtil.noSelector = true;
    orderListUtil.showCloseButton = true;
    //orderListUtil.quickActions = true;

    if (myStatus && myStatus.restaurant_id) {
      auth.ignoreOrderSequenceNumber = true;
      ready(this.props, this, this.serverSideMessageHandler);
      orderListUtil.tablesOverview = false;
      this.checkDayOpen();
      this.setState({ initialized: true });
    }
    orderListUtil.kioskMode = false;

    admin.userChanged = userChanged;
    admin.hideEditTable = hideEditTable;
    window.hideEditTable = () => $('#main').hide();
    delete localStorage._editTable;

    const instance = this;

    auth.resize = function () {
      instance.myRef.current && instance.myRef.current.getApi().updateSize();
    }

  }

  serverSideMessageHandler = (data, recovery = false) => {
    if (data.type === "refresh") {
      if (data.message === "courier position update" || data.message === "delivery update")
        return;
      if (data.message === "order changed" || data.message === "order state changed") {
        update(data.data);
        return;
      } else if (data.message === "courier update") {
        const customers = data.data;
        console.log(customers);
        const c = customers.find(c => c.id === auth.myStatus.id);
        if (c) {
          auth.myStatus.courierState = c.courierState;
          auth.myStatus.tableOccupation = c.tableOccupation;
          store.dispatch(EatWithMeActions.resetMyStatus({ ...auth.myStatus }));
        }
      } else {
        if (data.data) {
          update(data.data);
        }
        /*
        admin.getMyStatus();
        admin.resync([], (data) => { updateTableMates().done(() => updateOrders(data.orders)) }, recovery);
        */
      }
    }
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;
    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      ready(this.props, this, this.serverSideMessageHandler);
      this.checkDayOpen();
      this.setState({ initialized: true });
    }
    $('.fc-row.fc-week.fc-widget-content').scrollTop(this.topScroll);

  }

  componentWillUnmount() {
    auth.ignoreOrderSequenceNumber = false;
    stopIntervals();
    this.setState({ initialized: false });
    delete admin.userChange;
    delete admin.hideEditTable;
    delete window.hideEditTable;
    //orderListUtil.quickActions = false;
    stopPool();
  }

  checkDayOpen = (first = false) => {
    if (featureEnabled("ntak")) {
      const h = this.props.myStatus.restaurant_settings["business-day-starts-at"];
      if (this.state.open === undefined || ((!first || !this.state.doneOnce) && this.state.date !== moment().add(-h, "hour").startOf("day").valueOf())) {
        //this.setState({ open: false })
        ajaxCallStart("findByRestaurantAndDate");
        get("api/businessDays/search/findByRestaurantAndDate?restaurant=" + this.props.myStatus.restaurant_id + "&date=" + moment().add(-h, "hour").startOf("day").format("yyyy-MM-DD HH:mm:ss ZZ").replace("+", "%2B"), undefined, undefined, undefined, false).done(d => {
          ajaxCallEnd("findByRestaurantAndDate");
          this.setState({ doneOnce: true, open: d._embedded.businessDays.length > 0, date: d._embedded.businessDays.length > 0 ? moment().startOf("day").valueOf() : null })
        })
      }
    } else {
      this.setState({ open: true })
    }
  }

  getSsEe(date) {
    var ss = moment(date).startOf("day");
    ss.add(this.props.myStatus.restaurant_settings["business-day-starts-at"], "Hour");

    var ee = moment(date).startOf("day");
    ee.add(1, "Day").add(-1, "Second");
    if (this.props.myStatus.restaurant_settings["business-day-starts-at"] > this.props.myStatus.restaurant_settings["business-day-ends-at"])
      ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"], "Hour");
    else ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"] - 24, "Hour");
    return {
      ss: ss,
      ee: ee
    };
  }

  // Function to handle checkbox selection
  handleCheckboxChange(e, tableOccupation) {
    const { selectedTableOccupations } = this.state;

    if (e.target.checked) {
      // Add the item to the selected list
      this.setState({
        selectedTableOccupations: [...selectedTableOccupations, tableOccupation],
      });
    } else {
      // Remove the item from the selected list
      this.setState({
        selectedTableOccupations: selectedTableOccupations.filter(
          (item) => item !== tableOccupation
        ),
      });
    }
  }
  // Function to assign selected occupations to a courier
  assignToCourier = () => {
    const { selectedTableOccupations } = this.state;
    console.log("Assigning to courier:", selectedTableOccupations);

    const courier = auth.myStatus.id;
    let arr = [];
    let arr2 = [];
    const date = this.state.today;
    const occupations = selectedTableOccupations;

    var delivery = 0;
    const orderIds = [];

    if (!occupations.length) {
      const c = this.props.deliveryUsers.find(u => u.id === Number(courier));
      if (c.position_latitude) {
        //show where is and was the courier
        this.setState({ center: { latitude: Number(c.position_latitude), longitude: Number(c.position_longitude) } });
      }
    }

    const assign = (occupations) => {
      const occupation = occupations.shift();
      occupation.orderIds.forEach(id => orderIds.push(id));
      if (occupations.length) {
        assign(occupations);
        return;
      }

      if (orderIds) {
        get("adminService/" + this.props.myStatus.restaurant_id + "/assignCourier/" + delivery + "/" + occupation.tableOccupation + "?orderIds=" + orderIds + "&courier=" + courier).done((data) => {
          update(data);
          delivery = data.orders[0].deliveredById;
          this.setState({ changed: moment() })
        })
      }

    }
    if (occupations.length)
      assign(occupations);

    // Add your logic here to assign the selected items to a courier
    // For example, you might send the selected items to an API
  }

  render() {
    var minTime = 24, maxTime = 0;
    const maxHour = {};
    const minHour = {};
    var timeslots = this.props.myStatus.restaurant_settings['enabled-features'].pickup['available-timeslots'];
    var today = moment().startOf("day").valueOf();

    if (timeslots)
      timeslots.forEach(timeslot => {
        minTime = Math.min(minTime, timeslot.from);
        maxTime = Math.max(minTime, timeslot.to);
        var day = timeslot.day;
        if (day === 7)
          day = 0;
        minHour[day] = minHour[timeslot.day] ? Math.min(minHour[timeslot.day], timeslot.from) : timeslot.from;
        maxHour[day] = maxHour[timeslot.day] ? Math.max(maxHour[timeslot.day], timeslot.to) : timeslot.to;
      });
    timeslots = this.props.myStatus.restaurant_settings['enabled-features'].homedelivery['available-timeslots'];
    if (timeslots)
      timeslots.forEach(timeslot => {
        minTime = Math.min(minTime, timeslot.from);
        maxTime = Math.max(minTime, timeslot.to);
        var day = timeslot.day;
        if (day === 7)
          day = 0;
        minHour[day] = minHour[timeslot.day] ? Math.min(minHour[timeslot.day], timeslot.from) : timeslot.from;
        maxHour[day] = maxHour[timeslot.day] ? Math.max(maxHour[timeslot.day], timeslot.to) : timeslot.to;
      });
    minTime = minTime + ":00:00";
    maxTime = maxTime + ":00:00";

    const clazz = "fc-toolbar fc-footer-toolbar" + (this.props.qrActionsOnly === true ? ' qrActionsOnly' : '');

    this.topScroll = $('.fc-row.fc-week.fc-widget-content').scrollTop();

    const selected = this.state.selected;

    const idle = !this.props?.orders?.tables || !this.props.orders.tables.find(t => t.courierId == auth.myStatus.id && moment(t.date).startOf('day').valueOf() === today && this.orderState(t) === "delivering");

    const tableOccupations = this?.props?.tableOccupations && this.props.orders && hasRole(auth.myStatus.roles, ["courier"]) ? this.props.orders.tables.filter(o => o.courierId === auth.myStatus.id && moment(o.date).startOf('day').valueOf() === today).reduce(this.groupByDelivery, []).sort((a, b) => a.toDeliveredById - b.toDeliveredById) : [];

    const unAssignedTableOccupations = this?.props?.tableOccupations && this.props.orders && hasRole(auth.myStatus.roles, ["waiter", "admin"]) ? this.props.orders.tables.filter(o => o.courierId === 0 && moment(o.date).startOf('day').valueOf() === today).reduce(this.groupByDelivery, []).sort((a, b) => a.toDeliveredById - b.toDeliveredById) : [];

    const tableOccupationsToDeliver = this?.props?.tableOccupations && this?.props?.orders && this.props.orders && hasRole(auth.myStatus.roles, ["courier"]) ?
      this.props.orders.tables.filter(o => this.orderState(o) !== "delivered" && o.courierId == auth.myStatus.id && moment(o.date).startOf('day').valueOf() === today).reduce(this.groupByDelivery, []).sort((a, b) => a.toDeliveredById - b.toDeliveredById) : [];

    return (
      <React.Fragment>

        <div id="main" className={clazz} style={{ display: "none", position: "absolute", width: "100%", height: "100%", zIndex: 100, background: "white", margin: 0 }}></div>
        {this.state.showUpdatePositionDialog && <ConfirmationDialog show={!!this.state.showUpdatePositionDialog} {...this.state.showUpdatePositionDialog} onConfirm={this.confirmUpdatePosition} onCancel={() => this.setState({ showUpdatePositionDialog: false })} />}
        <div className="qrActionsOnly" style={{ marginBottom: "-1rem" }}>
          <div className="fc-left">
            {hasRole(auth.myStatus.roles, ["admin", "waiter", "courier"]) ?
              (!tableOccupationsToDeliver.length && (auth.myStatus.courierState === "null" || auth.myStatus.courierState === "IDLE" || (auth.myStatus.courierState !== "WAITING" && auth.myStatus.courierState !== "IDLE")) ?
                <React.Fragment>
                  <button onClick={() => this.setCourierState('WAITING')} className="btn btn-info qr-action fc-button fc-button-primary icon-hourglass">{I18n.t("admin_local.courier_ready")}</button>
                  {auth.myStatus.courierState !== "OFF" ?
                    <button onClick={() => this.setCourierState('OFF')} className="btn btn-info qr-action fc-button fc-button-primary icon-cancel">{I18n.t("admin_local.courier_off")}</button>
                    : null}
                </React.Fragment>
                :
                auth.myStatus.courierState === "WAITING" ?
                  <React.Fragment>
                    <button onClick={() => this.setCourierState('IDLE')} className="btn btn-info qr-action fc-button fc-button-primary icon2-pause-outline">{I18n.t("admin_local.courier_pause")}
                    </button>
                  </React.Fragment> :
                  null)
              : <button onClick={() => this.setCourierState('OFF')} className="btn btn-info qr-action fc-button fc-button-primary icon-cancel">{I18n.t("admin_local.courier_off")}</button>}
            <div style={{ width: "100%" }} />
            {hasRole(auth.myStatus.roles, ["admin", "waiter"]) ?
              <button onClick={() => this.scanqrcode('open')} className="btn btn-primary qr-action bg-color-open fc-button fc-button-primary icon-qrcode">{I18n.t("admin_local.open")}
              </button> : null}
            {hasRole(auth.myStatus.roles, ["admin", "waiter", "courier"]) ?
              <button onClick={() => this.scanqrcode('delivering')} className="btn btn-primary qr-action bg-color-delivered fc-button fc-button-primary icon-qrcode">{I18n.t("local.delivering")}
              </button> : null}
            {hasRole(auth.myStatus.roles, ["admin", "waiter", "courier"]) ?
              <button onClick={() => this.scanqrcode('delivered')} className="btn btn-primary qr-action bg-color-delivered fc-button fc-button-primary icon-qrcode">{I18n.t("local.delivered")}
              </button> : null}
            {hasRole(auth.myStatus.roles, ["admin", "waiter", "courier"]) ?
              <button onClick={() => this.scanqrcode('payment')} className="btn btn-primary qr-action bg-color-pay fc-button fc-button-primary icon-qrcode">{I18n.t("admin_local.payment")}
              </button> : null}
          </div>
        </div>
        <div readyState={this.readyState()} className="fc fc-toolbar fc-footer-toolbar">
          <div>
            {this.props.tableOccupations && this.props.orders && hasRole(auth.myStatus.roles, ["courier"]) ?
              tableOccupations.sort(this.sortTableOccupations).map(tt => {
                const ready = tt.tableOccupations.find(t => this.orderState(t) === this.readyState());
                const delivering = tt.tableOccupations.find(t => this.orderState(t) === "delivering");
                return (
                  <React.Fragment>
                    <div key={tt.id} class="delivery-group">
                      <div className="delivery-header">
                        <div className="deliveryHeader">
                          <div className="deliveryId">#{tt.toDeliveredById}</div>
                          <div className="labelCount">{tt.tableOccupations.length} {I18n.t("admin_local.address").toLowerCase()} - {tt.tableOccupations.map(this.labelCount).reduce((a, b) => a + b, 0) + I18n.t("local.pcs")} {I18n.t("admin_local.package").toLowerCase()}</div>
                          {idle && ready ?
                            <button className="btn btn-primary icon-delivering" onClick={() => this.deliveringAll(tt.tableOccupations)}> {I18n.t("local.delivering")}</button>
                            : null}
                          {delivering ?
                            <button className="btn btn-negative icon-cancel" onClick={() => this.cancelDeliveringAll(tt.tableOccupations)}> {I18n.t("local.cancel")}</button>
                            : null}
                        </div>
                      </div>
                      {tt.tableOccupations.sort(this.orderByDistance).map(ttt => {
                        const t = this.props.tableOccupations.find(t => ttt.tableOccupation == t.id);
                        if (!t) return null;
                        return (
                          <React.Fragment>
                            <div className={"delivery-details " + this.orderState(ttt) + (t.id === this.props.myStatus.tableOccupation || selected === tt.toDeliveredById + "-" + ttt.tableOccupation ? " active" : "")} onDoubleClick={event => this.openTableOccupation(t.id, t.restaurantTables[0].restaurantTable.number)} onClick={event => this.setState({ selected: tt.toDeliveredById + "-" + ttt.tableOccupation })}>
                              {this.getTableOccupationAddress(t, ttt)}
                              {t.id === this.props.myStatus.tableOccupation || (selected === (tt.toDeliveredById + "-" + ttt.tableOccupation)) ? this.getTableOccupationDetails(ttt) : null}
                              {this.getTableOccupationButtons(t, tt, ttt)}
                              <div class="labelCount">{this.labelCount(ttt) + I18n.t("local.pcs")}</div>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </React.Fragment>
                )
              })
              : null}
            {unAssignedTableOccupations.sort(this.sortTableOccupations).map(tt => {
              const ready = tt.tableOccupations.find(t => this.orderState(t) === this.readyState());
              const delivering = tt.tableOccupations.find(t => this.orderState(t) === "delivering");
              return (
                <React.Fragment key={tt.id}>
                  <div className="delivery-group">
                    <div className="delivery-header">
                      <div className="deliveryHeader">
                        <div className="deliveryId">{I18n.t("admin_local.unassigned_orders")}</div>
                        <div className="labelCount">
                          {tt.tableOccupations.length} {I18n.t("admin_local.address").toLowerCase()} -
                          {tt.tableOccupations.map(this.labelCount).reduce((a, b) => a + b, 0) + I18n.t("local.pcs")}
                          {" " + I18n.t("admin_local.package").toLowerCase()}
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={this.assignToCourier}
                          disabled={this.state.selectedTableOccupations.length === 0}
                        >
                          {I18n.t("admin_local.assign_to_me")}
                        </button>
                      </div>
                    </div>
                    {tt.tableOccupations.sort(this.orderByDistance).map(ttt => {
                      const t = this.props.tableOccupations.find(t => ttt.tableOccupation == t.id);
                      if (!t) return null;

                      return (
                        <React.Fragment key={ttt.tableOccupation}>
                          <div
                            className={
                              "delivery-details " +
                              this.orderState(ttt) +
                              (t.id === this.props.myStatus.tableOccupation || selected === tt.toDeliveredById + "-" + ttt.tableOccupation ? " active" : "")
                            }
                            onDoubleClick={() => this.openTableOccupation(t.id, t.restaurantTables[0].restaurantTable.number)}
                            onClick={() => this.setState({ selected: tt.toDeliveredById + "-" + ttt.tableOccupation })}
                          >
                            <input
                              style={{ width: "2rem", height: "2rem" }}
                              type="checkbox"
                              className="select-table-occupation"
                              onChange={e => this.handleCheckboxChange(e, ttt)}
                              checked={this.state.selectedTableOccupations.includes(ttt)}
                            />
                            {this.getTableOccupationAddress(t, ttt)}
                            {t.id === this.props.myStatus.tableOccupation || selected === (tt.toDeliveredById + "-" + ttt.tableOccupation) ? this.getTableOccupationDetails(ttt) : null}
                            {this.getTableOccupationButtons(t, tt, ttt)}
                            <div className="labelCount">{this.labelCount(ttt) + I18n.t("local.pcs")}</div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}


            <div id="tableMatePaidDialog"></div>
            <div id="editMenuItemDialog"></div>
          </div>
        </div>
      </React.Fragment >
    );
  }

  sortTableOccupations = (a, b) => {
    const statea = a.tableOccupations.find(t => this.orderState(t) !== "delivered");
    const stateb = b.tableOccupations.find(t => this.orderState(t) !== "delivered")
    if (statea && !stateb)
      return -1;
    if (!statea && stateb)
      return 1;
    if (statea)
      return a.toDeliveredById - b.toDeliveredById;
    else
      return b.toDeliveredById - a.toDeliveredById;
  }

  getTableOccupationButtons = (t, tt, ttt) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end", gridColumnStart: 1, gridColumnEnd: 4 }}>
        {t?.bookedProperties?.phone ? <div className="btn btn-primary icon-phone" onClick={() => {
          if (auth.device.platform === "Android")
            window.location.href = `tel:${t?.bookedProperties.phone}`;
          else
            window.plugins.CallNumber.callNumber(
              success => console.log("Call successful!"),
              error => console.error("Call failed!", error),
              t?.bookedProperties.phone,
              true // Whether to bypass the dialer and call directly
            );
        }}></div> : null}
        {t?.bookedProperties?.latitude ? <div className="btn btn-primary icon-level-down" onClick={() => { this.headingTo(t, true, tt.toDeliveredById) }}></div> : null}
        <div style={{ flexGrow: 1 }} />
        {!ready && this.orderState(ttt) === this.readyState() ? <button className="btn btn-primary icon-delivering" onClick={() => this.delivering(ttt)}> {I18n.t("local.delivering")}</button> : null}
        {this.orderState(ttt) === "delivering" ? <React.Fragment>
          {t?.id === this.props.myStatus.tableOccupation ?
            <div className="btn btn-negative icon-cancel" onClick={() => { this.headingTo(undefined, false, tt.toDeliveredById) }}>{I18n.t("local.cancel")}</div>
            :
            <button className="btn btn-primary " onClick={() => this.headingTo(t, null, tt.toDeliveredById)}> {I18n.t("local.delivering")}</button>
          }
          <div style={{ flexGrow: 1 }} />
          {
            //eslint-disable-next-line
            navigator.geolocation ? <button className="btn btn-primary icon2-target-1" onClick={() => this.updatePosition(ttt)}></button> : null
          }
          <button className="btn btn-primary " onClick={() => this.delivered(ttt)}> {I18n.t("local.delivered")}</button>
        </React.Fragment> : null}
        {this.orderState(ttt) === "delivered" && !this.orderPaid(t) ? <button className="btn btn-primary icon-dollar" onClick={() => this.paid(ttt)}> {I18n.t("local.paid")}</button> : null}
      </div>)
  }

  getTableOccupationDetails = ttt => {
    const t = getTableOccupation(ttt.tableOccupation);
    return (
      <React.Fragment>
        {t?.bookedProperties?.payment_method && (
          <React.Fragment>
            <div className="label">{I18n.t("admin_local.payment_method")}</div>
            <div className="payment_method">{getPaymentMethod(t.bookedProperties.payment_method).method.name}</div>
          </React.Fragment>)}
        <div className="details" key={"details" + ttt.tableOccupation}>
          {ttt.orders.map(order =>
            order.orders.map(order => (
              <React.Fragment>
                <div key={order.id} style={{ textAlign: "right", fontWeight: 800 }}>{order.orderCount}x</div><div style={{ fontWeight: 800 }}>{getMenuItem(order.menuItem.id).name}</div><div style={{ fontWeight: 800 }}>{order.labelCount ? order.orderCount * order.labelCount + I18n.t("local.pcs") : ""}</div>
                {
                  order.childOrders.map(co => (
                    <React.Fragment>
                      <div /><div>{getMenuItem(co.menuItem.id).name}</div><div>{co.labelCount ? co.labelCount + I18n.t("local.pcs") : ""}</div>
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
            )))
          }
        </div>
      </React.Fragment>)
  }

  getTableOccupationAddress = (t, ttt) => (
    <React.Fragment>
      <div className="label">{I18n.t("admin_local.id")}:</div><div className="">{(t?.bookedProperties && t.bookedProperties["thirdparty-order-number"]) || ("#" + t.seqnr)}</div><div class="created">{moment(ttt.created).fromNow()}</div><div className={this.getIcon(this.orderState(ttt))} />
      {t?.bookedProperties && (
        <React.Fragment>
          <div className="label">{I18n.t("local.name")}:</div><div className="value">{t?.bookedProperties?.name}{t?.bookedProperties.comment ? " (" + t?.bookedProperties.user_comment + ")" : ""}</div>
          <div className="label">{I18n.t("local.phone")}:</div><div className="value">{t?.bookedProperties?.phone}</div>
          <div className="label">{I18n.t("local.address")}:</div><div className="value">{t?.bookedProperties?.postcode} {t?.bookedProperties?.town}, {t?.bookedProperties?.address}{t?.bookedProperties?.door ? " ," + t?.bookedProperties?.door : ""}{t?.bookedProperties.comment ? " (" + t?.bookedProperties.comment + ")" : ""}</div>
        </React.Fragment>)}
    </React.Fragment>
  )

  headingTo = (t, navigate = true, deliveryId = 0) => {
    return new Promise(resolve => {
      stopBackgroundLocationTraking();
      setTimeout(() => {
        startBackgroundLocationTraking(deliveryId, t?.id || 0);
      }, 1000);
      get("adminService/" + localStorage.restaurantSelected + "/updateCourierHeading/" + (t?.id || 0) + "/" + deliveryId + "?instance=" + localStorage.instance, undefined, undefined, false).done((data) => {
        auth.setMyStatus({ ...auth.myStatus, ...data }, true);
        if (t && navigate && t?.bookedProperties.latitude) {
          if (auth.device.platform === "iOS") {
            const destination = [t?.bookedProperties.latitude, t?.bookedProperties.longitude];
            //eslint-disable-next-line
            launchnavigator.navigate(destination, {
              //eslint-disable-next-line
              app: launchnavigator.APP.WAZE
            }, function () {
              console.log("Successfully opened Waze");
            }, function (error) {
              console.error("Waze not available, opening Google Maps:", error);

              // Open Google Maps as fallback
              //eslint-disable-next-line
              launchnavigator.navigate(destination, {
                //eslint-disable-next-line
                app: launchnavigator.APP.GOOGLE_MAPS
              }, function () {
                console.log("Successfully opened Google Maps");
              }, function (error) {
                console.error("Error opening Google Maps:", error);
              });
            });
          } else
            window.location.href = (auth.device.platform === "Android" ? "geo:" : "https://www.google.com/maps/dir/?api=1&destination=") + `${t?.bookedProperties.latitude},${t?.bookedProperties.longitude}?q=${t?.bookedProperties.latitude},${t?.bookedProperties.longitude}`;
        }
        resolve(data)
      }).fail(() => { auth.ajaxError = false; })
    })
  }

  labelCount = t => {
    if (orderListUtil.ordersCache)
      return t.orderIds.map(o => getOrderById(o)).filter(o => o.state !== "cancelled" && o.state !== "ordered" && o.state !== "selected").map(o => {
        return o.defaultProductionLinesIds.split(",").map(id => getProductionLine(id)).filter(lb => lb.labelPrinter).length * o.labelCount + o.childOrders.filter(co => co.addition).map(co => co.defaultProductionLinesIds.split(",").map(id => getProductionLine(id)).filter(lb => lb.labelPrinter).length * co.labelCount).reduce((a, b) => a + b, 0)
      }).reduce((a, b) => a + b, 0)
    return 0;
  }

  startLocationService = () => {
    try {
      startBackgroundLocationTraking();
    } catch (ex) {
      console.error(ex);
    }
  }
  stopLocationService = () => {
    stopBackgroundLocationTraking();
  }

  openTableOccupation(tableOccupation, tableNumber) {
    selectTableOccupationSelected(tableOccupation);
    selectTableNumberSelected(tableNumber);
    showEditTable();
    selectTableOccupation(tableOccupation);
    hideSelectNewOrders();
    $('#main').show();
    updateTableMates().done(() => {
      updateOrderList(true);
      updateHooks($('#main'));
      updateFooterButtons();
      updateHeadings();
    });
  }

  groupByDelivery = (a, b) => {
    var delivery = a.find(aa => aa.toDeliveredById === b.deliveredById);
    if (delivery) {
      delivery.tableOccupations.push(b);
    } else {
      a.push({ toDeliveredById: b.deliveredById, tableOccupations: [b] });
    }
    return a;
  }

  orderByDistance = (a, b) => {
    if (this.state.latitude) {
      return this.getDistance(a) - this.getDistance(b);
    } else {
      return a.seqnr - b.seqnr;
    }
  }

  getOrdersForOccupation = (id) => {
    if (orderListUtil.ordersCache)
      return orderListUtil.ordersCache.filter(o => o.tableOccupationId == id && o.recordState === "ACTIVE" && o.state != "canceled" && o.state != "selected" && o.state != "ordered");
    return [];
  }

  orderState = (a) => {
    const states = allstates_but_cancelled.filter(state => a.stateMap[state]?.length);
    return allstates_but_cancelled[Math.min(...states.map(state => allstates_but_cancelled.indexOf(state)))];
  }

  orderPaid = (a) => {
    return !this.getOrdersForOccupation(a.id).find(o => !o.paid);
  }

  icons = {
    accepted: "icon-accepted",
    delivered: "icon-check",
    delivering: "icon-delivering",
    producing: "icon-producing",
    ready: "icon-ready",
    canceled: "icon-cancelled",
  }

  getIcon = (a) => {
    return this.icons[a];
  }

  getDistance = (a) => {
    if (a.bookedProperties.latitude)
      return this.getDistanceHaver(a.bookedProperties.latitude, a.bookedProperties.longitude, this.state.latitude, this.state.longitude);
    return 10000;
  }

  getDistanceHaver = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);  // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;  // Distance in kilometers
    return distance;
  }

  getDistanceBetween = (a, b) => {
    return this.getDistanceHaver(a[0], a[1], b[0], b[1]);
  }

  onTableOccupationSearch = (tableOccupations) => {
    if (this.props.qrActionsOnly === true && tableOccupations.length) {
      this.openTableOccupation(tableOccupations[0].occupation.id, tableOccupations[0].occupation.restaurantTables[0].restaurantTable.number)
      return;
    }
    if (tableOccupations.length > 0) {
      var event = this.myRef.current.getApi().getEventById(tableOccupations[0].occupation.id + "-" + tableOccupations[0].occupation.restaurantTables[0].restaurantTable.number);
      if (event != null) {
        this.myRef.current.getApi().gotoDate(moment(tableOccupations[0].occupation.bookedOccupationStart).startOf("day").toDate());
        this.setState({ date: moment(tableOccupations[0].occupation.bookedOccupationStart).startOf("day").toDate() });
        this.openTableOccupation(tableOccupations[0].occupation.id, tableOccupations[0].occupation.restaurantTables[0].restaurantTable.number)
      }
    }
  }

  scanqrcode = (action) => {
    console.log("Scan:" + !!window.cordova.plugins.barcodeScanner)
    window.cordova.plugins.barcodeScanner.scan(result => this.processQrCode(action, result),
      function (error) {
        alert("Scanning failed: " + error);
        if (window.cordova.plugins.backgroundMode)
          setTimeout(() => { window.cordova.plugins.backgroundMode.enableOverrideBackButton = true }, 1000);
      })
    if (window.cordova.plugins.backgroundMode)
      setTimeout(() => { window.cordova.plugins.backgroundMode.enableOverrideBackButton = true }, 1000);

  }

  processQrCode = (action, result) => {
    const instance = this;

    if (!result.cancelled) {
      if (result.format === "QR_CODE" || result.format === "Fake") {
        try {
          const regexp = /(\d+):((\d+,)+)/g;
          const match = regexp.exec(result.text);

          if (match.length === 4) {
            const productionLine = match[1];
            const orders = match[2].split(",").map(id => {
              if (id) {
                const order = getOrderById(id);
                if (order)
                  return order;
              }
              return null;
            }).filter(o => o);
            if (!orders.length) {
              messageDialog(I18n.t('local.error_message'), String.format(I18n.t('admin_local.could_not_find_this_order2'), ""));
              return;
            }
            const tableOccupationId = orders[0].tableOccupationId;
            const tableNumber = orders[0].tableNumber;
            const key = tableOccupationId + "-" + tableNumber;
            const tableOccupation = getTableOccupation(tableOccupationId);
            if (!tableOccupation) {
              messageDialog(I18n.t('local.error_message'), String.format(I18n.t('admin_local.could_not_find_this_order'), tableOccupationId));
              return;
            }

            if (instance.props.qrActionsOnly === true) {
              if (tableOccupation !== null) {
                if (action === 'open') {
                  instance.openTableOccupation(tableOccupationId, tableNumber);
                } else if (action === 'payment') {
                  localStorage.tableOccupationSelected = tableOccupation.id;
                  tableMatePaid(tableOccupation.tableMates[0].id);
                } else {
                  admin.changeStateForOrders(tableOccupationId, match[2], action, productionLine, data => {
                    update(data);
                  })
                }
              }
              return;
            }
            var event = instance.myRef.current.getApi().getEventById(key);
            if (event !== null) {
              instance.myRef.current.getApi().gotoDate(moment(tableOccupation.bookedOccupationStart).startOf("day").toDate());
              instance.setState({ date: moment(tableOccupation.bookedOccupationStart).startOf("day").toDate() });
              if (action === 'open') {
                instance.openTableOccupation(tableOccupationId, tableNumber);
              } else if (action === 'payment') {
                localStorage.tableOccupationSelected = tableOccupation.id;
                tableMatePaid(tableOccupation.tableMates[0].id);
              } else {
                admin.changeStateForOrders(tableOccupationId, match[2], action, productionLine, data => {
                  update(data);
                })
              }
            } else {
              messageDialog(I18n.t('local.errormessage'), String.format(I18n.t('admin_local.could_not_find_this_order'), tableOccupationId));
            }
          }
        } catch (ex) {
          alert(ex);
        }
      }
    }
  }

  delivering = tableOccupation => {
    this.changeState([tableOccupation], "delivering");
  }

  deliveringAll = async tableOccupations => {

    const t = getTableOccupation(tableOccupations[0].tableOccupation);
    await this.headingTo(null, false, t.toDeliveredById);
    await this.changeState(tableOccupations.filter(tableOccupation => this.orderState(tableOccupation) == this.readyState()), "delivering");
  }

  changeState = (queue, state) => {
    return new Promise(resolve => {
      const process = () => {
        const tableOccupation = queue.pop();
        if (tableOccupation)
          admin.changeStateForOrders(tableOccupation.tableOccupation, tableOccupation.orderIds, state, Object.values(tableOccupation.productionLinesInvolved).map(p => p.id), data => {
            update(data);
            process();
          })
        else
          resolve();
      }
      process();
    })
  }


  cancelDeliveringAll = async tableOccupations => {
    await this.headingTo(null);
    await this.changeState(tableOccupations.filter(tableOccupation => this.orderState(tableOccupation) == "delivering"), this.readyState());
    await this.setCourierState("IDLE");
  }

  readyState = () => {
    const statesShown = this.props.myStatus.restaurant_settings["tables-overview"].statesShown;
    const state = statesShown.find(s => s === "ready") || statesShown.find(s => s === "producing") || statesShown.find(s => s === "accepted");
    return state;
  }

  delivered = tableOccupation => {
    //this.changeState([tableOccupation], "delivered");
    getBackgroundLocation(data => {
      var occ = getTableOccupation(tableOccupation.tableOccupation);
      if (!occ.bookedProperties.latitude) {
        this.updatePosition(tableOccupation, data, I18n.t("admin_local.new_position"), "delivered");
      } else {
        const distance = this.getDistanceBetween([data.latitude, data.longitude], [Number(occ.bookedProperties.latitude), Number(occ.bookedProperties.longitude)]);
        if (distance > .01) {
          this.updatePosition(tableOccupation, data, String.format(I18n.t("admin_local.distance_to_big"), Math.round(distance * 100) / 100), "delivered");
        } else {
          this.changeState([tableOccupation], "delivered");
          this.headingTo(null, false, tableOccupation.toDeliveredById);
        }
      }
    }, (error) => {
      this.changeState([tableOccupation], "delivered");
      console.error("Failed to retrieve location:" + error
      );
    },
      {
        timeout: 15000, // Set timeout to 500 ms
        maximumAge: 10000, // Do not use cached location
        enableHighAccuracy: true, // May affect speed; set to true if high accuracy is required
      })
  }

  updatePosition = async (tableOccupation, data, text = "", changeState) => {
    return new Promise(resolve => {
      //eslint-disable-next-line 
      this.setState({ showUpdatePositionDialog: { occupation: getTableOccupation(tableOccupation.tableOccupation) } });
      if (data) {
        setTimeout(() => {
          this.setState({ showUpdatePositionDialog: { defaultPosition: [data.latitude, data.longitude], tableOccupation, occupation: getTableOccupation(tableOccupation.tableOccupation), text, changeState } });
        }, 100);
        resolve();
      } else {
        getBackgroundLocation(data => {
          this.setState({ showUpdatePositionDialog: { defaultPosition: [data.latitude, data.longitude], tableOccupation, occupation: getTableOccupation(tableOccupation.tableOccupation), text, changeState } });
          resolve();
        },
          (error) => {
            if (changeState) {
              this.changeState([tableOccupation], changeState);
              if (changeState === "delivered") {
                this.headingTo(null, false, tableOccupation.toDeliveredById);
              }
            }
            console.error("Failed to retrieve location:" + error);
          },
          { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 });
      }
    });
  }

  confirmUpdatePosition = async (position, tableOccupation) => {
    return new Promise(resolve => {
      get("adminService/" + localStorage.restaurantSelected + "/updateBookingPosition/" + tableOccupation.tableOccupation + "/" + position[0] + "/" + position[1] + "?refreshAddress=true").done((data) => {
        const changeState = this.state.showUpdatePositionDialog.changeState;
        update(data);
        if (changeState) {
          this.changeState([tableOccupation], changeState)
          if (changeState === "delivered") {
            this.headingTo(null, false, tableOccupation.toDeliveredById);
          }
        }
        this.setState({ showUpdatePositionDialog: false })
        resolve();
      });
    });
  }

  paid = tableOccupation => {
    localStorage.tableOccupationSelected = tableOccupation.tableOccupation;
    const t = this.props.tableOccupations.find(tt => tt.id === tableOccupation.tableOccupation);
    tableMatePaid(t.tableMates[0].id, undefined, undefined, (data) => {
      update(data);
    });

  }

  onChange = (event) => {
    const state = { ...this.state };
    if (this.state.eventSelected) {
      state.booking = { ...this.state.booking, changed: true };
      state.booking[event.target.id] = event.target.value;
      if (this.onChangeTimeout) clearTimeout(this.onChangeTimeout);
      this.onChangeTimeout = setTimeout(() => {
        var title = this.state.eventSelected.title.split("-")[0] + " - " + state.booking.email + (state.booking.comment ? "\r\n" + state.booking.comment : "");

        this.state.eventSelected.setProp("title", title);
        this.state.eventSelected.setExtendedProp("changed", true);
        this.onChangeTimeout = null;
      }, 500);
      this.setState(state);
    }
  };

  createBooking = count => {
    var events = this.state.selectedEvents;
    var counts = [];
    var defaultRoom = undefined;
    if (events.length > 0) {
      Object.keys(events[0].extendedProps.seats).forEach(c => {
        events[0].extendedProps.seats[c].forEach(q => {
          if (Array.isArray(q)) defaultRoom = getTable(q[0]).roomId;
          else defaultRoom = getTable(q).roomId;
          counts.push(c);
        });
      });
    }
    counts.push(count);
    var freeResources = null;
    if (defaultRoom !== undefined) freeResources = this.findFreeResources(counts, moment(this.state.booking.start), moment(this.state.booking.end), defaultRoom);
    if (freeResources == null) freeResources = this.findFreeResources(counts, moment(this.state.booking.start), moment(this.state.booking.end));
    if (freeResources == null) {
      if (counts.length > 1) messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_enough_free_tables"));
      else messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_free_table"));
      counts.splice(count.length - 1, 1);
      return;
    }

    var tables = {};
    var seats = {};

    counts.forEach((c, ind) => {
      if (seats[c] === undefined) {
        if (freeResources) seats[c] = [freeResources[ind]];
      } else {
        seats[c].push(freeResources[ind]);
      }
    });

    Object.values(seats).forEach(c => {
      c.forEach(c => {
        if (Array.isArray(c)) {
          c.forEach(c => {
            var table = getTable(c);
            if (tables[table.seats] === undefined) tables[table.seats] = 1;
            else tables[table.seats]++;
          });
        } else {
          var table = getTable(c);
          if (tables[table.seats] === undefined) tables[table.seats] = 1;
          else tables[table.seats]++;
        }
      });
    });

    var t = "";

    Object.keys(tables)
      .sort((a, b) => {
        return a - b;
      })
      .forEach(s => {
        if (t !== "") t += ", ";
        t += tables[s] + "x" + s;
      });
    $("#reservations span#tables").html(t);

    tables = [];

    freeResources.forEach((resource, ind) => {
      const table = getTable(resource);
      tables.push(resource);
      if (ind < events.length) {
        event = events[ind];
        if (Array.isArray(resource)) {
          var resourceIds = [];
          resource.forEach(r => {
            if (r.startsWith("room")) return;
            var table = getTable(r);
            var roomResourceId = "room-" + table.roomId + "-" + table.seats;
            resourceIds.push(r);
            if (resourceIds.indexOf(roomResourceId) === -1) resourceIds.push(roomResourceId);
          });
          event._def.resourceIds = resourceIds;
          event._def.title = String.format(I18n.t("admin_local.book_a_table.number_of_seats"), table.seats) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment;
        } else {
          var roomResourceId = "room-" + table.roomId + "-" + table.seats;
          event._def.resourceIds = [roomResourceId, resource];
          event._def.title = String.format(I18n.t("admin_local.book_a_table.number_of_seats"), table.seats) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment;
        }
      }
    });

    events.forEach(event => {
      event.setExtendedProp("seats", seats);
    });

    var event = {
      title: String.format(I18n.t("admin_local.book_a_table.number_of_seats"), count) + " - " + $("#reservations input#email").val() + " - " + $("#reservations input#comment").val(),
      start: this.state.booking.start.toDate(),
      end: this.state.booking.end.toDate(),
      endEditable: true,
      startEditable: true,
      editable: true,
      id: "booking-" + events.length,
      groupId: typeof this.eventSelected !== "undefined" ? this.eventSelected.groupId : "booking",
      extendedProps: {
        booking: true,
        seats: seats,
        editable: true,
        removeEnabled: true,
        email: $("#reservations input#email").val(),
        phone: $("#reservations input#phone").val(),
        comment: $("#reservations input#comment").val()
      }
    };

    var newEvent = this.myRef.current.getApi().addEvent(event);
    var resource = freeResources[freeResources.length - 1];
    if (Array.isArray(resource)) {
      var resourceIds = [];
      resource.forEach(r => {
        var table = getTable(r);
        var roomResourceId = "room-" + table.roomId + "-" + table.seats;
        resourceIds.push(r);
        if (resourceIds.indexOf(roomResourceId) === -1) resourceIds.push(roomResourceId);
      });
      newEvent._def.resourceIds = resourceIds;
    } else {
      var table = getTable(resource);
      var roomResourceId = "room-" + table.roomId + "-" + table.seats;
      newEvent._def.resourceIds = [roomResourceId, resource];
    }

    this.setState({
      booking: {
        ...this.state.booking,
        seats: seats,
        groupId: "booking"
      },
      eventSelected: newEvent,
      selectedEvents: [...events, newEvent]
    });
    var resourceHtmlElement = $("tr[data-resource-id = '" + freeResources[freeResources.length - 1].id + "']");
    resourceHtmlElement.parents(".fc-scroller").centerTo(resourceHtmlElement);
    this.myRef.current.getApi().rerenderEvents();
    $("#reservations input#email").focus();

    //this.enableButtons(true);
  };

  findFreeResources = (counts, start, end, selectedRoom) => {
    var instance = this;
    var result = null;
    this.props.myStatus.restaurant_rooms
      .sort(roomComparator)
      .forEach(room => {
        if (result !== null) return;
        if (selectedRoom !== undefined && room.id !== selectedRoom) {
          return result;
        }
        var resources = [];
        var foundCount = 0;
        counts.forEach(c => {
          var count = instance.getTableSeatCount(c);
          var found = false;
          this.props.tables.forEach(table => {
            if (found) return;
            if (table.roomId === room.id && table.seats === count) {
              var f = false;
              resources.forEach(res => {
                f = f || res === table.id;
              });
              if (f) return;
              if (instance.isFree(table.id, start, end)) {
                resources.push(table.id);
                found = true;
                foundCount++;
              }
            }
          });
        });
        if (foundCount === counts.length) {
          result = resources;
        }
      });

    if (result == null) {
      //let's try first to fit the need with bigger tables than needed
      this.props.myStatus.restaurant_rooms
        .sort(roomComparator)
        .forEach(room => {
          if (result !== null) return;
          var resources = [];
          var foundCount = 0;
          counts.forEach(c => {
            var count = instance.getTableSeatCount(c);
            var found = false;
            this.props.tables.forEach(table => {
              if (found) return;
              if (table.roomId === room.id && table.seats >= count) {
                var f = false;
                resources.forEach(res => {
                  f = f || res === table.id;
                });
                if (f) return;
                if (instance.isFree(table.id, start, end)) {
                  resources.push(table.id);
                  found = true;
                  foundCount++;
                }
              }
            });
          });
          if (foundCount === counts.length) {
            result = resources;
          }
        });
    }

    if (result == null) {
      //let's try to extend some tables if possible to meet the needed seat number
      this.props.myStatus.restaurant_rooms
        .sort(roomComparator)
        .forEach(room => {
          if (result !== null) return;
          var resources = [];
          var foundCount = 0;
          counts.forEach(c => {
            var count = instance.getTableSeatCount(c);
            var found = false;
            this.props.tables.forEach(table => {
              if (found) return;
              var seats = table.seats;
              if (table.roomId === room.id && seats >= count) {
                var f = false;
                resources.forEach(res => {
                  f = f || res === table.id;
                });
                if (f) return;
                if (instance.isFree(table.id, start, end)) {
                  resources.push(table.id);
                  found = true;
                  foundCount++;
                }
              }
            });
            var free = true;
            if (!found) {
              var tables = [];
              this.props.tables.forEach(table => {
                var t = getTable(table.id);
                if (t.extensions === "" || !t.extensions) {
                  return;
                }
                t.extensions.split(",").forEach(extension => {
                  var totalSeats = extension.split("=")[1];
                  if (Number(table.roomId) === Number(room.id) && Number(totalSeats) >= Number(count)) {
                    var f = false;
                    var res = [table.id];
                    resources.forEach(res => {
                      f = f || res === table.id;
                    });
                    if (f) return;
                    if (instance.isFree(table.id, start, end)) {
                      extension
                        .split("=")[0]
                        .split("+")
                        .forEach(otherTable => {
                          if (otherTable === "" || isNaN(otherTable)) return;

                          var f = false;
                          resources.forEach(res => {
                            f = f || res === otherTable;
                          });
                          if (f) {
                            free = false;
                            return;
                          }

                          if (instance.isFree(otherTable, start, end));
                          res.push(otherTable);
                        });
                      if (free) tables.push({ totalSeats: totalSeats, resource: res });
                      else free = false;
                    } else {
                      free = false;
                    }
                  }
                });
              });
              if (free) {
                tables.sort((a, b) => {
                  return a.totalSeats - b.totalSeats;
                });
                if (tables.length > 0) {
                  var table = tables[0];
                  resources.push(table.resource);
                  foundCount++;
                }
              }
            }
          });
          if (foundCount === counts.length) {
            result = resources;
          }
        });
    }
    return result;
  };

  moveToRoom = (events, room, start, end) => {
    var counts = [];
    Object.keys(events[0].extendedProps.seats).forEach(c => {
      events[0].extendedProps.seats[c].forEach(cc => {
        counts.push(c);
      });
    });
    var freeResources = this.findFreeResources(counts, start, end, room);
    if (!freeResources) {
      if (counts.length > 1) messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_enough_free_tables"));
      else messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_free_table"));
      //eventDropInfo.revert();
      return;
    }

    var seats = {};

    counts.forEach((c, ind) => {
      if (!seats[c]) {
        if (freeResources) seats[c] = [freeResources[ind]];
      } else {
        seats[c].push(freeResources[ind]);
      }
    });

    var tables = {};
    counts.forEach(c => {
      if (!tables[c]) tables[c] = 1;
      else tables[c]++;
    });
    var t = "";
    Object.keys(tables)
      .sort((a, b) => {
        return a - b;
      })
      .forEach(s => {
        if (t !== "") t += ", ";
        t += tables[s] + "x" + s;
      });
    $("#reservations span#tables").html(t);

    tables = [];

    freeResources.forEach((resource, ind) => {
      let event = events[ind];
      if (Array.isArray(resource)) {
        var resourceIds = [];
        resource.forEach(r => {
          if (r.startsWith("room")) return;
          var table = getTable(r);
          var roomResourceId = "room-" + table.roomId + "-" + table.seats;
          resourceIds.push(r);
          if (resourceIds.indexOf(roomResourceId) === -1) resourceIds.push(roomResourceId);
        });
        event._def.resourceIds = resourceIds;
        event.setExtendedProp("seats", seats);
        event.setProp("title", String.format(I18n.t("admin_local.book_a_table.number_of_seats"), counts[ind]) + " - " + $("#reservations input#email").val() + " - " + $("#reservations input#comment").val());
      } else {
        var table = getTable(resource);
        var roomResourceId = "room-" + table.roomId + "-" + table.seats;
        event._def.resourceIds = [roomResourceId, resource];
        event._def.title = String.format(I18n.t("admin_local.book_a_table.number_of_seats"), counts[ind]) + " - " + $("#reservations input#email").val() + " - " + $("#reservations input#comment").val();
      }
    });
    events.forEach(event => {
      event.setExtendedProp("seats", seats);
    });
    this.setState({
      booking: {
        ...this.state.booking,
        seats: seats,
        start: start,
        end: end
      }
    });
  };

  getTableSeatCount = count => {
    var c = count;
    this.props.tables.forEach(room => {
      if (room.children)
        room.children.forEach(tables => {
          if (tables.seats >= count && tables.seats < c) {
            c = tables.seats;
          }
        });
    });
    return c;
  };

  reveal = resource => {
    var parent = this.myRef.current.getApi().getResourceById(resource._resource.parentId);
    if (!parent) return;
    var id = parent.id;
    this.myRef.current.getApi().dispatch({
      type: "SET_RESOURCE_ENTITY_EXPANDED",
      id: id,
      isExpanded: true
    });
    this.reveal(parent);
  };

  clearBooking = rerender => {
    if (this.state.booking)
      this.getSelectedEvents().forEach(event => {
        event.setExtendedProp("changed", false);
        event.setProp("title", event.title.split(" - ")[0] + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
      });

    this.counts = [];
    this.setState({
      booking: {
        events: [],
        seats: [],
        rooms: [],
        tables: [],
        start: null,
        end: null,
        customer: null,
        phone: null
      },
      tables: "",
      email: "",
      phone: "",
      comment: "",
      selectedEvents: [],
      eventSelected: undefined
    });

    //if (this.myRef.current.getApi() && rerender !== false) this.myRef.current.getApi().rerenderEvents();
  };

  static updateBookingTable = (tableOccupationId, oldTable, newTable) => {
    var tableOccupation = getTableOccupation(tableOccupationId);
    var bookedProperties = tableOccupation.bookedProperties;
    Object.values(bookedProperties.seats).forEach(tables => {
      tables.forEach((table, ind) => {
        if (Array.isArray(table)) {
          table.forEach((t, ind) => {
            if (t === oldTable) table[ind] = newTable;
          });
        } else {
          if (table === oldTable) tables[ind] = newTable;
        }
      });
    });
    post("adminService/" + localStorage.restaurantSelected + "/updateBooking/" + tableOccupationId, {
      start: tableOccupation.bookedOccupationStart,
      end: tableOccupation.bookedOccupationEnd,
      seats: bookedProperties.seats,
      email: bookedProperties.email,
      phone: bookedProperties.phone,
      comment: bookedProperties.comment,
      door: ""
    }).done(data => {
      update(data);
    });
  };

  isFree = (resource, start, end) => {
    var free = true;
    this.state.tableOccupations.forEach(tableOccupation => {
      tableOccupation.tableNumbers.forEach(table => {
        if (table === resource) {
          var s = moment(tableOccupation.occupationStart == null ? tableOccupation.bookedOccupationStart : tableOccupation.occupationStart);
          var e = moment(tableOccupation.occupationEnd == null ? tableOccupation.bookedOccupationEnd : tableOccupation.occupationEnd);
          if (s.isBefore(end) && e.isAfter(start)) {
            free = false;
          }
        }
      });
    });
    return free;
  };

  getLocation = () => {
    getBackgroundLocation(
      (position) => {
        const latitude = position.latitude;
        const longitude = position.longitude;
        this.setState({ latitude, longitude })
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );
  }

  updateHeader = () => {
    var tables = {};
    Object.values(this.state.booking.seats).forEach(c => {
      c.forEach(c => {
        if (Array.isArray(c)) {
          c.forEach(c => {
            var table = getTable(c);
            if (!tables[table.seats]) tables[table.seats] = 1;
            else tables[table.seats]++;
          });
        } else {
          var table = getTable(c);
          if (!tables[table.seats]) tables[table.seats] = 1;
          else tables[table.seats]++;
        }
      });
    });

    var t = "";
    Object.keys(tables)
      .sort((a, b) => {
        return a - b;
      })
      .forEach(s => {
        if (t !== "") t += ", ";
        t += tables[s] + "x" + s;
      });
    $("#reservations span#tables").html(t);
  };

  eventDrag = eventDropInfo => {
    this.drag = true;
  }

  eventDrop = eventDropInfo => {
    this.drag = false;
    var event = eventDropInfo.event;
    //eslint-disable-next-line react/no-direct-mutation-state
    this.state.booking.changed = true;
    //eslint-disable-next-line react/no-direct-mutation-state
    this.state.booking.start = moment(event.start);
    //eslint-disable-next-line react/no-direct-mutation-state
    this.state.booking.end = moment(event.end);
    if (this.view === "tables" && eventDropInfo.oldResource) {
      Object.values(event.extendedProps.seats).forEach(tables => {
        tables.forEach((table, ind) => {
          if (Array.isArray(table)) {
            table.forEach((t, ind) => {
              if (t === eventDropInfo.oldResource.id) table[ind] = eventDropInfo.newResource.id;
            });
          } else {
            if (table === eventDropInfo.oldResource.id) tables[ind] = eventDropInfo.newResource.id;
          }
        });
      });
      event.setExtendedProp("changed", true);
      this.setState({
        booking: {
          ...this.state.booking,
          changed: true
        }
      });
      this.myRef.current.getApi().rerenderEvents();
    }
  };
  eventResize = eventResizeInfo => {
    this.booking.start = moment(eventResizeInfo.event.start);
    this.booking.end = moment(eventResizeInfo.event.end);
    if (eventResizeInfo.event.id.startsWith("booking")) {
      this.booking.changed = true;
      this.myRef.current.getApi().getEvents().forEach(ev => {
        if (ev.groupId === eventResizeInfo.event.groupId) {
          ev.setExtendedProp("changed", true);
        }
      });
    } else {
      this.booking.changed = true;
      this.myRef.current.getApi().getEvents().forEach(ev => {
        if (ev.groupId === eventResizeInfo.event.groupId) {
          ev.setExtendedProp("changed", true);
        }
      });
    }
    this.myRef.current.getApi().rerenderEvents();
  };
  eventRender = eventInfo => {
    //console.log("datesRender", this.topScroll)
    //$('.fc-row.fc-week.fc-widget-content').scrollTop(this.topScroll);
    const instance = this;


    var el = eventInfo.el;
    var event = eventInfo.event;

    const tableNumber = Number(event._def.resourceIds[0]);

    const pickupTableNumber = this.props.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];

    const isPickup = tableNumber === pickupTableNumber;

    const now = moment();

    const minduration = isPickup ? 1.5 : instance.props.myStatus.restaurant_settings['enabled-features'].homedelivery["min-duration"];

    if (instance.drag)
      return;
    if (event.startEditable && event.durationEditable && !event.allDay) {
      console.log('adjust')
      var startDate = moment(event.start);
      if (startDate.startOf('minute').isBefore(now.clone().startOf('minute'))) {
        event.start = now.format();
        startDate = now.clone();
      }
      if (event.end) {
        var endDate = moment(event.end);
        const hoursDif = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDif < minduration && !isNaN(hoursDif)) {
          event.end = startDate.add(minduration, "hour").format();
        }
      } else {
        event.end = startDate.add(minduration, "hour").format();
      }
    }

    if (event.extendedProps.tables) {
      if (event.extendedProps.occupied === event.extendedProps.tables) {
        el.style.background = "#F05050";
        el.style.color = "white";
      } else if (event.extendedProps.occupied - event.extendedProps.tables === -1 && event.extendedProps.tables > 1) {
        el.style.background =
          "linear-gradient(to top, orange, orange " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%, transparent " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%,transparent 100%)";
      } else {
        el.style.background =
          "linear-gradient(to top, darkgray, darkgray " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%, transparent " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%, transparent 100%)";
      }
      el.style.border = "unset";
      el.style["border-left"] = "1px dotted #ddd";
      el.style["border-top"] = "1px solid #ddd";
      el.style.bottom = "0";
      el.style["margin-bottom"] = "0";
    }
    if (event.extendedProps.booked) {
      el.style.background = "white";
    }
    if (event.extendedProps.changed) {
      el.style.background = "yellow";
    }
    if (event.extendedProps.booking) {
      el.style.background = "orange";
    }
    if (event.extendedProps.booked === true || event.extendedProps.booking === true) {
      event.editable = event.extendedProps.editable === true;
      var seatsAvailable = 0;
      var seatsUsed = 0;
      Object.keys(event.extendedProps.seats).forEach(c => {
        event.extendedProps.seats[c].forEach(cc => {
          if (Array.isArray(cc)) {
            if (event._def.resourceIds.indexOf(cc[0]) !== -1) seatsUsed += Number(c);
          } else {
            if (event._def.resourceIds.indexOf(cc) !== -1) seatsUsed += Number(c);
          }
        });
      });
      event._def.resourceIds.forEach(resource => {
        if (!resource.startsWith("room")) seatsAvailable += getTable(resource).seats;
      });
      if (instance.view === "rooms" && event.extendedProps.tables) {
        //event._def.resourceEditable = false;
        $(el)
          .find(".fc-title")
          .html(event.extendedProps.tables[event.id.split("booking-")[1]] + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
        return;
      }
      if (seatsUsed > seatsAvailable) {
        el.style.background = "red";
        el.style.color = "yellow";
      } else if (seatsUsed < seatsAvailable) {
        el.style.background = "yellow";
        el.style.color = "black";
      }
      var ts = [];
      event._def.resourceIds.forEach(resource => {
        if (!resource.startsWith("room")) ts.push(resource);
      });
      if (ts.length > 1) {
        var tables = "";
        var seats = 0;
        ts.forEach(res => {
          if (tables !== "") tables += "+";
          tables += res;
          seats += getTable(res).seats;
        });
        $(el)
          .find(".fc-title")
          .html(tables + " = " + String.format(I18n.t("admin_local.book_a_table.number_of_seats"), seats) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
      }
    }
    if (instance.state.eventSelected && event.groupId === instance.state.eventSelected.groupId) {
      el.style["border-width"] = "3px";
    }
    if (event.groupId === instance.state.booking.groupId) {
      if (event.startEditable === true) {
        if (event.extendedProps.removeEnabled === true || event.extendedProps.booked === true) {
          $(el).append($("<i class='closeon icon-cancel-circled'></i>"));

          $(el)
            .find(".closeon")
            .on("click", function () {
              event.remove();
              const newState = {
                ...instance.state
              }
              if (instance.state.eventSelected && instance.state.eventSelected.id === event.id) {
                newState.eventSelected = undefined;
              }
              newState.selectedEvents = newState.selectedEvents.filter(e => e.id !== event.id);
              instance.setState(newState);
              Object.keys(instance.state.booking.seats).forEach(c => {
                var tables = [];
                var oldTables = event.extendedProps.seats[c];
                instance.myRef.current.getApi().getEvents().forEach(event => {
                  if (event.groupId === instance.state.booking.groupId) {
                    event._def.resourceIds.forEach(resource => {
                      if (resource !== null && oldTables.indexOf(resource) !== -1) tables.push(resource);
                    });
                  }
                });
                instance.state.booking.seats[c] = tables;
              });
              Object.keys(instance.state.booking.seats).forEach(c => {
                if (instance.state.booking.seats[c].lenght === 0) {
                  delete instance.state.booking.seats[c];
                }
              });
              instance.myRef.current.getApi().getEvents().forEach(event => {
                if (event.groupId === instance.state.booking.groupId) {
                  event.setExtendedProp("seats", instance.state.booking.seats);
                }
              });

              instance.updateHeader();
            });
        }
      }
    }
  };
  eventClick = eventClickInfo => {
    const instance = this;
    var event = eventClickInfo.event;
    if (instance.state.eventClicked && instance.state.eventClicked.id === event.id && new Date().getTime() - instance.state.eventClickedTimestamp < 1000) {
      selectTableOccupationSelected(event.extendedProps.tableOccupation);
      selectTableNumberSelected(event._def.resourceIds[0]);
      this.openTableOccupation(event.extendedProps.tableOccupation, event._def.resourceIds[0]);
    }

    if (!(event.extendedProps.booked || event.extendedProps.isPickup || event.extendedProps.isHomeDelivery) && !event.extendedProps.booking) {
      instance.clearBooking();
      return;
    }
    instance.clearBooking();

    instance.setState({
      booking: { ...event.extendedProps },
      eventClicked: event,
      eventClickedTimestamp: new Date().getTime(),
      eventSelected: event,
      selectedEvents: this.getSelectedEvents(event.groupId)
    });

    console.log("selected");

    $(instance.myRef.current.getApi().el)
      .find(".closeon")
      .remove();
    $(instance.myRef.current.getApi().el)
      .find(".edit")
      .remove();
    $(instance.myRef.current.getApi().el)
      .find(".save")
      .remove();

  };

  eventAfterAllRender = (ref) => {
    console.log("datesRender", this.topScroll)
    $('.fc-row.fc-week.fc-widget-content').scrollTop(this.topScroll);
  };

  setView(view) {
    this.setState({ view: view });
    //instance.myRef.current.getApi().refetchResources();
    //instance.myRef.current.getApi().refetchEvents();
  }

  resourceColumns = [
    {
      group: true,
      labelText: I18n.t("admin_local.book_a_table.room"),
      field: "order",
      render: function (resource, el) {
        $(el).html(resource.extendedProps.room);
      }
    },
    {
      group: true,
      labelText: I18n.t("admin_local.book_a_table.seats"),
      field: "seats"
    },
    {
      labelText: I18n.t("admin_local.book_a_table.tables"),
      field: "title"
    }
  ];

  footer = {
    left: "cancel",
    right: "edit,save"
  };

  views = {
    "1dayResourceTimelineDay15": {
      type: "resourceTimeGridDay",
      buttonText: '15"',
      duration: { day: 1 },
      slotDuration: '00:15"'
    },
    "1dayResourceTimelineDay30": {
      type: "resourceTimeGridDay",
      buttonText: '30"',
      duration: { day: 1 },
      slotDuration: "00:30"
    },
    "1dayResourceTimelineDay60": {
      type: "resourceTimeGridDay",
      buttonText: '60"',
      duration: { day: 1 },
      slotDuration: "00:60"
    },
    "3dayResourceTimelineDay60": {
      type: "resourceTimeGridDay",
      buttonText: '3 ' + I18n.t("admin_local.day"),
      duration: { day: 3 },
      slotDuration: "00:60"
    },
    "7dayResourceTimelineDay60": {
      type: "resourceTimeGridDay",
      buttonText: I18n.t("admin_local.week"),
      duration: { day: 7 },
      slotDuration: "00:60"
    },
    "30dayResourceTimelineDay60": {
      type: "resourceTimeGridDay",
      buttonText: I18n.t("admin_local.month"),
      duration: { month: 1 },
      slotDuration: "00:60"
    },
  };

  header = {
    left: "title prev,next 1dayResourceTimelineDay15,1dayResourceTimelineDay30,1dayResourceTimelineDay60 3dayResourceTimelineDay60,7dayResourceTimelineDay60,30dayResourceTimelineDay60",
    center: "",
    right: ""
  };

  dayRender = dayRenderInfo => {
    var date = dayRenderInfo.date;
    var el = dayRenderInfo.el;
    // var offset = ee.utcOffset()*60000 -
    // date.utcOffset()*60000;
    //console.log(this);
    this.props.businessHours.forEach(businessHour => {
      var day = date.getDay();
      if (businessHour.daysOfWeek.indexOf(day) === -1) {
        return;
      }
      var d = new Date(date.getTime());
      d.setSeconds(0);
      d.setMilliseconds(0);
      var startHour = new Date(date.getTime());
      startHour.setHours(businessHour.startTime.split(":")[0]);
      startHour.setMinutes(businessHour.startTime.split(":")[1]);
      startHour.setSeconds(0);
      startHour.setMilliseconds(0);
      var endHour = new Date(date.getTime());
      endHour.setHours(businessHour.endTime.split(":")[0]);
      endHour.setMinutes(businessHour.endTime.split(":")[1]);
      endHour.setSeconds(0);
      endHour.setMilliseconds(0);
      endHour = moment(endHour)
        .add(-this.state.slotDuration.split(":")[1], "minute")
        .toDate();
      if (date.getTime() === startHour.getTime() && businessHour.showStart === true) {
        el.style["border-left"] = "5px dashed darkslategray";
        el.style["margin-left"] = "3px";
      } else if (date.getTime() === endHour.getTime() && businessHour.showEnd === true) {
        el.style["border-right"] = "5px dashed darkslategray";
        el.style["margin-right"] = "3px";
      }
    });
  };

  getVisibleRange = date => {
    var ss = moment(date).startOf("day");
    ss.add(this.props.myStatus.restaurant_settings["business-day-starts-at"], "Hour");
    var ee = moment(date).startOf("day")
    ee.add(2, 'day');
    ee.add(1, "Day").add(-1, "Second");
    if (this.props.myStatus.restaurant_settings["business-day-starts-at"] > this.props.myStatus.restaurant_settings["business-day-ends-at"])
      ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"], "Hour");
    else ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"] - 24, "Hour");

    const visibleRange = {
      start: ss.toDate(),
      end: ee.toDate()
    };
    return visibleRange;
  };

  customButtons = instance => {
    return {
      tables: {
        text: I18n.t("admin_local.book_a_table.tables"),
        click: function () {
          instance.setView("tables");
        }
      },
      rooms: {
        text: I18n.t("admin_local.book_a_table.rooms"),
        click: function () {
          instance.setView("rooms");
        }
      },
      daypicker: {
        text: I18n.t("admin_local.date"),
        click: function () {
          var w = $(instance.datePickerWidget).css("width");
          if (w === "0px") {
            $(instance.datePickerWidget).css("width", "39em");
          } else {
            $(instance.datePickerWidget).css("width", "0em");
          }
        }
      },
      /*
      next: {
        icon: "chevron-right",
        click: function () {
          // instance.myRef.current.getApi().incrementDate({ days: 1 });
          const newdate = moment(instance.state.date).clone().add(1, 'day').toDate();
          console.log(newdate)
          instance.setState({
            date: newdate,
            forward: true,
          })
        }
      },
      prev: {
        icon: "chevron-left",
        click: function () {
          //instance.myRef.current.getApi().incrementDate({ days: -1 });
          const newdate = moment(instance.state.date).clone().add(-1, 'day').toDate();
          instance.setState({
            date: newdate,
            forward: false,
          })
        }
      },*/
      twoPerson: {
        text: "2",
        click: function () {
          instance.createBooking(2);
        }
      },
      fourPerson: {
        text: "4",
        click: function () {
          instance.createBooking(4);
        }
      },
      sixPerson: {
        text: "6",
        click: function () {
          instance.createBooking(6);
        }
      },
      eightPerson: {
        text: "8",
        click: function () {
          instance.createBooking(8);
        }
      },
      tenPerson: {
        text: "10",
        click: function () {
          instance.createBooking(10);
        }
      },
      persons: {
        text: "",
        click: function () {
          var count = $(instance.myRef.current.getApi().el)
            .find("input#count")
            .val();
          $(instance.myRef.current.getApi().el)
            .find("input#count")
            .val("");
          instance.modal
            .find("#numbersBlock")
            .parent()
            .hide();
          if (count > 0) {
            instance.createBooking(count);
          }
        }
      },
      edit: {
        text: I18n.t("admin_local.edit"),
        click: function () {
          instance.getSelectedEvents().forEach(event => {
            alert("Edit");
            event.setProp("editable", true);
            event.setProp("startEditable", true);
            instance.myRef.current.getApi().rerenderEvents();
          });
        }
      },
      save: {
        text: I18n.t("admin_local.save"),
        click: function () {
          instance.save();
        }
      },
      t1: {
        text: I18n.t("admin_local.tables")
      },
      t2: {
        text: " X ",
        icon: "cancel-circled",
        click: function () {
          var events = instance.getSelectedEvents();
          if (events.length > 0) {
            if (events[0].id.startsWith("booking")) {
              events.forEach(event => {
                event.remove();
              });
            } else {
              admin.unreservations(events[0].groupId, -1, true, function (data) {
                update(data);
                instance.myRef.current.getApi().refetchEvents();
              });
            }
            instance.clearBooking();
          }
        }
      },
      cancel: {
        text: I18n.t("admin_local.close"),
        click: function () {
          instance.modal.modal("hide");
        }
      }
    };
  };

  getAllTableOccupations = (handler, errorHandler) => {
    var search = {
      fromDate: this.state.ss.toDate().getTime(),
      toDate: this.state.ee.toDate().getTime(),
      active: true
    };
    post("adminService/" + localStorage.restaurantSelected + "/filterTableOccupationsOnly?lang=" + localStorage.language, search).done(function (data) {
      resultHandler(data, handler, errorHandler);
    });
  };

  static updateEvents = (myStatus, tableOccupations, allOrders, pickupTableNumber, homeDeliveryTableNumber) => {
    var events = [];
    tableOccupations.forEach(function (tableOccupation, i) {
      const v = {};
      v.title = "";
      v.selected = false;
      v.isActive = v.active;
      if (!tableOccupation.bookedProperties) {
        return;
      } else {
        v.bookedProperties = tableOccupation.bookedProperties;
        v.bookedOccupationEnd = tableOccupation.bookedOccupationEnd;
        v.bookedOccupationStart = tableOccupation.bookedOccupationStart;
        v.id = tableOccupation.id;
        v.tableNumbers = tableOccupation.restaurantTables.map(t => t.restaurantTable.number);
        v.ordersInState = {};
        v.printed = true;

        allOrders.tables && allOrders.tables.forEach(t => {
          if (t.tableOccupation === tableOccupation.id) {
            v.ordersInState = t.stateMap ? t.stateMap : {};
            v.printed = t.printed;
          }
        })
      }
      delete v.active;
      v.folder = false;
      const isPickup = v.tableNumbers[0] === pickupTableNumber;
      const isHomeDelivery = v.tableNumbers[0] === homeDeliveryTableNumber;

      if (!isPickup && !isHomeDelivery)
        return;

      var start = null;
      var end = null;

      var title;

      var backgroundColorClass = [];

      if (isPickup || isHomeDelivery) {
        if (isPickup)
          title = v.id + " - " + v.bookedProperties.name + " - " + v.bookedProperties.phone;
        else
          title = v.id + " - " + v.bookedProperties.name + " - " + v.bookedProperties.postcode + " " + v.bookedProperties.town + ", " + v.bookedProperties.address;
        var minTime;

        var minState = null;

        if (v.bookedProperties.comment) {
          title += "\r\n" + v.bookedProperties.comment;
        }

        Object.keys(v.ordersInState).forEach(state => {
          if (state === "cancelled") return;
          const count = v.ordersInState[state].length;
          if (count > 0 && state !== 'cancelled') {
            title += "\r\n" + I18n.t('local.' + state) + ": " + count;

            if (minState === null || allstates.indexOf(minState) > allstates.indexOf(state))
              minState = state;
          }
        })

        if (!minState && v.ordersInState.cancelled > 0) {
          minState = "cancelled";
        }

        backgroundColorClass.push("bg-color-" + minState);

        if (!v.printed) {
          backgroundColorClass.push("icon-printer");
        }

        const maxHour = {};
        const minHour = {};
        const timeslots = isPickup ? myStatus.restaurant_settings['enabled-features'].pickup['available-timeslots'] : myStatus.restaurant_settings['enabled-features'].homedelivery['available-timeslots']
        timeslots && timeslots.forEach(timeslot => {
          minTime = Math.min(minTime, timeslot.from);
          var day = timeslot.day;
          if (day === 7)
            day = 0;
          minHour[day] = minHour[timeslot.day] ? Math.min(minHour[timeslot.day], timeslot.from) : timeslot.from;
          maxHour[day] = maxHour[timeslot.day] ? Math.max(maxHour[timeslot.day], timeslot.to) : timeslot.to;
        });

        start = new Date(Number(v.bookedOccupationStart));
        end = v.bookedOccupationEnd ? new Date(v.bookedOccupationEnd) : null;
      }

      if (Math.abs(v.bookedOccupationEnd - moment(v.bookedOccupationEnd).endOf('day').valueOf()) < 10000 && Math.abs(moment(v.bookedOccupationStart).startOf('day').valueOf() - v.bookedOccupationStart) < 10000) {
        end = null;
      }

      var tableNumbers = [];

      v.tableNumbers.forEach(table => {
        tableNumbers.push(table);
        events.push({
          start: start,
          end: end,
          id: v.id + "-" + table,
          resourceId: "" + table,
          title: title,
          allDay: end === null && moment(v.bookedOccupationStart).startOf('day').valueOf() === v.bookedOccupationStart,
          editable: false,
          startEditable: false,
          durationEditable: false,
          resourceEditable: v.booked || isPickup || isHomeDelivery,
          booked: v.booked,
          isPickup: isPickup,
          isHomeDelivery: isHomeDelivery,
          tableOccupation: v.id,
          classNames: backgroundColorClass,
          groupId: v.id,
          extendedProps: {
            ...v.bookedProperties
          }
        });
      });

    });
    return events;
    //this.setState({ tableEvents: events });
    //});
  };


  getSelectedEvents(groupId) {
    groupId = groupId ? groupId : this.booking ? this.booking.groupId : "";
    if (!groupId)
      return [];
    var events = [];
    this.myRef.current.getApi().getEvents().forEach(event => {
      if (event.groupId === groupId)
        events.push(event);
    })
    return events;
  };

  setCourierState = (state) => {
    return new Promise(resolve => {
      get("adminService/" + localStorage.restaurantSelected + '/updateCourierState/' + state).done(data => {
        auth.setMyStatus({ ...auth.myStatus, ...data });
        resolve();
      });
    });
  }

  edit = () => {
    const instance = this;
    this.state.selectedEvents.forEach(event => {
      event.setProp("editable", true);
      event.setProp("startEditable", true);
    });
    instance.myRef.current.getApi().rerenderEvents();
  }

  save = () => {
    const instance = this;
    var event = instance.myRef.current.getApi().getEventById(this.state.eventSelected.id);
    var seats = event.extendedProps.seats;
    console.log(event.start);
    if (event.groupId !== "booking") {
      post("adminService/" + localStorage.restaurantSelected + "/updateBooking/" + event.groupId, {
        start: event.start.getTime(),
        end: event.end ? event.end.getTime() : "null",
        seats: seats,
        name: instance.state.booking.name,
        email: instance.state.booking.email,
        phone: instance.state.booking.phone,
        postcode: instance.state.booking.postcode,
        town: instance.state.booking.town,
        address: instance.state.booking.address,
        comment: instance.state.booking.comment,
        door: instance.state.booking.door
      }).done(data => {
        update(data);
        if (event.id.startsWith("booking")) event.remove();
        this.setState({
          booking: {
            events: [],
            seats: [],
            rooms: [],
            tables: [],
            start: null,
            end: null,
            customer: null,
            phone: null
          },
          selectedEvents: [],
          eventSelected: undefined,
          email: "",
          phone: "",
          comment: "",
          tables: ""
        });
        //this.refetchEvents();
      });
    } else {
      post("adminService/" + localStorage.restaurantSelected + "/reservations", {
        start: this.state.booking.start.toDate().getTime(),
        end: this.state.booking.end.toDate().getTime(),
        seats: seats,
        email: $("#reservations input#email").val(),
        phone: $("#reservations input#phone").val(),
        comment: $("#reservations input#comment").val()
      }).done(data => {
        if (instance.props.update) instance.props.update(data);
        this.setState({
          booking: {
            events: [],
            seats: [],
            rooms: [],
            tables: [],
            start: null,
            end: null,
            customer: null,
            phone: null
          },
          selectedEvents: [],
          eventSelected: undefined,
          email: "",
          phone: "",
          comment: "",
          tables: ""
        });
        //this.refetchEvents();
      });
    }
  };
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    const pickupTableNumber = props.rootReducer.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
    const homeDeliveryTableNumber = props.rootReducer.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];
    var events;
    if (props.rootReducer.tableOccupations && props.rootReducer.orders) {
      events = ReservationComponent.updateEvents(props.rootReducer.myStatus, props.rootReducer.tableOccupations, props.rootReducer.orders, pickupTableNumber, homeDeliveryTableNumber);
    }

    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
      rooms: props.rootReducer.rooms,
      pickupTableNumber: pickupTableNumber,
      homeDeliveryTableNumber: homeDeliveryTableNumber,
      tables: props.rootReducer.tablesByRooms.filter(table => table.tableNumber === pickupTableNumber || table.tableNumber === homeDeliveryTableNumber).sort((a, b) => a.number - b.number),
      businessHours: props.rootReducer.businessHours,
      tableOccupations: props.rootReducer.tableOccupations,
      deliveryUsers: props.rootReducer.deliveryUsers,
      orders: props.rootReducer.orders,
      events: events
    };
  }
  return {};
};

function roomComparator(a, b) {
  if (a.order === b.order && a.id !== b.id) {
    if (a.room === b.room && a.id !== b.id) {
      return a.seats - b.seats;
    }
    return a.room.localeCompare(b.room);
  }
  return a.order - b.order;
}

export default connect(mapStateToProps)(
  ReservationComponent);
