import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import $ from "jquery";
import "././index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import auth, { setRem, ajaxCallStart, ajaxCallEnd, navigateTo } from "./js/auth";
import ErrorTemplate from "./templates/ErrorTemplate";
import "./css/light-theme.css";
import rootReducer from "./reducers/rootReducer";
import languages, { admin_locals, locals } from "./langs/languages";
import { HashRouter } from "react-router-dom";
import thunk from "redux-thunk";
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";
import "./css/style.css";
import "./css/fontello.css";
import "./css/fontello2.css";
import "./css/index.css";
import "./css/admin.css";
import "./css/print.css";
import "./css/chooseMenuItem.css";
import "./css/orderListUtil.css";
import "./css/admin-react.css";
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";
import "font-awesome/css/font-awesome.css";
import { TemplateLoadFrame } from "./components/TemplateLoadFrame";
import { MuiThemeProvider } from "material-ui/styles";
import ThemeProvider from "./components/ThemeProvider";

export const store = createStore(rootReducer, applyMiddleware(thunk));

const DefaultTheme = React.lazy(() => import('./theme/Default'));
const FlatTheme = React.lazy(() => import('./theme/Flat'));
const FancyTheme = React.lazy(() => import('./theme/Fancy'));
const NightTheme = React.lazy(() => import('./theme/Night'));

const ThemeSelector = ({ children }) => {
  const daynight = store.getState().rootReducer.daynight;
  const CHOSEN_THEME = daynight ? 'night' : localStorage.getItem('skin') || 'default';
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(CHOSEN_THEME === 'flat') && <FlatTheme />}
        {(CHOSEN_THEME === 'default') && <DefaultTheme />}
        {(CHOSEN_THEME === 'fancy') && <FancyTheme />}
        {(CHOSEN_THEME === 'night') && <NightTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

const createTranslation = (lang) => ({
  admin_local: admin_locals[lang],
  local: locals[lang]
});

const createTranslationsObject = (languages) => {
  return languages.reduce((acc, lang) => {
    acc[lang] = createTranslation(lang);
    return acc;
  }, {});
};

const translationsObject = createTranslationsObject(languages.languages);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale(localStorage.language));

const { admin_local } = languages;

ajaxCallStart("Start", 0);

document.addEventListener(
  "deviceready2",
  () => {
    console.error("deviceready2");
    if (navigator?.presentation) {
      console.error("set onpresent");
      try {
        navigator.presentation.onpresent = (event) => {
          console.error("onpresent");
          if (event.session) {
            console.error("set session");
            auth.session = event.session;
            auth.session.onmessage = function (msg) {
              console.error("OnMessage");
            };
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    }
  });

document.addEventListener(
  "deviceready",
  () => {
    //eslint-disable-next-line no-undef
    //console.log("Device Ready", window.device, cordova.device);

    if (window.device.platform === "browser" && window.location.href.indexOf("https://restaurant.eatwithme.online/") !== -1) {
      navigateTo("https://console.eatwithme.online/" + window.location.hash);
      return;
    }
    /*
    const frame = $('<iframe style="visibility:hidden" w3-include-html src="templates/superadmin_users_templates.html"/>');
    frame.appendTo("head");
    frame.addEventListener("onload", () => {
      auth.frameLoaded(this);
    });*/
    ReactDOM.render(
      <React.Fragment>
        <TemplateLoadFrame src="superadmin_users_templates" />
        <TemplateLoadFrame src="tables-overview-template" />
        <TemplateLoadFrame src="point-of-sale-template" />
        <TemplateLoadFrame src="table" />
        <TemplateLoadFrame src="menuitem" />
        <TemplateLoadFrame src="keyboard" />
        <TemplateLoadFrame src="error" />
        <TemplateLoadFrame src="comments" />
        <TemplateLoadFrame src="ordersByCustomer" />
        <TemplateLoadFrame src="ordersByPayer" />
        <TemplateLoadFrame src="ordersByTiming" />
        <TemplateLoadFrame src="orderList" />
        <TemplateLoadFrame src="simpleOrderList" />
        <TemplateLoadFrame src="rejectionReason" />
        <TemplateLoadFrame src="orderMenuItem" />
        <TemplateLoadFrame src="markTableMateForPayment" />
        <TemplateLoadFrame src="tableMatePaid" />
        <TemplateLoadFrame src="lockTable" />
        <TemplateLoadFrame src="chooseCustomer" />
        <TemplateLoadFrame src="editDiscounts" />
        <TemplateLoadFrame src="bookTable" />
        <TemplateLoadFrame src="chooseMenuItem" />
        <TemplateLoadFrame src="barTemplate" />
        <TemplateLoadFrame src="kitchenTemplate" />
        <TemplateLoadFrame src="productionLineTemplate" />
        <TemplateLoadFrame src="rejectionReason" />
        <TemplateLoadFrame src="orderList-table-style-react" />
        <TemplateLoadFrame src="bookTable" />
        <TemplateLoadFrame src="menuitem" />
        <TemplateLoadFrame src="suppliers" />
        <TemplateLoadFrame src="stores" />
        <TemplateLoadFrame src="daily-closing" />
        <TemplateLoadFrame src="stock_movements" />
        <TemplateLoadFrame src="incoming_stocks" />
        <TemplateLoadFrame src="inventories" />
        <TemplateLoadFrame src="inventoryScrapping" />
        <TemplateLoadFrame src="stocklist" />
        <TemplateLoadFrame src="tableOccupations" />
        <TemplateLoadFrame src="stock-statistics" />
        <TemplateLoadFrame src="myprofile" />
        <TemplateLoadFrame src="order-summary" />
        <TemplateLoadFrame src="restaurants" />
        <TemplateLoadFrame src="printers" />
        <TemplateLoadFrame src="production-lines" />
        <TemplateLoadFrame src="tables" />
        <TemplateLoadFrame src="vat-categories" />
        <TemplateLoadFrame src="quantity-types" />
        <TemplateLoadFrame src="discount-types" />
        <TemplateLoadFrame src="labels" />
        <TemplateLoadFrame src="cash-registers" />
        <TemplateLoadFrame src="bank-terminals" />
        <TemplateLoadFrame src="scales" />
        <TemplateLoadFrame src="barcodes" />
        <TemplateLoadFrame src="tableLayouts" />
        <TemplateLoadFrame src="paymentMethods" />
        <TemplateLoadFrame src="advertisements" />
      </React.Fragment>,
      document.getElementById("templates")
    );
    auth.waitForFrames(render);
  },
  false
);

function render() {
  ajaxCallEnd("Start");
  const def = $.Deferred();
  if (!auth.isIncludedInFrame) {
    ReactDOM.render(
      <React.Fragment>
        <div id="test_mode" className="top">
          {admin_local.test_mode}
        </div>
        <div id="backup" className="top">
          {admin_local.backup}
        </div>
      </React.Fragment>,
      document.getElementById("test-top")
    );
    ReactDOM.render(
      <React.Fragment>
        <div id="test_mode" className="bottom">
          {admin_local.test_mode}
        </div>
        <div id="backup" className="top">
          {admin_local.backup}
        </div>
      </React.Fragment>,
      document.getElementById("test-bottom")
    );
  }

  ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <HashRouter>
          <ErrorTemplate />
          <MuiThemeProvider>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </MuiThemeProvider>
        </HashRouter>
      </Provider>
    </React.Fragment>,
    document.getElementById("root")
  );
  document.getElementById("root").setAttribute('theme', localStorage.skin);
  def.resolve();
  setRem();
  window.onresize = setRem;
  return def.promise();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
