import React, { Component, useEffect } from 'react';
import { Map as MapContainer, TileLayer, Marker, useLeaflet as useMapEvents, Polyline, Pane } from 'react-leaflet';
import { I18n } from 'react-redux-i18n';
import moment from "moment";
import { getLocalPrinterSettings, getTableMate } from '../js/admin';
import { DivIcon } from 'leaflet';
import orderListUtil, { getAddresses, getTableOccupation, update } from '../js/order-list-util';
import TimeRangeSlider from "react-time-range-slider";
import $ from "jquery";
import { selectTableOccupation, showMap } from '../js/tables-overview';
import auth, { get, post, select } from '../js/auth';
import L from 'leaflet';
import { allstates } from '../js/order-grouping';
import CenterTransition from './CenterTransition';

const filterTableOccupations = (props, state) => {
    const date = moment(state.date);
    return props.tableOccupations.filter(o => o.bookedProperties && o.bookedProperties.longitude).filter(occupation => {

        if (state.selectedMarkers[occupation.id]) {
            return true;
        }

        if (state.showTableOccupation === occupation.id) {
            return true;
        }

        if (state.showTableOccupation) {
            const deliveredById = getTableOccupation(state.showTableOccupation)?.deliveredById;
            if (deliveredById && deliveredById == occupation.deliveredById) {
                return true;
            }
        }

        const o = orderListUtil.allOrders.tables.find(t => t.tableOccupation === occupation.id);

        if (!o)
            return false;

        const startTime = moment(o.date);

        if (startTime.isBefore(date))
            return false;

        if (startTime.isAfter(state.endDate))
            return false;

        if (state.startDate.isAfter(moment().startOf('day')) && startTime.isBefore(state.startDate))
            return false;

        const res = (localStorage["mapmapShowStateassigned"] !== "false" || !o.courierId) && allstates.find(state => o.stateMap[state]?.length > 0 && localStorage["mapmapShowState" + state] !== "false");

        return !!res;

    });
}

export const restaurantIcon = new L.Icon({
    className: "restaurant-icon",
    iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png', // URL of the icon
    iconSize: [20, 30], // Size of the icon [width, height]
    iconAnchor: [0, 10], // Point where the icon should be anchored (center bottom)
    popupAnchor: [0, -40], // Position of the popup relative to the icon
});

//var map;
function MyComponent({ t }) {
    const date = t.state.date;

    useMapEvents({
        click: (event) => t.clickEvent(date, event)
    })
    const restaurantPosition = [t.props.myStatus.restaurant_latitude, t.props.myStatus.restaurant_longitude];

    const deliveries = Object.values(t.state.deliveries).filter(d => d.id === t.state.showDelivery && d.locations.length).map(d => {
        const partitionedLocations = [];
        let currentPartition = [];
        let lastT = null;

        d.locations.forEach(dd => {
            if (dd.t !== lastT) {
                // Start a new partition when "t" changes
                if (currentPartition.length > 0) {
                    partitionedLocations.push({ t: lastT, positions: currentPartition });
                }
                currentPartition = [];
            }
            // Add the current position to the partition
            currentPartition.push([dd.latitude, dd.longitude]);
            lastT = dd.t;
        });

        // Push the last partition if it has items
        if (currentPartition.length > 0) {
            partitionedLocations.push({ t: lastT, positions: currentPartition });
        }

        return partitionedLocations.map(tt => (<Polyline positions={tt.positions} color={tt.t === t.state.showTableOccupation ? "blue" : "darkblue"} weight={tt.t === t.state.showTableOccupation ? 6 : 4} opacity=".7" />))
    }
    )

    const deliveryUsers = t.props.deliveryUsers;

    return (
        <React.Fragment>
            <Pane name="top-layer" style={{ zIndex: 650 }}>
                {deliveryUsers.filter(c => c.tableOccupation).map(courier => {
                    const position1 = [courier.position_latitude, courier.position_longitude];
                    const occupation = t.props.tableOccupations.find(t => t.id === courier.tableOccupation);
                    if (occupation && courier.position_latitude && occupation?.bookedProperties?.latitude && occupation?.bookedProperties?.longitude) {
                        const position2 = [occupation.bookedProperties.latitude, occupation.bookedProperties.longitude];
                        return (
                            <Polyline positions={[position1, position2]} color="darkorange" weight="2" opacity=".7" />)
                    } else
                        return null;
                })}
                {deliveries}
            </Pane>
            {deliveryUsers.filter(c => c.position_latitude).filter(courier => courier.courierState !== "OFF").map(courier => {
                const position = [courier.position_latitude, courier.position_longitude];
                const tableOccupations = t.state.filteredTableOccupations.map(occupation => orderListUtil.allOrders.tables.find(o => o.tableOccupation === occupation.id && moment(o.date).startOf("day").valueOf() === date)).filter(t => t);
                const count = tableOccupations.filter(o => o.courierId === courier.id).length;
                const done = tableOccupations.filter(o => o.courierId === courier.id && o?.stateMap?.delivered?.length > 0).length;
                const customMarker = new DivIcon({
                    className: "courier-delivering",
                    id: courier.id,
                    iconSize: [20, 20],
                    fontSize: "3px",
                    html: `<div key='courier-${courier.id}'><div style='--steps:${count};--completed:${done}' class='courier courier-state-${courier.courierState}'><div class='spinning-border'/></div></div><div>${courier.name}</div></div>`,
                })
                return (<Marker key={localStorage.bookingMapZoom + "-courier-" + courier.id} position={position} icon={customMarker} ></Marker>)
            })}
            {t.state.filteredTableOccupations.filter(o => o?.bookedProperties?.latitude).map(occupation => {
                const position = [occupation.bookedProperties.latitude, occupation.bookedProperties.longitude];
                const startTime = moment(occupation.bookedOccupationStart);
                var clazz = "";
                var text = "<label class='checkbox-inline'><input onChange='setOrder(event)' order='0' class='order' type='checkbox' value='' " + (t.state.selectedMarkers[occupation.id] ? "checked" : "") + ">";
                text += occupation?.bookedProperties && occupation.bookedProperties["thirdpary-order-number"] ? occupation.bookedProperties["thirdpary-order-number"] : "#" + occupation.seqnr;
                text += "</label>";

                const o = orderListUtil.allOrders.tables.find(o => o.tableOccupation === occupation.id && moment(o.date).startOf("day").valueOf() === date);

                if (!o)
                    return;

                if (o.stateMap) {
                    if (o.stateMap.ordered.length)
                        clazz += "icon-ordered"
                    else if (o.stateMap.accepted.length)
                        clazz += "icon-accepted"
                    else if (o.stateMap.producing.length)
                        clazz += "icon-producing"
                    else if (o.stateMap.delivering.length)
                        clazz += "icon-delivering"
                    else if (o.stateMap.delivered.length)
                        clazz += "icon-delivered"
                    else if (o.stateMap.ready.length)
                        clazz += "icon-ready"
                }

                if (o && o.deliveredById) {


                    clazz += " courierSet ";
                    var courier = deliveryUsers.find(u => u.id === o.courierId);
                    if (courier) {
                        text += `
                            <div class="courier courier-state-${courier.state}" style="width: 100%;">
                                <span style="color: ${courier.color}; background-color: ${courier.backgroundColor};">
                                    ${o.deliveredByName} (${o.deliveredById})
                                </span>
                            </div>
                        `;
                    } else {
                        text += `
                            <div class="courier courier-state-${courier?.state}" style="width: 100%;">
                                <span>${o.deliveredByName}</span>
                            </div>
                        `;
                    }
                }
                text += "<div style=''>";

                /*
                if (localStorage.bookingMapZoom > 14) {
                    text += "<div class='icon-level-down'></div>";
                    text += '<div style="flex-grow:1"><b>#' + occupation.seqnr + '</b></div>';
                    text += "<div class='icon-qrcode'></div>";
                }
                if (localStorage.bookingMapZoom > 14) {
                    text += "<div>";
                    text += occupation.bookedProperties.surname ? occupation.bookedProperties.surname + " " : "";
                    text += (occupation.bookedProperties.name ? occupation.bookedProperties.name : occupation.bookedProperties.email ? occupation.bookedProperties.name : "");
                    text += "</div>";
                    text += "<div class='address icon-address-card-o'>";
                    text += (occupation.bookedProperties.postcode ? occupation.bookedProperties.postcode + "&nbsp;" : "") + (occupation.bookedProperties.town !== t.props.myStatus.restaurant_town ? occupation.bookedProperties.town + "&nbsp;" : "") + (occupation.bookedProperties.address ? occupation.bookedProperties.address : "");
                    text += "</div>";

                    text += "<div class='icon-mobile'>" + (occupation.bookedProperties.phone ? occupation.bookedProperties.phone : "");
                }*/

                const restaurantTableOccupation = occupation.restaurantTables[0];

                clazz += (getTableMate(restaurantTableOccupation.tableMateIds[0]) ? getTableMate(restaurantTableOccupation.tableMateIds[0]).states.reduce((a, b) => a + " " + b.state, "") + " " : "");

                var stateMap = orderListUtil.allOrders.tables.find(t => t.tableOccupation === occupation.id);
                stateMap = stateMap && stateMap.stateMap;
                clazz += stateMap && Object.entries(stateMap).filter(e => e[1].length).reduce((a, b) => a + " " + b[0], "");

                var style = "filter: drop-shadow(2px 2px 6px black)"

                if (localStorage.bookingMapZoom < 14) {
                    style = "font-size:" + localStorage.bookingMapZoom + "px;";
                }


                const customMarker = new DivIcon({
                    className: clazz,
                    id: occupation.id,

                    iconSize: [30, 30],
                    fontSize: "3px",
                    html: "<div style='" + style + "' id='" + occupation.id + "' address='" + occupation.bookedProperties.address + ", " + occupation.bookedProperties.town + (occupation.bookedProperties.postcode ? ", " + occupation.bookedProperties.postcode : "") + "' longitude='" + occupation.bookedProperties.longitude + "' latitude='" + occupation.bookedProperties.latitude + "'>" + text + "</div>",
                })


                return (<Marker key={localStorage.bookingMapZoom + "-" + occupation.id} position={position} icon={customMarker} >
                </Marker>)
            }
            )}
            <Marker icon={restaurantIcon} position={restaurantPosition} />
        </React.Fragment >)
}



class BookingMap extends Component {
    state = {
        startDate: moment().startOf('day'),
        endDate: moment().add(2, 'hour'),
        deliveries: {},
        center: { latitude: Number(this.props.myStatus.restaurant_latitude) || 0, longitude: Number(this.props.myStatus.restaurant_longitude) || 0 },
        showDelivery: 0,
        selectedMarkers: {},
    }

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const filteredOccupations = filterTableOccupations(props, state);
        return {
            date: moment(props.date).startOf("day").valueOf(),
            ...props,
            ...state,
            filteredTableOccupations: filteredOccupations,
            processedTableOccupations: filteredOccupations.map(occupation => orderListUtil.allOrders.tables.find(o => o.tableOccupation === occupation.id && moment(o.date).startOf("day").valueOf() === props.date)).filter(t => t)
        }
    }

    componentDidMount = (props) => {
        window.setOrder = (event) => this.clickEvent(event);
        const map = this.mapRef.current.leafletElement;

        if (typeof localStorage.bookingMapZoom == "undefined")
            localStorage.bookingMapZoom = 13;

        // Attach the zoom change event listener
        map.on('zoomend', this.handleZoomChange);
        this.updateDeliveryLocations(this.props.tableOccupations)
        map.on('moveend', this.handleMapMove);
        map.on('movestart', this.handleMapMoveStart);
        map.on('zoomstart', this.handleZoomStart);
        map.on('zoomend', this.handleZoomEnd);
    }

    handleZoomStart = (event) => {
        const map = this?.mapRef?.current?.leafletElement;
        // Update the center based on the map's new center after a move
        if (map._loaded && map._lastCenter) {
            this.zoomStart = map.getZoom();
            map.stop();
        }
    }
    handleZoomEnd = (event) => {
        const map = this?.mapRef?.current?.leafletElement;
        // Update the center based on the map's new center after a move
        if (map._loaded && map._lastCenter) {
            var shift = this.zoomStart / map.getZoom() * this.state.shift;
            this.setState({ shift: shift });
            map.stop();
        }
    }

    handleMapMoveStart = (event) => {
        const map = this?.mapRef?.current?.leafletElement;
        if (map.dragging._draggable && map.dragging._draggable._moving) {
            // Update the center based on the map's new center after a move
            if (map._loaded && map._lastCenter) {
                map.stop();
            }
        }
    }

    handleMapMove = (event) => {
        const map = this?.mapRef?.current?.leafletElement;
        // Update the center based on the map's new center after a move
        if (map._loaded) {
            const mapCenter = map.getCenter();
            const lng = mapCenter.lng + this.state.shift;
            const distance = mapCenter.distanceTo(L.latLng([this.state.center.latitude, this.state.center.longitude - this.state.shift]));
            if (distance > 1) {
                this.setState({
                    center: { latitude: mapCenter.lat, longitude: lng },
                });
            }
        }
    };

    componentWillUnmount() {
        delete window.clickEvent;
        const map = this?.mapRef?.current?.leafletElement;

        // Clean up the event listener when the component unmounts
        if (map) {
            map.off('zoomend', this.handleZoomChange);
            map.off('moveend', this.handleMapMove);
        }
    }

    componentDidUpdate = props => {
        this.updateDeliveryLocations(this.props.tableOccupations)
    }

    handleZoomChange = () => {
        // This function will be called when the zoom level changes
        const currentZoom = this.mapRef.current.leafletElement.getZoom();
        localStorage.bookingMapZoom = currentZoom;
        this.setState({ currentZoom })
    };

    selectTableOccupation = (id, checked) => {
        const selectedMarkers = { ...this.state.selectedMarkers };
        if (checked) {
            selectedMarkers[id] = true;
        } else {
            delete selectedMarkers[id];
        }
        this.setState({ selectedMarkers })

    }

    cleanTableOccupation = (id, checked) => {
        this.setState({ selectedMarkers: {} })
    }

    render() {

        const map = this?.mapRef?.current?.leafletElement;

        var shift = 0;

        if (map && map._loaded) {
            const b = map.getBounds();
            shift = (b.getNorthEast().lng - b.getSouthWest().lng) / (2 * $("#left_pane").get(0).getBoundingClientRect().width / $("#tableOccupation-selector").get(0).getBoundingClientRect().width);
        }

        if (this.state.shift != shift) {
            this.setState({ shift: shift })
        }


        //'leaflet-pan-anim'

        const date = this.state.date;
        const tableOccupations = this.props.tableOccupations.map(occupation => orderListUtil.allOrders.tables.find(o =>
            o.tableOccupation === occupation.id && moment(o.date).startOf("day").valueOf() === date
        )).filter(t => t);

        return (
            <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
                <div style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>{this.state.startDate.format('HH:mm') + " - " + this.state.endDate.format('HH:mm')}</div>
                <TimeRangeSlider
                    style={{ flexShrink: "0" }}
                    disabled={false}
                    format={24}
                    minValue={"00:00"}
                    maxValue={"24:00"}
                    name={"time_range"}
                    onChangeComplete={this.changeCompleteHandler}
                    onChange={this.timeChangeHandler}
                    step={15}
                    value={{ start: this.state.startDate.format("HH:mm"), end: this.state.endDate.format("HH:mm") }}
                />
                <MapContainer ref={this.mapRef}
                    whenCreated={mapInstance => { this.mapRef.current = mapInstance; this.componentDidMount2() }}
                    style={{ flexGrow: "1", flexShrink: "1", width: "100vw" }}
                    zoom={Number(localStorage.bookingMapZoom)}
                    scrollWheelZoom={true}>
                    {(map && map._mapPane.classList.contains("leaflet-pan-anim")) ? null :
                        <CenterTransition shift={0} newCenter={[this.state.center.latitude, this.state.center.longitude - shift]} duration={2.5} fly={false} />}
                    <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
                    <MyComponent key={localStorage.bookingMapZoom + "-" + this.key} t={this} />
                </MapContainer>
                <div class='couriers'>
                    <span>{I18n.t("admin_local.couriers")}</span>
                    {this.props.deliveryUsers.filter(u => u.courierState !== "OFF").map(u => {
                        const count = tableOccupations.filter(o => o.courierId === u.id).length;
                        const done = tableOccupations.filter(o => o.courierId === u.id && o?.stateMap?.delivered?.length > 0).length;
                        return (
                            <div id={u.id} key={u.id} onClick={this.assignToCourier} class={"courier courier-state-" + u.courierState}><span className="courier-name" style={{ backgroundColor: u.backgroundColor, color: u.color }} >{u.name}</span><span className="courier-progress">{done} / {count}</span></div>
                        )
                    }

                    )}
                </div>
            </div >);
    }

    assignToCourier = (event) => {
        const courier = event.currentTarget.id;
        let arr = [];
        $('.leaflet-marker-icon .order:checked').each((ind, i) => arr.push(i));
        let arr2 = [];
        const date = this.state.date;
        $("#tableOccupation-selector li[date = " + date + "] input.custom-control-input:checked").each((ind, i) => arr2.push(Number($(i).parents("li").attr("tableoccupation"))))
        arr = arr.sort((a, b) => $(a).attr('order') - $(b).attr('order'));
        const occupations = [];
        arr.forEach(i => {
            const t = $(i).parents('.leaflet-marker-icon').find('>div');
            const occupationid = Number(t.attr('id'));
            orderListUtil.allOrders.tables.filter(o => o.tableOccupation === occupationid && moment(o.date).startOf("day").valueOf() === date).forEach(o => occupations.push(o))
            arr2 = arr2.filter(id => id !== occupationid);
        });

        arr2.forEach(occupationid => {
            orderListUtil.allOrders.tables.filter(o => o.tableOccupation === occupationid && moment(o.date).startOf("day").valueOf() === date).forEach(o => occupations.push(o))
        })

        var delivery = 0;
        const orderIds = [];

        if (!occupations.length) {
            const c = this.props.deliveryUsers.find(u => u.id === Number(courier));
            if (c.position_latitude) {
                //show where is and was the courier
                this.setState({ center: { latitude: Number(c.position_latitude), longitude: Number(c.position_longitude) } });
            }
        }

        const assign = (occupations) => {
            const occupation = occupations.shift();
            occupation.orderIds.forEach(id => orderIds.push(id));
            if (occupations.length) {
                assign(occupations);
                return;
            }

            if (orderIds) {
                get("adminService/" + this.props.myStatus.restaurant_id + "/assignCourier/" + delivery + "/" + occupation.tableOccupation + "?orderIds=" + orderIds + "&courier=" + courier).done((data) => {
                    update(data);
                    delivery = data.orders[0].deliveredById;
                    this.setState({ changed: moment() })
                })
            }

        }
        if (occupations.length)
            assign(occupations);
        $("#tableOccupation-selector li:not(.group-functions) input.custom-control-input").prop("checked", false);
    }

    timeChangeHandler = (time) => {
        const start = moment().startOf('day').add(Number(time.start.split(":")[0]), "hour").add(Number(time.start.split(":")[1]), "minute");
        const end = moment().startOf('day').add(Number(time.end.split(":")[0]), "hour").add(Number(time.end.split(":")[1]), "minute");

        this.setState({
            startDate: start,
            endDate: end,
        });
    }

    changeCompleteHandler = (time) => {
        //this.code.reload(this.state)
    }

    updateDelivery = (delivery) => {
        var oldDeliveries = { ...this.state.deliveries };
        if (delivery?.id && oldDeliveries[delivery.id]) {
            oldDeliveries[delivery.id] = delivery;
            this.setState({ deliveries: oldDeliveries })
        }
    }

    selectDelivery = (delivery, tableOccupation) => {
        this.setState({ showDelivery: Number(delivery), showTableOccupation: Number(tableOccupation) })
        const tt = this.props.tableOccupations.find(t => t.id == tableOccupation);
        const ttt = this.state.filteredTableOccupations.find(t => t.id == tableOccupation);
        if (tt && !ttt) {
            //it is hidden from the map
            //messageDialog(I18n.t("local.warning_message"), "not visible");
        }
        if (tt?.bookedProperties?.latitude) {
            const map = this?.mapRef?.current?.leafletElement;
            map._mapPane.classList.remove("leaflet-pan-anim");
            this.setState({ center: { latitude: Number(tt.bookedProperties.latitude), longitude: Number(tt.bookedProperties.longitude) } });
        }
    }

    updateDeliveryLocations = (tableOccupations) => {
        var deliveryIds = tableOccupations.map(a => a.toDeliveredById).filter(m => m);
        var oldDeliveries = { ...this.state.deliveries };
        var changed = false;
        Object.keys(oldDeliveries).forEach(did => {
            if (deliveryIds.indexOf(Number(did)) < 0) {
                delete oldDeliveries[did];
                changed = true;
            }
        });
        deliveryIds.forEach(did => {
            if (!oldDeliveries[did]) {
                oldDeliveries[did] = {
                    id: did,
                    locations: []
                }
                changed = true;
                get("adminService/" + localStorage.restaurantSelected + "/getDelivery/" + did, undefined, undefined, undefined, false).done(data => {
                    var oldDeliveries = { ...this.state.deliveries };
                    oldDeliveries[data.id] = data;
                    this.setState({ deliveries: oldDeliveries });
                }).fail(() => { auth.ajaxError = false; });
            }
        })
        if (changed) {
            this.setState({ deliveries: oldDeliveries });
        }

    }

    clickEvent = (event) => {
        const date = this.state.date;
        var target = event.target;
        var marker = $(target).parents('.leaflet-marker-icon').find('>div');
        const id = marker.attr('id');
        $("#tableOccupation-selector li[date = " + date + "][tableOccupation = " + id + "] input.custom-control-input").prop("checked", target.checked);
        const selectedMarkers = { ...this.state.selectedMarkers };
        if (target.checked) {
            select($("#tableOccupation-selector li[date = " + date + "][tableOccupation = " + id + "] input.custom-control-input").parents("li"));
            selectedMarkers[id] = true;
        } else {
            delete selectedMarkers[id];
        }

        this.setState({ selectedMarkers })

        if ($(target).hasClass('icon-level-down') || $(target).hasClass('icon-qrcode')) {

            let arr = [];
            var text = "";
            $('.leaflet-marker-icon .order:checked').each((ind, i) => arr.push(i));
            arr = arr.sort((a, b) => $(a).attr('order') - $(b).attr('order'));

            var ok = false;

            //var path = t.props.myStatus.restaurant_latitude + ',' + t.props.myStatus.restaurant_longitude;
            var path = encodeURIComponent(this.props.myStatus.restaurant_address);

            arr.forEach(i => {
                const t = $(i).parents('.leaflet-marker-icon').find('>div');
                //path += "/" + t.attr('longitude') + ',' + t.attr('latitude');
                path += "/" + encodeURIComponent(t.attr('address').replace(/\//g, " ").replace(/,/g, " "));
                //console.log(t.attr('id'), marker.attr('id'))
                if (t.attr('id') === marker.attr('id'))
                    ok = true;

                text += getAddresses(t.attr('id'), date);
            });

            if (!ok) {
                text = "";
                path += this.props.myStatus.restaurant_address;
                //path = t.props.myStatus.restaurant_latitude + ',' + t.props.myStatus.restaurant_longitude;
                //path += "/" + marker.attr('longitude') + ',' + marker.attr('latitude');
                path += "/" + marker.attr('address');
                text += getAddresses(marker.attr('id'), date);
            }


            path += "/" + encodeURIComponent(this.props.myStatus.restaurant_address);
            //path += "/" + t.props.myStatus.restaurant_latitude + ',' + t.props.myStatus.restaurant_longitude;

            const url = 'https://www.google.com/maps/dir/' + path + '/@' + marker.attr('longitude') + ',' + marker.attr('latitude') + ',14z';
            if ($(target).hasClass('icon-qrcode')) {
                const printerSettings = getLocalPrinterSettings();
                var printer = this.props.myStatus.restaurant_printer_actions.printTableSlip.id;
                if (printerSettings.printerActions.pa_printTableSlip)
                    printer = printerSettings.printerActions.pa_printTableSlip;

                const html = "<html><body><div>!ESCqrcode: " + url + "!LF!LF</div><div>" + text + "</div></body></html>";

                post("printerServerClient/" + localStorage.restaurantSelected + "/printerMessage/" + printer, { text: html });
            } else {
                window.open(url, "_blank")
            }
            return;
        }

        if ($(target).hasClass('order')) {
            if (!$(target).prop('checked')) {
                $(target).attr('order', 0);
                marker.find('span.seqnr').html("");
                marker.find('span.seqnr').addClass("hidden");
            }
            var arr = [];
            $('.leaflet-marker-icon .order:checked').each((ind, i) => arr.push(i));
            if ($(target).prop('checked')) {

                var currentOrder = arr.map(i => $(i).attr('order')).reduce((a, b) => Math.max(a, b), 0);
                $(target).attr('order', currentOrder + 1);
            }
            arr = arr.sort((a, b) => $(a).attr('order') - $(b).attr('order'));
            arr.forEach((i, ind) => {
                $(i).attr('order', ind + 1);
                $(i).parents('.leaflet-marker-icon').find('span.seqnr').html(ind + 1);
                $(i).parents('.leaflet-marker-icon').find('span.seqnr').removeClass('hidden');
            });
            return;
        }

        if (marker.length > 0) {
            showMap(false);
            selectTableOccupation(marker.attr('id'))
        }
        //t.setState({ longitude: event.latlng.lat, latitude: event.latlng.lng });
    }

}

export default BookingMap;