/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import $ from "jquery";
import { getMenuItem, getTableMate, getProductionLine, getDiscount, getPickupTableNumber, getHomeDeliveryTableNumber } from "./admin";
import SortedSet from "js-sorted-set";
import auth, { ajaxCallStart, ajaxCallEnd } from "./auth";
import admin from "./admin";
import orderListUtil, { getTable, isPartPrinted, isBeingPrinted, isPrintFailed, isNotPrinted, isSelectedFiltered, getTableOccupation, getOrderById } from "./order-list-util";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import menuitemutil from "./admin/menuitem";
import { orders } from "../actions/EatWithMeActions";

export const allstates = ["ordered", "accepted", "producing", "ready", "delivering", "delivered", "cancelled"];
export const allstates_but_cancelled = ["ordered", "accepted", "producing", "ready", "delivering", "delivered"];
export const alltimings = ["asap", "appetizer", "maincourse", "desert", "last"];
const getStateMap = () => {
  return {
    paid: 0,
    unpaid: 0,
    selected: 0,
    cancelled: 0,
    ordered: 0,
    accepted: 0,
    producing: 0,
    ready: 0,
    delivering: 0,
    delivered: 0,
    active_delivering: 0,
    active_accepting: 0
  }
};
const getStateMapArray = () => {
  return {
    wantsToPay: [],
    paid: [],
    unpaid: [],
    selected: [],
    cancelled: [],
    ordered: [],
    accepted: [],
    producing: [],
    ready: [],
    delivering: [],
    delivered: []
  }
};

export const customerComparator = (a, b) => {
  if (a.tableNumber != b.tableNumber) {
    if (a.tableOccupation != b.tableOccupation) return a.tableOccupation - b.tableOccupation;
    else if (a.tableOccupationId != b.tableOccupationId) return a.tableOccupationId - b.tableOccupationId;
    else return a.tableNumber - b.tableNumber;
  } else {
    if (a.tableOccupation != b.tableOccupation) return a.tableOccupation - b.tableOccupation;
    else if (a.tableOccupationId != b.tableOccupationId) return a.tableOccupationId - b.tableOccupationId;
  }

  var aMate = a.customer ? a.customer : a;
  var bMate = b.customer ? b.customer : b;
  if (aMate.seqNr === bMate.seqNr && aMate.id !== bMate.id)
    return aMate.id - bMate.id;
  return aMate.seqNr - bMate.seqNr;
};

var durationUpdatedTimestamp;

function timingComparator(a, b) {
  if (moment(a.date).startOf("day").valueOf() != moment(b.date).startOf("day").valueOf()) {
    return a.date - b.date;
  }
  if (a.deliveredById != b.deliveredById) {
    return a.deliveredById - b.deliveredById;
  }
  if (a.deliveredById != null && b.deliveredById != null) {
    if (a.deliveredById != b.deliveredById) {
      //console.log(a.deliveredByName, a.deliveredByIndex, b.deliveredByName, b.deliveredByIndex);
      return a.deliveredById - b.deliveredById;
    }
  }

  if (false && a.orderSequenceNumber) {
    if (a.tableOccupation != b.tableOccupation) return a.tableOccupation - b.tableOccupation;
    else if (a.tableOccupationId != b.tableOccupationId) return a.tableOccupationId - b.tableOccupationId;
    if (a.tableNumber == b.tableNumber) {
      if (a.tableOccupation != b.tableOccupation) return a.tableOccupation - b.tableOccupation;
      else if (a.tableOccupationId != b.tableOccupationId) return a.tableOccupationId - b.tableOccupationId;

      if (alltimings.indexOf(a.timing) == alltimings.indexOf(b.timing)) {
        return a.orderSequenceNumber - b.orderSequenceNumber;
      } else {
        var i1 = alltimings.indexOf(a.timing);
        var i2 = alltimings.indexOf(b.timing);
        return i1 - i2;
      }
    } else {
      if (a.orderSequenceNumber != b.orderSequenceNumber) return a.orderSequenceNumber - b.orderSequenceNumber;
      else return a.tableNumber - b.tableNumber;
    }
  } else {
  }

  if (a.tableNumber != b.tableNumber) {
    if (a.tableOccupationId != b.tableOccupationId) return a.tableOccupationId - b.tableOccupationId;
    else if (a.tableOccupation != b.tableOccupation) return a.tableOccupation - b.tableOccupation;
    else return a.tableNumber - b.tableNumber;
  } else if (a.tableOccupation != b.tableOccupation) return a.tableOccupation - b.tableOccupation;
  try {
    if (a.tableNumber == getHomeDeliveryTableNumber() || a.tableNumber == getPickupTableNumber())
      return 0;
  } catch (Ex) { }
  return alltimings.indexOf(a.timing) - alltimings.indexOf(b.timing);
}

export const menuItemComparator = (a, b) => {
  const res = _menuItemComparator(a, b);
  return res;
}

function _menuItemComparator(a, b) {
  if (a.orderSequenceNumber != b.orderSequenceNumber) return a.orderSequenceNumber - b.orderSequenceNumber;
  var aMenuItem = getMenuItem(a.menuItem.id);
  var bMenuItem = getMenuItem(b.menuItem.id);
  if (a.state != b.state) {
    return allstates.indexOf(a.state) - allstates.indexOf(b.state);
  }

  const perPersonKitchenReceipt = auth.myStatus.restaurant_settings["tables-overview"].perPersonKitchenReceipt;

  if (!aMenuItem.freeQuantity && !bMenuItem.freeQuantity) {
    if (!perPersonKitchenReceipt || a.forWhomId === b.forWhomId) {
      if (a.orderedById == b.orderedById) {
        if (aMenuItem != null && bMenuItem != null && aMenuItem.type == bMenuItem.type) {
          if (aMenuItem.id == bMenuItem.id) {
            if (a.quantity == b.quantity) {
              if (a.price == b.price) {
                if (a.comment == b.comment) {
                  if (a.restaurantComment == b.restaurantComment) {
                    if (compareChildOrders(a, b) == 0) {
                      if (a.discountComment == b.discountComment) {
                        if (a.selected_order_discountComment == b.selected_order_discountComment) {
                          if (a.takeaway == b.takeaway) {
                            if (a.paid == b.paid) {
                              //if (a.printed == b.printed) {
                              if (a.printStatus && b.printStatus) {
                                var r = JSON.stringify(a.printStatus).localeCompare(JSON.stringify(b.printStatus));
                                return r;
                              }
                              if (a.newOrder == b.newOrder) return 0;
                              else {
                                if (a.newOrder) return 1;
                                return -1;
                              }
                            } else {
                              if (a.paid) return -1;
                              else return 1;
                            }
                          } else {
                            return a.takeaway ? 1 : -1;
                          }
                        } else {
                          return a.selected_order_discountComment.localeCompare(b.selected_order_discountComment);
                        }
                      } else {
                        return a.discountComment.localeCompare(b.discountComment);
                      }
                    } else {
                      return compareChildOrders(a, b);
                    }
                  } else {
                    return a.restaurantComment.localeCompare(b.restaurantComment);
                  }
                } else {
                  return a.comment.localeCompare(b.comment);
                }
              } else {
                return a.price - b.price;
              }
            } else {
              return a.quantity - b.quantity;
            }
          }
        }
      } else {
        if (a.orderedById == localStorage.id) return -1;
        if (b.orderedById == localStorage.id) return 1;
        r = a.orderedByName.localeCompare(b.orderedByName);
        if (r != 0) return r;
        return a.orderedById - b.orderedById;
      }
    } else {
      return a.forWhomId - b.forWhomId;
    }
  }
  if (aMenuItem.entityType && bMenuItem.entityType && aMenuItem.entityType.localeCompare(bMenuItem.entityType) != 0) return aMenuItem.entityType.localeCompare(bMenuItem.entityType);
  else if (aMenuItem.name === bMenuItem.name) {
    if (aMenuItem.id === bMenuItem.id) {
      if (!aMenuItem.freeQuantity && !bMenuItem.freeQuantity) {
        return aMenuItem.id - bMenuItem.id;
      } else {
        return a.maxOrderId - b.maxOrderId;
      }
    } else {
      return (menuitemutil.getPath(aMenuItem) + "/" + aMenuItem.id).localeCompare(menuitemutil.getPath(bMenuItem) + "/" + bMenuItem.id);
    }
  } else {
    //console.log(menuitemutil.getOrder(aMenuItem) + "/" + aMenuItem.name, "!=", menuitemutil.getOrder(bMenuItem) + "/" + bMenuItem.name);
    //return (menuitemutil.getPath(aMenuItem) + "/" + aMenuItem.name).localeCompare(menuitemutil.getPath(bMenuItem) + "/" + bMenuItem.name);
    //console.log(aMenuItem.globalOrder, bMenuItem.globalOrder);
    return aMenuItem.globalOrder - bMenuItem.globalOrder;
  }
}

function compareChildOrders(a, b) {
  if (!a?.childOrders?.length && !b?.childOrders?.length) return 0;
  if (a?.childOrders?.length != b?.childOrders?.length) return a?.childOrders?.length - b?.childOrders?.length;
  var aChildren = new SortedSet({
    comparator: menuItemComparator
  });
  var bChildren = new SortedSet({
    comparator: menuItemComparator
  });
  a.childOrders.forEach(function (o) {
    if (!aChildren.contains(o)) aChildren.insert(o);
  });
  b.childOrders.forEach(function (o) {
    if (!bChildren.contains(o)) bChildren.insert(o);
  });
  var aChildrenArray = aChildren.toArray();
  var bChildrenArray = bChildren.toArray();
  var r = 0;
  aChildrenArray.forEach(function (a, index) {
    var b = bChildrenArray[index];
    if (r != 0) return;
    r = menuItemComparator(a, b);
  });
  return r;
}

/*
function orderComparator(a, b) {
  // if (a.menuItem.id == b.menuItem.id && a.quantity == b.quantity)
  // return 0;
  if (a.menuItem.type.localeCompare(b.menuItem.type) != 0)
    return a.menuItem.type.localeCompare(b.menuItem.type);
  else if (a.state != b.state)
    return allstates.indexOf(a.state) - allstates.indexOf(b.state);
  else
    return a.id - b.id;
}
*/
function tableComparator(a, b) {
  if (a.tableNumber == b.tableNumber && a.tableOccupation == b.tableOccupation && a.orderSequenceNumber == b.orderSequenceNumber && a.timing == b.timing) return 0;
  if (a.timing == b.timing)
    if (a.tableNumber == b.tableNumber) return a.orderSequenceNumber - b.orderSequenceNumber;
    else return a.tableNumber - b.tableNumber;
  return a.timing - b.timing;
}
//tableComparator = tableComparator;

var productionLineUsage = {};

function getStateOfOrder(order) {
  if (typeof localStorage.productionLineSelected != "undefined" && order.statePerProductionLine) {
    var state = null;
    localStorage.productionLineSelected.split(",").forEach(pl => {
      if (order.statePerProductionLine[Number(pl)]) state = order.statePerProductionLine[Number(pl)].state;
    });
    if (state == null) return order.state;
    order.state = state;
    return state;
  } else if (order.statePerProductionLine && Object.keys(order.statePerProductionLine).length > 0) {
    var state = "delivered";
    Object.keys(order.statePerProductionLine).forEach(key => {
      state = allstates.indexOf(order.statePerProductionLine[key].state) < allstates.indexOf(state) ? order.statePerProductionLine[key].state : state;
    });
    order.state = state;
    return state;
  }
  return order.state;
}

SortedSet.find = (sortedSet, item) => {
  const node = sortedSet.findIterator(item).node;
  if (node != null && sortedSet.priv.comparator(node.value, item) == 0) return node.value;
  return null;
};

export const _preProcessOrders = (_orderlist, states, show) => {
  const ignoreOrderSequenceNumber = auth.ignoreOrderSequenceNumber;
  var now = new Date().getTime();

  if (!_orderlist)
    return;

  var orderlist;
  const cache = (orderListUtil.resultCache.states === null || JSON.stringify(states) === orderListUtil.resultCache.states) && !orderListUtil.isFiltered();
  var tableOccupationsProcessed = [];
  const tableOccupations = [];
  if (cache) {

    orderListUtil.resultCache.states = JSON.stringify(states);
    //orderListUtil.ordersCache = _orderlist;


    Object.keys(orderListUtil.resultCache.orderlist).forEach(key => {
      orderListUtil.resultCache.orderlist[key].found = false;
    })

    _orderlist.forEach(order => {
      if (tableOccupations.indexOf(order.tableOccupationId) === -1)
        tableOccupations.push(order.tableOccupationId);
    })

    //now = new Date().getTime();

    orderListUtil.tableOccupations && orderListUtil.tableOccupations.forEach(t => {
      const nonDeleted = _orderlist.filter(o => o.tableOccupationId === t.id && o.recordState != 'DELETED');
      const customerSelectedOrderCount = nonDeleted.filter(o => o.state === "selected" && o.orderedByCustomer);
      const nonCustomerSelectedOrderCount = nonDeleted.filter(o => o.state !== "selected" || !o.orderedByCustomer).length > 0;

      const pickupTableNumber = getPickupTableNumber();
      const homedeliveryTableNumber = getHomeDeliveryTableNumber();

      const isPickupOrHomeDelivery = !!t.restaurantTables.find(tt => tt.tableNumber === pickupTableNumber || tt.tableNumber === homedeliveryTableNumber);

      if (isPickupOrHomeDelivery && customerSelectedOrderCount.length && !nonCustomerSelectedOrderCount) {
        if (tableOccupations.indexOf(t.id) !== -1)
          tableOccupations.splice(tableOccupations.indexOf(t.id), 1);
        if (orderListUtil.tableOccupations.indexOf(t) !== -1) {
          orderListUtil.tableOccupations.splice(orderListUtil.tableOccupations.indexOf(t), 1);
        }
        _orderlist = _orderlist.filter(o => o.tableOccupationId !== t.id);
        return;
      }
      if (tableOccupations.indexOf(t.id) === -1) {
        tableOccupations.push(t.id);
      } else {
      }
    })

    //console.log(tableOccupations.length, new Date().getTime() - now);

    orderListUtil.resultCache.tables = orderListUtil.resultCache.tables.filter(table => tableOccupations.indexOf(table.tableOccupation) !== -1);
    orderListUtil.resultCache.ordersByPayer = orderListUtil.resultCache.ordersByPayer.filter(table => tableOccupations.indexOf(table.tableOccupation) !== -1);
    orderListUtil.resultCache.ordersByCustomer = orderListUtil.resultCache.ordersByCustomer.filter(table => tableOccupations.indexOf(table.tableOccupation) !== -1);
    orderListUtil.resultCache.ordersByTiming = orderListUtil.resultCache.ordersByTiming.filter(table => tableOccupations.indexOf(table.tableOccupation) !== -1);
    orderListUtil.resultCache.ordersByMenuItem = orderListUtil.resultCache.ordersByMenuItem.filter(table => tableOccupations.indexOf(table.tableOccupation) !== -1);
    Object.keys(orderListUtil.resultCache.tableOccupations).forEach(tableOccupation => tableOccupations.indexOf(tableOccupation) === -1 ? null : delete orderListUtil.resultCache.tableOccupations[tableOccupation]);
    Object.keys(orderListUtil.resultCache.tableStatus).forEach(tableOccupation => tableOccupations.indexOf(tableOccupation.split('-')[0]) === -1 ? null : delete orderListUtil.resultCache.tableStatus[tableOccupation]);


    /*
     orderlist.forEach(order => {
       orderListUtil.resultCache.orderlist[order.id] = {};
       orderListUtil.resultCache.orderlist[order.id] = order.modificationTimeStamp;
     })
     */

    //console.log(orderListUtil.resultCache.tables.length, new Date().getTime() - now);

    orderListUtil.tableOccupations.forEach(tableOccupation => {
      if (orderListUtil.resultCache.tableOccupations[tableOccupation.id] !== tableOccupation.modificationTimeStamp) {
        if (tableOccupationsProcessed.indexOf(tableOccupation.id) === -1) {
          tableOccupationsProcessed.push(tableOccupation.id);
          orderListUtil.resultCache.tableOccupations[tableOccupation.id] = tableOccupation.modificationTimeStamp;
        }
      }
    })


    orderlist = _orderlist.filter(order => {
      if (!orderListUtil.resultCache.orderlist[order.id]) {
        orderListUtil.resultCache.orderlist[order.id] = { timestamp: order.modificationTimeStamp, found: true, tableOccupation: order.tableOccupationId };
        return true;
      } else if (orderListUtil.resultCache.orderlist[order.id].timestamp !== order.modificationTimeStamp) {
        orderListUtil.resultCache.tables = orderListUtil.resultCache.tables.filter(table => table.tableOccupation !== order.tableOccupationId);
        orderListUtil.resultCache.ordersByCustomer = orderListUtil.resultCache.ordersByCustomer.filter(table => table.tableOccupation !== order.tableOccupationId);
        orderListUtil.resultCache.ordersByPayer = orderListUtil.resultCache.ordersByPayer.filter(table => table.tableOccupation !== order.tableOccupationId);
        orderListUtil.resultCache.ordersByTiming = orderListUtil.resultCache.ordersByTiming.filter(table => table.tableOccupation !== order.tableOccupationId);
        orderListUtil.resultCache.ordersByMenuItem = orderListUtil.resultCache.ordersByMenuItem.filter(table => table.tableOccupation !== order.tableOccupationId);
        orderListUtil.resultCache.orderlist[order.id] = { timestamp: order.modificationTimeStamp, found: true, tableOccupation: order.tableOccupationId }
        return true;
      }
      orderListUtil.resultCache.orderlist[order.id].found = true;
      return false;
    });


    Object.entries(orderListUtil.resultCache.orderlist).forEach(order => {
      if (!order[1].found) {
        delete orderListUtil.resultCache.orderlist[order[0]]
      }
    })
    orderlist.forEach(order => {
      if (tableOccupationsProcessed.indexOf(Number(order.tableOccupationId)) === -1)
        tableOccupationsProcessed.push(Number(order.tableOccupationId));
    });
    orderListUtil.resultCache.tables = orderListUtil.resultCache.tables.filter(table => tableOccupationsProcessed.indexOf(table.tableOccupation) === -1);
    orderListUtil.resultCache.ordersByCustomer = orderListUtil.resultCache.ordersByCustomer.filter(table => tableOccupationsProcessed.indexOf(table.tableOccupation) === -1);
    orderListUtil.resultCache.ordersByPayer = orderListUtil.resultCache.ordersByPayer.filter(table => tableOccupationsProcessed.indexOf(table.tableOccupation) === -1);
    orderListUtil.resultCache.ordersByTiming = orderListUtil.resultCache.ordersByTiming.filter(table => tableOccupationsProcessed.indexOf(table.tableOccupation) === -1);
    orderListUtil.resultCache.ordersByMenuItem = orderListUtil.resultCache.ordersByMenuItem.filter(table => tableOccupationsProcessed.indexOf(table.tableOccupation) === -1);

  } else {
    orderlist = _orderlist.filter(order => !orderListUtil.tablesOverview || ((typeof localStorage.tableOccupationSelected === "undefined" || Number(localStorage.tableOccupationSelected) === Number(order.tableOccupationId)) && (Number(localStorage.tableNumberSelected) === order.tableNumber)));
  }

  orderlist.forEach(order => {
    if (tableOccupationsProcessed.indexOf(order.tableOccupationId) === -1)
      tableOccupationsProcessed.push(Number(order.tableOccupationId));
  });
  const age = now - 10 * 24 * 60 * 60 * 60000;
  orderlist = _orderlist.filter(order => tableOccupationsProcessed.indexOf(Number(order.tableOccupationId)) !== -1 && (!order.paid || order.created >= age));

  //if (orderlist.length > 0)
  ///console.log("orders to process: ", orderlist.length);

  //console.log(states, show);

  ajaxCallStart("processing");

  var ordersByCustomer = new SortedSet({ comparator: customerComparator });
  var ordersByTiming = new SortedSet({ comparator: timingComparator });
  var ordersByMenuItem = new SortedSet({ comparator: menuItemComparator });

  var tables = new SortedSet({ comparator: timingComparator });
  var unproccessed = false;
  var count = 0;

  var statistics = {
    inStateTimeStamp: getStateMap(),
    inStateDurations: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    stateMap: getStateMap(),
    unprinted: 0,
    printed: 0,
    partprinted: 0,
    beingprinted: 0,
    printFailed: 0
  };

  productionLineUsage = {};

  const pickupTable = auth.myStatus.restaurant_settings["enabled-features"].pickup ? auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"] : "";
  const homeDeliveryTable = auth.myStatus.restaurant_settings["enabled-features"].homedelivery ? auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"] : "";

  var tableMatesWithoutOrders = new SortedSet({ comparator: customerComparator });
  if (typeof admin.tableMates != "undefined")
    admin.tableMates.forEach(function (mateid) {
      let mate = getTableMate(mateid);
      const tableNumber = mate.tableNumber;
      //if (tableNumber != pickupTable && tableNumber != homeDeliveryTable && tableOccupationsProcessed.indexOf(mate.tableOccupationId) !== -1)
      if (SortedSet.find(tableMatesWithoutOrders, mate) == null) tableMatesWithoutOrders.insert(mate);
    });

  function orderComparator(a, b) {
    return a.id - b.id;
  }

  var sortedOrders = new SortedSet({
    comparator: orderComparator
  });

  const pickupTableNumber = getPickupTableNumber();
  const homedeliveryTableNumber = getHomeDeliveryTableNumber();

  orderlist.forEach(function (order) {

    try {
      if (order.recordState == "DELETED") return;

      var orderState = getStateOfOrder(order);

      if (states.indexOf(orderState) >= 0 && show(order)) {

        order.defaultProductionLinesIds.split(",").forEach(function (pl) {
          if (typeof productionLineUsage[pl] == "undefined") {
            productionLineUsage[pl] = 0;
          }
          if (orderState == "accepted" || orderState == "producing") productionLineUsage[pl]++;
        });

        var tableMate = getTableMate(order.forWhomId);
        if (tableMate == null) {
          // Most probably the order is for a table which was
          // closed, but the table occupation was not closed;
          return;
        }

        const isPickup = order.tableNumber === pickupTableNumber;
        const isHomeDelivery = order.tableNumber === homedeliveryTableNumber;

        count++;

        //var oldMate = tableMatesWithoutOrders.get(tableMate);
        var oldMate = SortedSet.find(tableMatesWithoutOrders, tableMate);
        if (oldMate != null) tableMatesWithoutOrders.remove(tableMate);

        const orderMenuItem = getMenuItem(order.menuItem.id);
        const tableOccupation = getTableOccupation(order.tableOccupationId);

        var orderbyTiming = createOrderByTiming(order, orderMenuItem, tableOccupation, ignoreOrderSequenceNumber);

        var orderbyTiming2 = SortedSet.find(tables, orderbyTiming);
        if (orderbyTiming2 == null) {
          tables.insert(orderbyTiming);
          orderbyTiming2 = orderbyTiming;
          orderbyTiming2.orders = new SortedSet({ comparator: tableComparator });
        } else {
        }
        if (orderbyTiming2.orderSequenceNumber > order.orderSequenceNumber) orderbyTiming2.orderSequenceNumber = order.orderSequenceNumber;
        if (order.defaultProductionLinesIds)
          order.defaultProductionLinesIds.split(",").forEach(function (pl) {
            if (pl == "") return;
            if (typeof orderbyTiming2.productionLinesInvolved["pl" + pl] == "undefined")
              orderbyTiming2.productionLinesInvolved["pl" + pl] = {
                name: getProductionLine(pl) == null ? "N/A" : getProductionLine(pl).name,
                id: pl
              };
            orderbyTiming2.productionLinesInvolved["pl" + pl].count = orderbyTiming2.productionLinesInvolved["pl" + pl].count ? orderbyTiming2.productionLinesInvolved["pl" + pl].count + 1 : 1;
          });

        const table = createTable(order, ignoreOrderSequenceNumber);

        var table2 = SortedSet.find(orderbyTiming2.orders, table);
        if (table2 == null) {
          orderbyTiming2.orders.insert(table);
          table2 = table;
          table2.orders = new SortedSet({ comparator: menuItemComparator });
        } else {
          table2.oldestOrder = Math.min(table2.oldestOrder, order.modificationTimeStamp);
        }
        if (/*order.orderedById!=localStorage.id && */ table2.orderedByIds.indexOf(order.orderedById) == -1) {
          table2.orderedByIds.push(order.orderedById);
          orderbyTiming2.orderedByIds.push(order.orderedById);
        }

        if (order.defaultProductionLinesIds)
          order.defaultProductionLinesIds.split(",").forEach(function (pl) {
            if (pl == "") return;
            if (typeof table2.productionLinesInvolved[pl.name] == "undefined")
              table2.productionLinesInvolved["pl" + pl] = {
                name: getProductionLine(pl) == null ? "N/A" : getProductionLine(pl).name,
                id: pl
              };
            table2.productionLinesInvolved["pl" + pl].count = table2.productionLinesInvolved["pl" + pl].count ? table2.productionLinesInvolved["pl" + pl].count + 1 : 1;
          });

        const customer = getTableMate(order.forWhomId);

        var menuItem = createMenuItem(order, tableOccupation, isPickup, isHomeDelivery, customer, ignoreOrderSequenceNumber);

        var menuItem2 = SortedSet.find(table2.orders, menuItem);
        if (menuItem2 == null) {
          table2.orders.insert(menuItem);
          orderbyTiming2.rows++;
          table2.rows2++;
          menuItem2 = menuItem;
          menuItem2.orders = new SortedSet({ comparator: orderComparator });
        } else {
        }
        menuItem2.maxOrderId = Math.max(order.id, menuItem2.maxOrderId);

        if (order.defaultProductionLinesIds)
          order.defaultProductionLinesIds.split(",").forEach(function (pl) {
            if (pl == "") return;
            menuItem2.productionLinesInvolved["pl" + pl] = productionLineUsage[pl];
          });

        var printable = false;
        if (order.defaultProductionLinesIds != "") {
          order.defaultProductionLinesIds.split(",").forEach(id => {
            var pl = getProductionLine(id);
            if (pl.printer && pl.printer != null && pl.printer.id) printable = true;
          });
        }
        //if (order.state === 'cancelled')
        //printable = false;
        if (!order.printed && order.state !== "selected" && order.state !== "ordered") {
          if (printable) {
            if (isPartPrinted(order.printStatus)) {
              statistics.partprinted++;
              orderbyTiming2.partprinted++;
              table2.partprinted++;
              menuItem2.partprinted++;
            }
            if (isBeingPrinted(order.printStatus)) {
              statistics.beingprinted++;
              orderbyTiming2.beingprinted++;
              table2.beingprinted++;
              menuItem2.beingprinted++;
            } else {
            }
            if (isPrintFailed(order.printStatus)) {
              statistics.printFailed++;
              orderbyTiming2.printFailed++;
              table2.printFailed++;
              menuItem2.printFailed++;
              statistics.unprinted++;
              orderbyTiming2.unprinted++;
              table2.unprinted++;
              menuItem2.unprinted++;
            } else if (order.printed && printable) {
              statistics.printed++;
              orderbyTiming2.printed++;
              table2.printed++;
              menuItem2.printed++;
            } else {
              if (isNotPrinted(order.printStatus)) {
                statistics.unprinted++;
                orderbyTiming2.unprinted++;
                table2.unprinted++;
                menuItem2.unprinted++;
              }
            }

          }
        } else if (order.printed && printable) {
          statistics.printed++;
          orderbyTiming2.printed++;
          table2.printed++;
          menuItem2.printed++;
        }
        updateDurationStatistics(orderbyTiming2, order);
        updateDurationStatistics(table2, order);
        updateDurationStatistics(menuItem2, order);

        if (auth.myStatus.restaurant_settings["enabled-features"].homedelivery && order.menuItem.id === auth.myStatus.restaurant_settings["enabled-features"].homedelivery.homedelivery_menuitem) {
        } else {
          statistics.stateMap[orderState]++;
        }
        updateDurationStatistics(statistics, order);

        /*
         * table2.inStateTimeStamp['_'+orderState] =
         * Math.max(table2.inStateTimeStamp['_'+orderState] ?
         * table2.inStateTimeStamp['_'+orderState] : 0,
         * orderbyTiming2.inStateTimeStamp[orderState]);
         * menuItem2.inStateTimeStamp['_'+orderState] =
         * Math.max(menuItem2.inStateTimeStamp['_'+orderState] ?
         * menuItem2.inStateTimeStamp['_'+orderState] : 0,
         * table2.inStateTimeStamp[orderState]);
         */

        if (orderState != "cancelled") {
          orderbyTiming2.orderCount++;
          menuItem2.orderCount++;
          table2.orderCount++;

          var actualPrice = order.fullPrice - order.discount - order.tableMateDiscount;

          if (order.orderedByCustomer && order.paid) {
            var pp = order.fullPrice;
            order.childOrders.forEach(oo => pp -= (oo.addition ? 1 : -1) * oo.price);
            actualPrice = pp;
          }

          table2.price += order.paid ? actualPrice : order.price;
          orderbyTiming2.price += order.fullPrice;
          menuItem2.price = Math.round(order.fullPrice);
          menuItem2.totalPrice += Math.round(order.fullPrice);
          table2.discountedPrice += order.paid ? 0 : actualPrice;
          orderbyTiming2.discountedPrice += order.paid ? 0 : actualPrice;
          menuItem2.discountedPrice += Math.round(order.orderedByCustomer && order.paid ? order.fullPrice - order.discount - order.tableMateDiscount : actualPrice);

          if (order.discount > 0) {
            if (typeof table2.discount[order.discountComment] != "undefined") {
              table2.discount[order.discountComment] += order.discount;
            } else {
              table2.discount[order.discountComment] = order.discount;
            }
            if (typeof orderbyTiming2.discount[order.discountComment] != "undefined") {
              orderbyTiming2.discount[order.discountComment] += order.discount;
            } else {
              orderbyTiming2.discount[order.discountComment] = order.discount;
            }
            if (typeof menuItem2.discount[order.discountComment] != "undefined") {
              menuItem2.discount[order.discountComment] += order.discount;
            } else {
              menuItem2.discount[order.discountComment] = order.discount;
            }
          }
          orderbyTiming2.tableMateDiscount += order.tableMateDiscount;
          table2.tableMateDiscount += order.tableMateDiscount;
          menuItem2.tableMateDiscount += order.tableMateDiscount;

        }
        menuItem2.orders.insert(order);
        var productionLineSelected = false;
        order.defaultProductionLinesIds.split(",").forEach(function (pl) {
          if (typeof localStorage.productionLineSelected != "undefined")
            localStorage.productionLineSelected.split(",").forEach(productionLine => {
              if (productionLine == pl) productionLineSelected = true;
            });
          else productionLineSelected = true;
        });
        if (productionLineSelected) {
          orderbyTiming2.orderIds.push(order.id);
          table2.orderIds.push(order.id);
          menuItem2.orderIds.push(order.id);

          if (auth.myStatus.restaurant_settings["enabled-features"].homedelivery && order.menuItem.id === auth.myStatus.restaurant_settings["enabled-features"].homedelivery.homedelivery_menuitem) {
          } else {
            if (table2.stateMap[orderState].indexOf(order.id) === -1)
              table2.stateMap[orderState].push(order.id);
          }
          if (orderbyTiming2.stateMap[orderState].indexOf(order.id) === -1)
            orderbyTiming2.stateMap[orderState].push(order.id);


          table2.orderSequenceNumberVisible = true;
        }
        if (order.statePerProductionLine && Object.keys(order.statePerProductionLine).length > 0)
          Object.keys(order.statePerProductionLine).forEach(pl => {
            if (auth.myStatus.restaurant_settings["enabled-features"].homedelivery && order.menuItem.id === auth.myStatus.restaurant_settings["enabled-features"].homedelivery.homedelivery_menuitem) {
            } else {
              if (menuItem2.stateMap[order.statePerProductionLine[pl].state].indexOf(order.id) === -1)
                menuItem2.stateMap[order.statePerProductionLine[pl].state].push(order.id);
            }
          });
        else {
          if (auth.myStatus.restaurant_settings["enabled-features"].homedelivery && order.menuItem.id === auth.myStatus.restaurant_settings["enabled-features"].homedelivery.homedelivery_menuitem) {
          } else {
            if (menuItem2.stateMap[orderState].indexOf(order.id) === -1)
              menuItem2.stateMap[orderState].push(order.id);
          }
        }

        // group by customer
        var orderbyCustomer = createOrderByCustomer(order, tableMate);

        var orderbyCustomer2 = SortedSet.find(ordersByCustomer, orderbyCustomer);
        if (orderbyCustomer2 == null) {
          ordersByCustomer.insert(orderbyCustomer);
          orderbyCustomer2 = orderbyCustomer;
          orderbyCustomer2.timings = new SortedSet({ comparator: timingComparator });
        } else {
          orderbyCustomer2.oldestOrder = Math.min(orderbyCustomer2.oldestOrder, order.modificationTimeStamp);
        }

        if (order.paid != true && orderState != "cancelled") {
          orderbyCustomer2.stateMap.unpaid = orderbyCustomer2.stateMap.unpaid + 1;
          statistics.stateMap.unpaid++;
          if (getTableMate(tableMate.payingMateId ? tableMate.payingMateId : tableMate.id) && hasState(getTableMate(tableMate.payingMateId ? tableMate.payingMateId : tableMate.id).states, "wantsToPay")) {
            orderbyTiming2.stateMap.wantsToPay.push(order.id);

            orderbyCustomer2.stateMap.wantsToPay = orderbyCustomer2.stateMap.wantsToPay + 1;
            menuItem2.stateMap.wantsToPay = (typeof menuItem2.stateMap.wantsToPay != "undefined" ? menuItem2.stateMap.wantsToPay : 0) + 1;
            table2.stateMap.wantsToPay = (typeof table2.stateMap.wantsToPay != "undefined" ? table2.stateMap.wantsToPay : 0) + 1;
          }
        } else {
          if (orderState != "cancelled") {
            statistics.stateMap.paid++;
            orderbyCustomer2.stateMap.paid = orderbyCustomer2.stateMap.paid + 1;
            menuItem2.stateMap.paid = menuItem2.stateMap.paid + 1;
            table2.stateMap.paid = table2.stateMap.paid + 1;
            orderbyTiming2.stateMap.paid.push(order.id);
          }
        }
        if (orderState == "ordered" || orderState == "accepted") {
          orderbyTiming2.unprocessed = true;
          table2.unprocessed = true;
          menuItem2.unprocessed = true;
        }

        statistics.stateMap[orderState]++;

        var orderbyTiming = {
          timing: order.timing,
          tableNumber: order.tableNumber
        };
        var orderbyTiming2 = SortedSet.find(orderbyCustomer2.timings, orderbyTiming);
        if (orderbyTiming2 == null) {
          orderbyCustomer2.timings.insert(orderbyTiming);
          orderbyTiming2 = orderbyTiming;
          orderbyTiming2.orders = new SortedSet({ comparator: menuItemComparator });
        } else {
        }

        var menuItem = createMenuItem2(order, tableMate, orderState, orderComparator);

        var menuItem2 = SortedSet.find(orderbyTiming2.orders, menuItem);
        if (menuItem2 == null) {
          orderbyTiming2.orders.insert(menuItem);
          // orderbyTiming2.rows++;
          // table2.rows2++;
          menuItem2 = menuItem;
        } else {
        }
        menuItem2.orders.insert(order);
        menuItem2.orderIds.push(order.id);
        menuItem2.count++;
        orderbyTiming2.count++;
        orderbyCustomer2.count++;
        //count[order.menuItem.entityType] = (count[order.menuItem.entityType] ? count[order.menuItem.entityType] : 0) + 1;

        var actualPrice = order.fullPrice - order.discount - order.tableMateDiscount;
        var actualPrice2 = order.fullPrice - order.discount - order.tableMateDiscount;

        if (order.orderedByCustomer && order.paid) {
          var pp = order.fullPrice;
          order.childOrders.forEach(oo => pp -= (oo.addition ? 1 : -1) * oo.price);
          actualPrice = pp;
        }
        if (order.orderedByCustomer) {
          var pp = order.fullPrice;
          order.childOrders.forEach(oo => pp -= (oo.addition ? 1 : -1) * oo.price);
          actualPrice2 = pp;
        }

        menuItem2.price = order.orderedByCustomer ? actualPrice2 : order.price;

        orderbyTiming2.price += order.paid ? 0 : order.orderedByCustomer ? actualPrice : order.fullPrice - order.discount;
        orderbyCustomer2.price += order.paid ? 0 : order.orderedByCustomer ? actualPrice : order.fullPrice - order.discount;
        orderbyCustomer2.discountedPrice += Math.round(actualPrice);
        menuItem2.discountedPrice += Math.round(actualPrice);

        if (orderState == "ordered" || orderState == "accepted") {
          orderbyCustomer2.unprocessed = true;
          orderbyTiming2.unprocessed = true;
        }

        // group by timing
        var orderbyTiming = {
          timing: order.timing,
          tableNumber: order.tableNumber,
          tableOccupation: order.tableOccupationId,
          tableOccupationSequenceNumber: order.tableOccupationSequenceNumber,
          price: 0,
          count: 0
        };
        var orderbyTiming2 = SortedSet.find(ordersByTiming, orderbyTiming);
        if (orderbyTiming2 == null) {
          ordersByTiming.insert(orderbyTiming);
          orderbyTiming2 = orderbyTiming;
          orderbyTiming2.customers = new SortedSet({ comparator: customerComparator });
        } else {
        }

        updateDurationStatistics(orderbyCustomer2, order);
        updateDurationStatistics(menuItem2, order);

        var orderbyCustomer = createOrderByCustomer2(order, tableMate, orderState);

        var orderbyCustomer2 = SortedSet.find(orderbyTiming2.customers, orderbyCustomer);
        if (orderbyCustomer2 == null) {
          orderbyTiming2.customers.insert(orderbyCustomer);
          orderbyCustomer2 = orderbyCustomer;
          orderbyCustomer2.orders = new SortedSet({ comparator: menuItemComparator });
        } else {
          orderbyCustomer2.oldestOrder = Math.min(orderbyCustomer2.oldestOrder, order.modificationTimeStamp);
        }

        var menuItem = createMenuItem3(order, tableMate, orderState, orderComparator);

        var menuItem2 = SortedSet.find(orderbyCustomer2.orders, menuItem);
        if (menuItem2 == null) {
          orderbyCustomer2.orders.insert(menuItem);
          // orderbyTiming2.rows++;
          // table2.rows2++;
          menuItem2 = menuItem;
        } else {
        }

        menuItem2.count++;

        menuItem2.orders.insert(order);
        menuItem2.orderIds.push(order.id);
        orderbyCustomer2.price += order.paid ? 0 : order.fullPrice - order.discount;
        menuItem2.price = order.fullPrice;
        orderbyTiming2.price += order.paid ? 0 : order.fullPrice - order.discount;
        orderbyTiming2.discountedPrice += Math.round(actualPrice);
        menuItem2.discountedPrice += Math.round(actualPrice);

        orderbyTiming2.count += 1;

        if (orderState == "ordered" || orderState == "accepted") {
          orderbyTiming2.unprocessed = true;
          orderbyCustomer2.unprocessed = true;
        }

        // group by menu item
        const orderByMenuItem = createOrderByMenuItem(order);

        var orderByMenuItem2 = SortedSet.find(ordersByMenuItem, orderByMenuItem);
        if (orderByMenuItem2 == null) {
          ordersByMenuItem.insert(orderByMenuItem);
          orderByMenuItem2 = orderByMenuItem;
          orderByMenuItem2.orders = new SortedSet({ comparator: orderComparator });
        } else {
          orderByMenuItem2.oldestOrder = Math.min(orderbyCustomer2.oldestOrder, order.modificationTimeStamp);
        }
        orderByMenuItem2.orders.insert(order);
        orderByMenuItem2.price = order.fullPrice - order.discount;
      }

    } catch (ex) {
      console.error(ex, ex);
    }

  });

  var tables2 = [];
  var orderedByIds = {};
  tables.forEach(function (v) {
    v.price = Math.round(v.price);
    v.discountedPrice = Math.round(v.discountedPrice);
    var orders2 = [];
    v.orders.forEach(function (v) {
      v.price = Math.round(v.price);
      v.discountedPrice = Math.round(v.discountedPrice);
      var orders3 = [];

      var ordersByMaxOrderId = new SortedSet({
        comparator: (a, b) => {
          return a.maxOrderId - b.maxOrderId;
        }
      });

      v.orders.forEach(function (v) {
        v.price = Math.round(v.price);
        v.discountedPrice = Math.round(v.discountedPrice);
        ordersByMaxOrderId.insert(v);
      });

      ordersByMaxOrderId.forEach(function (v) {
        var orders4 = [];
        v.orders.forEach(function (v) {
          orders4[orders4.length] = v;
        });
        v.orders = orders4;
        orders3[orders3.length] = v;
      });
      v.orders = orders3;
      orders2[orders2.length] = v;
    });
    v.orders = orders2;

    v.orderedByIds.forEach(i => {
      var orderedByIdsArray = orderedByIds[v.tableNumber + "-" + v.tableOccupation];
      if (orderedByIdsArray == undefined) {
        orderedByIdsArray = [];
        orderedByIds[v.tableNumber + "-" + v.tableOccupation] = orderedByIdsArray;
      }
      if (orderedByIdsArray.indexOf(i) == -1 && i != localStorage.id) orderedByIdsArray.push(i);
    });

    const table2 = tables2[tables2.length - 1];

    if (tables2.length > 0 && table2.date == v.date && table2.delieveredById == v.delieveredById && table2.tableNumber == v.tableNumber && table2.tableOccupation == v.tableOccupation && table2.timing == v.timing) {
      if (table2.timing != v.timing && table2.timing == "asap" && tables2.length == 1) {
        table2.timing = v.timing;
      }

      v.orders.forEach(function (v2) {
        table2.orders.push(v2);
      });
      for (var key in v.productionLinesInvolved) {
        if (table2.productionLinesInvolved[key])
          table2.productionLinesInvolved[key].count = table2.productionLinesInvolved[key]
            ? table2.productionLinesInvolved[key].count + v.productionLinesInvolved[key].count
            : v.productionLinesInvolved[key];
        else {
          table2.productionLinesInvolved[key] = v.productionLinesInvolved[key];
        }
      }
      v.orderIds.forEach(function (id) {
        table2.orderIds.push(id);
      });

      table2.orderSequenceNumber += "_" + v.orderSequenceNumber;
      table2.rows += v.rows - 1;
      table2.orderCount += v.orderCount;
      table2.unprinted += v.unprinted;
      table2.partprinted += v.partprinted;
      table2.beingprinted += v.beingprinted;
      table2.printFailed += v.printFailed;
      table2.printed += v.printed;
      table2.price += v.price;
      table2.discountedPrice += v.discountedPrice;
      v.orderedByIds.forEach(i => {
        table2.orderedByIds.push(i);
      });
      Object.keys(v.stateMap).forEach(function (key, index) {
        v.stateMap[key].forEach(function (id) {
          if (table2.stateMap[key].indexOf(id) === -1)
            table2.stateMap[key].push(id);
        });
      });
      Object.keys(v.inStateDurations).forEach(function (key, index) {
        table2.inStateDurations[key] = Math.max(table2.inStateDurations[key] ? table2.inStateDurations[key] : 0, v.inStateDurations[key]);
      });
    } else {
      tables2.push(v);
    }
  });
  tables = tables2;
  tables.forEach(t => {
    if (orderedByIds[t.tableNumber + "-" + t.tableOccupation] == null || orderedByIds[t.tableNumber + "-" + t.tableOccupation].length == 0) {
      t.orderedByIds = [];
      t.orders.forEach(o => {
        o.orderedByIds = [];
      });
    }
  });
  var tableStatus = {};


  tables.forEach(table => {
    var finished = table.stateMap["accepted"].length + table.stateMap["producing"].length + table.stateMap["ready"].length + table.stateMap["delivering"].length == 0;
    if (table.timing == "asap") {
      if (tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"]) {
        tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].finished = tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].finished && finished;
        if (!finished)
          tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].inStateTimeStamp = Math.min(
            tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].inStateTimeStamp,
            table.inStateTimeStamp["active"]
          );
      } else tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"] = { timing: "appetizer", max_timing: "asap", inStateTimeStamp: table.inStateTimeStamp["active"], done: false, finished: finished };
      return;
    }
    if (typeof tableStatus[table.tableOccupation + "-" + table.tableNumber] == "undefined") {
      tableStatus[table.tableOccupation + "-" + table.tableNumber] = { timing: table.timing, max_timing: table.timing, inStateTimeStamp: table.inStateTimeStamp["active"], done: false };
      tableStatus[table.tableOccupation + "-" + table.tableNumber + "-" + table.timing] = { finished: finished };
    } else {
      if (tableStatus[table.tableOccupation + "-" + table.tableNumber + "-" + table.timing])
        tableStatus[table.tableOccupation + "-" + table.tableNumber + "-" + table.timing].finished = tableStatus[table.tableOccupation + "-" + table.tableNumber + "-" + table.timing].finished && finished;
      else tableStatus[table.tableOccupation + "-" + table.tableNumber + "-" + table.timing] = { finished: finished };
      //if (!finished)
      //	tableStatus[table.tableOccupation+"-"+table.tableNumber].inStateTimeStamp = Math.min(tableStatus[table.tableOccupation+"-"+table.tableNumber].inStateTimeStamp, table.inStateTimeStamp['active']);
    }
    if (finished) {
      if (tableStatus[table.tableOccupation + "-" + table.tableNumber].inStateTimeStamp < table.inStateTimeStamp["delivered"]) {
        tableStatus[table.tableOccupation + "-" + table.tableNumber].inStateTimeStamp = table.inStateTimeStamp["delivered"];
      }
      tableStatus[table.tableOccupation + "-" + table.tableNumber].timing = table.timing;
    }
    if (alltimings.indexOf(tableStatus[table.tableOccupation + "-" + table.tableNumber].max_timing) < alltimings.indexOf(table.timing)) {
      tableStatus[table.tableOccupation + "-" + table.tableNumber].max_timing = table.timing;
      tableStatus[table.tableOccupation + "-" + table.tableNumber].inStateTimeStamp = Math.max(tableStatus[table.tableOccupation + "-" + table.tableNumber].inStateTimeStamp, table.inStateTimeStamp["active"]);
    }
  });

  tables.forEach(table => {
    if (table.timing == "asap") {
      var finished = tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].finished;
      if (alltimings.indexOf(table.max_timing) < alltimings.indexOf(tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].max_timing))
        table.max_timing = tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].max_timing;
      table.asapNotDelivered = !finished;
      table.inActiveStateTimeStamp = tableStatus[table.tableOccupation + "-" + table.tableNumber + "-asap"].inStateTimeStamp;
      return;
    }
    var finished2 = table.stateMap["accepted"].length + table.stateMap["producing"].length + table.stateMap["ready"].length + table.stateMap["delivering"].length == 0;
    var finished = tableStatus[table.tableOccupation + "-" + table.tableNumber + "-" + table.timing].finished;
    if (alltimings.indexOf(table.max_timing) < alltimings.indexOf(tableStatus[table.tableOccupation + "-" + table.tableNumber].max_timing)) {
      table.max_timing = tableStatus[table.tableOccupation + "-" + table.tableNumber].max_timing;
    }
    if (!finished2) {
      if (tableStatus[table.tableOccupation + "-" + table.tableNumber].done != true || tableStatus[table.tableOccupation + "-" + table.tableNumber].timing == table.timing) {
        table.inActiveStateTimeStamp = tableStatus[table.tableOccupation + "-" + table.tableNumber].inStateTimeStamp;
        table.inStateDurations["accepted"] = (new Date().getTime() - table.inActiveStateTimeStamp) / 1000;
        tableStatus[table.tableOccupation + "-" + table.tableNumber].timing = table.timing;
        table.notDelivered = true;
      }
      tableStatus[table.tableOccupation + "-" + table.tableNumber].done = true;
    } else {
      table.inActiveStateTimeStamp = table.inStateTimeStamp["delivered"];
    }
  });

  var ordersByCustomer2 = [];
  ordersByCustomer.forEach(function (v) {
    v.price = Math.round(v.price);
    v.discountedPrice = Math.round(v.discountedPrice);

    var timings2 = {};
    v.timings.forEach(function (v) {
      var orders2 = [];
      v.orders.forEach(function (v) {
        orders2.push(v);
        var orders3 = [];
        v.orders.forEach(function (v) {
          orders3.push(v);
        });
        v.orders = orders3;
      });
      v.orders = orders2;
      timings2[v.timing] = v;
    });
    v.timings = timings2;
    v.price -= orderListUtil.statesShown === "selected" ? getTableMate(v.customer.id).selected_order_discount : getTableMate(v.customer.id).discount;
    ordersByCustomer2.push(v);
  });
  ordersByCustomer = ordersByCustomer2;

  var ordersByPayer = new SortedSet({ comparator: customerComparator });

  ordersByCustomer.forEach(function (orderByCustomer) {
    var orderByPayer = {
      customer: orderByCustomer.customer.payingMateId == 0 ? orderByCustomer.customer : getTableMate(orderByCustomer.customer.payingMateId),
      tableNumber: orderByCustomer.tableNumber,
      tableOccupation: orderByCustomer.tableOccupation,
      ordersByCustomer: new SortedSet({
        comparator: customerComparator
      }),
      price: 0,
      discountedPrice: 0,
      count: 0,
      stateMap: {
        paid: 0,
        unpaid: 0
      },
      inStateDurations: getStateMap(),
      inStateTimeStamp: getStateMap(),
      inStateTimeStampByProductionLine: getStateMap(),
    };

    var orderByPayer2 = SortedSet.find(ordersByPayer, orderByPayer);

    if (orderByPayer2 == null) {
      orderByPayer2 = orderByPayer;
      ordersByPayer.insert(orderByPayer2);
    } else {
    }
    orderByPayer2.ordersByCustomer.insert(orderByCustomer);
    orderByPayer2.price += orderByCustomer.price;
    orderByPayer2.discountedPrice += orderByCustomer.discountedPrice;
    orderByPayer2.count += orderByCustomer.count;
    orderByPayer2.stateMap.paid += orderByCustomer.stateMap.paid;
    orderByPayer2.stateMap.unpaid += orderByCustomer.stateMap.unpaid;
    Object.keys(orderByCustomer.inStateDurations).forEach(function (key, index) {
      orderByPayer2.inStateDurations[key] = Math.max(orderByCustomer.inStateDurations[key] ? orderByCustomer.inStateDurations[key] : 0, orderByPayer2.inStateDurations[key]);
    });

  });

  tableMatesWithoutOrders.forEach(function (mate) {
    const tableNumber = mate.tableNumber;
    if (tableNumber == pickupTable || tableNumber == homeDeliveryTable)
      return;

    var orderByPayer = {
      customer: mate.payingMateId == 0 ? mate : getTableMate(mate.payingMateId) != null ? getTableMate(mate.payingMateId) : mate,
      tableNumber: mate.tableNumber,
      tableOccupation: mate.tableOccupationId,
      count: 0,
      ordersByCustomer: new SortedSet({
        comparator: customerComparator
      }),
      price: 0,
      stateMap: {
        paid: 0,
        unpaid: 0
      }
    };

    var orderByPayer2 = SortedSet.find(ordersByPayer, orderByPayer);

    if (orderByPayer2 == null) {
      orderByPayer2 = orderByPayer;
      ordersByPayer.insert(orderByPayer2);
    } else {
    }
    orderByPayer2.ordersByCustomer.insert({
      customer: mate,
      tableNumber: mate.tableNumber,
      tableOccupation: mate.tableOccupationId,
      orders: [],
      price: 0,
      unprocessed: false,
      timings: []
    });
  });
  var ordersByPayer2 = [];
  ordersByPayer.forEach(function (v) {
    var ordersByCustomer2 = [];
    v.ordersByCustomer.forEach(function (v) {
      ordersByCustomer2[ordersByCustomer2.length] = v;
    });
    v.ordersByCustomer = ordersByCustomer2;
    ordersByPayer2[ordersByPayer2.length] = v;
  });
  ordersByPayer = ordersByPayer2;

  var ordersByTiming2 = [];
  ordersByTiming.forEach(function (v) {
    var customers2 = [];
    v.customers.forEach(function (v) {
      var orders2 = [];
      v.orders.forEach(function (v) {
        var orders3 = [];
        v.orders.forEach(function (v) {
          orders3.push(v);
        });
        v.orders = orders3;
        orders2.push(v);
      });
      v.orders = orders2;
      customers2.push(v);
    });
    v.customers = customers2;
    ordersByTiming2.push(v);
  });
  ordersByTiming = ordersByTiming2;

  var ordersByMenuItem2 = [];
  ordersByMenuItem.forEach(function (v) {
    var orders2 = [];
    v.orders.forEach(function (v) {
      orders2[orders2.length] = v;
    });
    v.orders = orders2;
    ordersByMenuItem2[ordersByMenuItem2.length] = v;
  });

  ordersByMenuItem = ordersByMenuItem2;
  ajaxCallEnd("processing");

  //ordersByPayer = ordersByPayer.filter(table => table.orders)

  function calculatePrice(o) {
    switch (o.quantityType2) {
      case "day":
      case "hour":
      case "min":
        const order = getOrderById(o.orderIds[0]);
        if (order.paid || order.state != "producing")
          return;
        var quantity = Math.floor(o.inStateDurations.producing / 60);
        const menuItem = getMenuItem(o.menuItem.id);
        const q = (menuItem.availableQuantities.length) ? menuItem.availableQuantities[0].quantity : 1;
        switch (o.quantityType2) {
          case "day":
            quantity = Math.ceil(quantity / 24 / 60 / q) * q;
            break;
          case "hour":
            quantity = Math.ceil(quantity / 60 / q) * q;
            break;
          case "min":
            break;
          default:
        }
        o.quantity = quantity;
        const originalPrice = o.price;
        o.price = Math.round(quantity * menuItem.unitPrice / q);
        o.discountedPrice += o.price - originalPrice;
        order.price = o.price;
        order.discountedPrice = o.price - originalPrice;
        order.fullPrice += o.price - originalPrice;
        break;
      default:
    }
  }
  //console.log("result in 0", new Date().getTime() - now);

  var doDurationStatistics = false;
  if (!durationUpdatedTimestamp || new Date().getTime() - durationUpdatedTimestamp > 60000) {
    doDurationStatistics = true;
    durationUpdatedTimestamp = new Date().getTime();
  }

  orderListUtil.resultCache.tables.forEach(o => {
    doDurationStatistics && o.orderIds.forEach(id => updateDurationStatistics(o, getOrderById(id)));
    o.orders.forEach(oo => {
      doDurationStatistics && oo.orderIds.forEach(id => updateDurationStatistics(oo, getOrderById(id)));
      oo.orders.forEach(oooo => {
        doDurationStatistics && oooo.orderIds.forEach(id => updateDurationStatistics(oooo, getOrderById(id)));
        var price = oooo.price;
        calculatePrice(oooo);
        var finalPrice = oooo.price;
        oo.price += finalPrice - price;
        o.price += finalPrice - price;
        o.discountedPrice += finalPrice - price;
        oo.discountedPrice += finalPrice - price;
      })
    });
  })
  //console.log("result in 1", new Date().getTime() - now);

  tables.forEach(o => {
    o.orders.forEach(oo => {
      oo.orders.sort(menuItemComparator);
      oo.orders.forEach(oooo => {
        var price = oooo.price;
        calculatePrice(oooo);
        var finalPrice = oooo.price;
        oo.price += finalPrice - price;
        o.price += finalPrice - price;
        o.discountedPrice += finalPrice - price;
        oo.discountedPrice += finalPrice - price;
      })
    });
  })


  orderListUtil.resultCache.ordersByPayer.forEach(o => {
    Object.values(o.ordersByCustomer).forEach(oo => {
      Object.values(oo.timings).forEach(ooo => {
        ooo.orders.forEach(oooo => {
          var price = oooo.price;
          calculatePrice(oooo);
          var finalPrice = oooo.price;
          ooo.price += finalPrice - price;
          oo.price += finalPrice - price;
          o.price += finalPrice - price;
          o.discountedPrice += finalPrice - price;
          oo.discountedPrice += finalPrice - price;
          ooo.discountedPrice += finalPrice - price;
        })
      })
    })
  });
  ordersByPayer.forEach(o => {
    Object.values(o.ordersByCustomer).forEach(oo => {
      Object.values(oo.timings).forEach(ooo => {
        ooo.orders.forEach(oooo => {
          var price = oooo.price;
          calculatePrice(oooo);
          var finalPrice = oooo.price;
          ooo.price += finalPrice - price;
          oo.price += finalPrice - price;
          o.price += finalPrice - price;
          o.discountedPrice += finalPrice - price;
          oo.discountedPrice += finalPrice - price;
          ooo.discountedPrice += finalPrice - price;
        })
      })
    })
  });

  if (cache) {

    orderListUtil.resultCache.count = count;
    orderListUtil.resultCache.statistics = { ...orderListUtil.resultCache.statistics, ...statistics };
    orderListUtil.resultCache.tables = [...orderListUtil.resultCache.tables, ...tables].sort(timingComparator);
    orderListUtil.resultCache.ordersByCustomer = [...orderListUtil.resultCache.ordersByCustomer, ...ordersByCustomer];
    orderListUtil.resultCache.ordersByPayer = [...orderListUtil.resultCache.ordersByPayer, ...ordersByPayer];
    orderListUtil.resultCache.ordersByTiming = [...orderListUtil.resultCache.ordersByTiming, ...ordersByTiming];
    orderListUtil.resultCache.ordersByMenuItem = [...orderListUtil.resultCache.ordersByMenuItem, ...ordersByMenuItem];
    orderListUtil.resultCache.productionLineUsage = { ...orderListUtil.resultCache.productionLineUsage, ...productionLineUsage };
    orderListUtil.resultCache.tableStatus = { ...orderListUtil.resultCache.tableStatus, ...tableStatus };

    //console.log("result in", new Date().getTime() - now);
    return orderListUtil.resultCache;

  }

  //console.log("non cache", new Date().getTime() - now, tables.length);

  //console.log(tables.length, ordersByCustomer.length, ordersByPayer.length, ordersByTiming.length)

  return {
    statistics: statistics,
    count: count,
    tables: tables.sort(timingComparator),
    ordersByCustomer: ordersByCustomer,
    ordersByPayer: ordersByPayer,
    ordersByTiming: ordersByTiming,
    ordersByMenuItem: ordersByMenuItem,
    productionLineUsage: productionLineUsage,
    tableStatus: tableStatus
  };
};

function getTableMateForCustomer(id) {
  var tableMate = null;
  admin.tableMates.forEach(function (mate) {
    if (mate == id) tableMate = getTableMate(mate);
  });
  return tableMate;
}

export const getOrderElement = id => {
  var order = null;
  orderListUtil.ordersCache.forEach(function (o) {
    if (o.id == id) order = o;
  });
  return order;
};

function hasState(states, state) {
  var r = false;
  states.forEach(function (s) {
    if (s.duration == 0 && s.state == state) r = true;
  });
  return r;
}

function updateDurationStatistics(statistics, order) {
  if (!order) return;
  var inStateDurations = getStateMap();
  var orderState = getStateOfOrder(order);
  order.ordersInState.forEach(function (orderInState) {
    var duration = orderInState.duration > 0 ? orderInState.duration : (new Date().getTime() - orderInState.timestamp) / 1000;
    inStateDurations[orderInState.state] = inStateDurations[orderInState.state] ? Number(inStateDurations[orderInState.state]) + Number(duration) : duration;
    if (orderState == "ordered" && orderInState.state == "ordered")
      inStateDurations["active_accepting"] = inStateDurations["active_accepting"] ? Number(inStateDurations["active_accepting"]) + Number(duration) : duration;
    if ((orderState == "accepted" || orderState == "producing" || orderState == "ready" || orderState == "delivering" || orderState == "delivering") && (orderInState.state == "accepted" || orderInState.state == "producing" || orderInState.state == "delivering" || orderInState.state == "delivering" || orderInState.state == "delivering"))
      inStateDurations["active_delivering"] = inStateDurations["active_delivering"] ? Number(inStateDurations["active_delivering"]) + Number(duration) : duration;
  });
  statistics.inStateTimeStamp["active"] = order.inStateTimeStamp;

  var duration = (new Date().getTime() - order.inStateTimeStamp) / 1000;
  statistics.inStateDurations[orderState] = statistics.inStateDurations[orderState] ? Math.max(statistics.inStateDurations[orderState], duration) : duration;
  if (orderState == "ordered") inStateDurations["active_accepting"] = inStateDurations["active_accepting"] ? Number(inStateDurations["active_accepting"]) + Number(duration) : duration;
  if (orderState == "accepted" || orderState == "producing" || orderState == "ready" || orderState == "delivering") {
    inStateDurations["active_delivering"] = inStateDurations["active_delivering"] ? Number(inStateDurations["active_delivering"]) + Number(duration) : duration;
  }

  $.each(inStateDurations, function (key, value) {
    statistics.inStateDurations[key] = statistics.inStateDurations[key] ? Math.max(statistics.inStateDurations[key], value) : value;
    //if (key == order.state) {
    if (typeof statistics.inStateTimeStamp[key] != "undefined") statistics.inStateTimeStamp[key] = Math.max(statistics.inStateTimeStamp[key], order.inStateTimeStamp);
    else statistics.inStateTimeStamp[key] = order.inStateTimeStamp;    //}
    Object.keys(order.statePerProductionLine).forEach(productionLine => {
      if (!statistics.inStateTimeStampByProductionLine[productionLine]) {
        statistics.inStateTimeStampByProductionLine[productionLine] = {};
      }
      if (order.statePerProductionLine[productionLine].state === key) {
        if (typeof statistics.inStateTimeStampByProductionLine[productionLine][key] != "undefined")
          statistics.inStateTimeStampByProductionLine[productionLine][key] = Math.max(statistics.inStateTimeStampByProductionLine[productionLine][key], order.statePerProductionLine[productionLine].timestamp);
        else
          statistics.inStateTimeStampByProductionLine[productionLine][key] = order.statePerProductionLine[productionLine].timestamp;
      }
    });
    //console.log(">>>>>>>>>>>>"+order.timing+" "+key+" "+getDuration((new Date()-statistics.inStateTimeStamp[key])/1000));
  });
}


function createOrderByTiming(order, orderMenuItem, tableOccupation, ignoreOrderSequenceNumber) {
  const table = getTable(order.tableNumber);
  return {
    date: moment(
      order.todeliver ||
      orderMenuItem.defaultFromDate ||
      tableOccupation.bookedProperties?.bookedOccupationStart ||
      tableOccupation.occupationStart
    ).valueOf(),
    created: tableOccupation.occupationStart,
    deliveredById: order.deliveredById || 0,
    deliveredByName: order.deliveredByName,
    deliveredByCustomer: order.deliveredByCustomer,
    deliveredByIndex: order.deliveredByIndex || 0,
    courierId: order.courierId,
    tableNumber: order.tableNumber,
    tableName: table?.name || "",
    tableOccupation: order.tableOccupationId,
    tableOccupationSequenceNumber: order.tableOccupationSequenceNumber,
    productionLinesInvolved: {},
    leadCustomerName: order.leadCustomerName,
    forWhomId: order.forWhomId,
    orderSequenceNumber: ignoreOrderSequenceNumber ? 1 : order.orderSequenceNumber,
    orderedByIds: [],
    orderIds: [],
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    timing: order.timing,
    stateMap: getStateMapArray(),
    rows: 1,
    orderCount: 0,
    unprocessed: false,
    unprinted: 0,
    partprinted: 0,
    printed: 0,
    beingprinted: 0,
    printFailed: 0,
    price: 0,
    discount: {},
    discountedPrice: 0,
    tableMateDiscount: 0
  };
}

function createTable(order, ignoreOrderSequenceNumber) {
  return {
    timing: order.timing,
    defaultProductionLines: order.defaultProductionLines,
    productionLinesInvolved: {},
    oldestOrder: order.modificationTimeStamp,
    orderSequenceNumber: ignoreOrderSequenceNumber ? 1 : order.orderSequenceNumber,
    orderSequenceNumberVisible: false,
    orderedByIds: [],
    tableNumber: order.tableNumber,
    tableOccupation: order.tableOccupationId,
    tableOccupationSequenceNumber: order.tableOccupationSequenceNumber,
    leadCustomerName: order.leadCustomerName,
    leadCustomerId: order.leadCustomerId,
    orderIds: [],
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    stateMap: getStateMapArray(),
    unprocessed: false,
    rows2: 0,
    orderCount: 0,
    unprinted: 0,
    partprinted: 0,
    beingprinted: 0,
    printFailed: 0,
    printed: 0,
    price: 0,
    discountedPrice: 0,
    discount: {},
    tableMateDiscount: 0
  };
}

function createMenuItem(order, tableOccupation, isPickup, isHomeDelivery, customer, ignoreOrderSequenceNumber) {
  return {
    tableNumber: order.tableNumber,
    comment: order.comment,
    discountComment: order.discountComment,
    restaurantComment: order.restaurantComment ?? "",
    timing: order.timing,
    defaultProductionLines: order.defaultProductionLines,
    defaultProductionLinesIds: order.defaultProductionLinesIds,
    productionLinesInvolved: {},
    menuItem: order.menuItem,
    orderedById: order.orderedById,
    orderedByName: order.orderedByName,
    orderedByCustomer: order.orderedByCustomer,
    orderSequenceNumber: ignoreOrderSequenceNumber ? 1 : order.orderSequenceNumber,
    quantity: order.quantity,
    quantityUnit: order.quantityUnit,
    customPrice: order.customPrice,
    discountedPrice: 0,
    quantityType: order.quantityType,
    quantityType2: getMenuItem(order.menuItem.id).quantityType,
    childOrders: order.childOrders,
    takeaway: order.takeaway || tableOccupation.takeaway,
    pickup: isPickup,
    homedelivery: isHomeDelivery,
    forWhomId: order.forWhomId,
    forWhomName: String.format(I18n.t("local.unknown_person"), customer.seqNr) +
      (customer.tableMate ? ' (' + (customer.tableMate.name || customer.tableMate.email) + ')' : customer.name ? ' (' + customer.name + ')' : ''),
    orderIds: [],
    newOrder: order.id < 0,
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    stateMap: getStateMapArray(),
    unprocessed: false,
    orderCount: 0,
    unprinted: 0,
    partprinted: 0,
    printed: 0,
    beingprinted: 0,
    printFailed: 0,
    printStatus: order.printStatus,
    price: order.fullPrice,
    totalPrice: 0,
    discount: {},
    tableMateDiscount: 0,
    maxOrderId: order.id,
    paid: order.paid,
    labelCount: order.labelCount
  };
}

function createMenuItem2(order, tableMate, orderState, orderComparator) {
  return {
    customer: tableMate,
    state: orderState,
    comment: order.comment,
    discountComment: order.discountComment,
    restaurantComment: order.restaurantComment ?? "",
    timing: order.timing,
    defaultProductionLines: order.defaultProductionLines,
    menuItem: order.menuItem,
    quantity: order.quantity,
    quantityUnit: order.quantityUnit,
    customPrice: order.customPrice,
    discountedPrice: 0,
    quantityType: order.quantityType,
    quantityType2: getMenuItem(order.menuItem.id).quantityType,
    orderedById: order.orderedById,
    orderedByName: order.orderedByName,
    orderedByCustomer: order.orderedByCustomer,
    childOrders: order.childOrders,
    forWhomId: order.forWhomId,
    forWhomName: getTableMate(order.forWhomId),
    discount: order.discount,
    selected_order_discount: order.selected_order_discount,
    selected_order_discountComment: order.selected_order_discountComment,
    orderIds: [],
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    stateMap: getStateMapArray(),
    unprocessed: false,
    orderCount: 0,
    unprinted: 0,
    partprinted: 0,
    beingprinted: 0,
    printFailed: 0,
    printed: 0,
    price: order.fullPrice,
    count: 0,
    paid: order.paid,
    orders: new SortedSet({ comparator: orderComparator })
  };
}

function createOrderByCustomer(order, tableMate) {
  return {
    customer: tableMate,
    tableNumber: order.tableNumber,
    tableOccupation: order.tableOccupationId,
    tableOccupationSequenceNumber: order.tableOccupationSequenceNumber,
    price: 0,
    discountedPrice: 0,
    unprocessed: false,
    oldestOrder: order.modificationTimeStamp,
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    count: 0,
    stateMap: {
      wantsToPay: 0,
      paid: 0,
      unpaid: 0
    }
  };
}


function createOrderByCustomer2(order, tableMate, orderState) {
  return {
    timing: order.timing,
    state: orderState,
    customer: tableMate,
    tableNumber: order.tableNumber,
    tableOccupation: order.tableOccupationId,
    tableOccupationSequenceNumber: order.tableOccupationSequenceNumber,
    price: 0,
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    discountedPrice: 0,
    orderCount: 0,
    unprocessed: false,
    oldestOrder: order.modificationTimeStamp
  };
}

function createMenuItem3(order, tableMate, orderState, orderComparator) {
  return {
    customer: tableMate,
    state: orderState,
    comment: order.comment,
    discountComment: order.discountComment,
    restaurantComment: order.restaurantComment ?? "",
    timing: order.timing,
    defaultProductionLines: order.defaultProductionLines,
    menuItem: order.menuItem,
    quantity: order.quantity,
    quantityType: order.quantityType,
    quantityType2: getMenuItem(order.menuItem.id).quantityType,
    childOrders: order.childOrders,
    customPrice: order.customPrice,
    discountedPrice: 0,
    discount: order.discount,
    selected_order_discount: order.selected_order_discount,
    selected_order_discountComment: order.selected_order_discountComment,
    orderedById: order.orderedById,
    orderedByCustomer: order.orderedByCustomer,
    orderedByName: order.orderedByName,
    forWhomId: order.forWhomId,
    orderIds: [],
    inStateDurations: getStateMap(),
    inStateTimeStamp: getStateMap(),
    inStateTimeStampByProductionLine: getStateMap(),
    stateMap: getStateMapArray(),
    unprocessed: false,
    orderCount: 0,
    unprinted: 0,
    partprinted: 0,
    beingprinted: 0,
    printFailed: 0,
    printed: 0,
    price: 0,
    count: 0,
    paid: order.paid,
    orders: new SortedSet({ comparator: orderComparator })
  };
}

function createOrderByMenuItem(order) {
  return {
    comment: order.comment,
    tableNumber: order.tableNumber,
    restaurantComment: order.restaurantComment ?? "",
    tableOccupation: order.tableOccupationId,
    tableOccupationSequenceNumber: order.tableOccupationSequenceNumber,
    menuItem: order.menuItem,
    paid: order.paid,
    quantity: order.quantity,
    customPrice: order.customPrice,
    quantityType: order.quantityType,
    childOrders: order.childOrders,
    defaultProductionLines: order.defaultProductionLines,
    price: order.fullPrice - order.discount,
    unprocessed: false,
    oldestOrder: order.modificationTimeStamp
  };
}
