import React, { Component } from "react";
import EatWithMeText from "./EatWIthMeText";
import ChangeLanguage from "./icon-buttons/ChangeLanguage";
import UserImageButton from "./icon-buttons/UserImageButton.js";
import RestaurantSelector from "./RestaurantSelector.js";
import auth, { getMyImageUrl } from "../js/auth";
import LogoutButton from "./icon-buttons/LogoutButton";
import LoginButton from "./icon-buttons/LoginButton";
import DayNight from "./icon-buttons/DayNight";
import Softphone from "../main/tablesOverview/Softphone.js";

class TopBar extends Component {
  render() {
    return (
      <div class="topBar">
        <div style={{ display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "max(10px, min(10vw, 30px))" }}>
          <EatWithMeText resetMenu={this.props.resetMenu} />
          <RestaurantSelector {...this.props} />
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <DayNight />
            <ChangeLanguage />
            <UserImageButton src={getMyImageUrl()} />
            {auth.device.platform === "browser" ? <LoginButton /> : null}
            <LogoutButton />
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
