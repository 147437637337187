import $ from "jquery";
import local_en from "./en";
import local_hu from "./hu";
import local_it from "./it";
import local_cn from "./cn";

import admin_local_en from "./admin_en";
import admin_local_hu from "./admin_hu";
import admin_local_it from "./admin_it";
import admin_local_cn from "./admin_cn";

function changeLanguage() {
  console.log("changeLanguage");
  $("#changeLanguage.modal").modal("show");
}

export const locals = {
  hu: local_hu,
  en: local_en,
  it: local_it,
  cn: local_cn
};

export const admin_locals = {
  hu: admin_local_hu,
  en: admin_local_en,
  it: admin_local_it,
  cn: admin_local_cn
};

let local;
let admin_local;
let languages = ["en", "hu", "it", "cn"];
let languages_and_countries = {
  en: ["gb", "us"],
  hu: ["hu"],
  it: ["it"],
  cn: ["cn"]
};

export const initLanguage = () => {
  try {
    if (typeof localStorage.language == "undefined" || localStorage.language === "undefined") {
      if (typeof localStorage.language != "undefined" && localStorage.language !== "" && localStorage.language !== "undefined") {
        if (languages_and_countries[localStorage.language]) localStorage.country = languages_and_countries[localStorage.language][0];
      } else {
        var userLang = navigator.language || navigator.userLanguage;
        localStorage.language = userLang.split("-")[0];
        if (languages_and_countries[localStorage.language]) localStorage.country = languages_and_countries[localStorage.language][0];
      }
    }
    if (!languages_and_countries[localStorage.language]) {
      localStorage.language = "hu";
    }
    local = locals[localStorage.language];
    admin_local = admin_locals[localStorage.language];
    console.log("Language is", localStorage.language);
    if (typeof localStorage.country == "undefined" || localStorage.country !== "undefined") {
      if (languages_and_countries[localStorage.language]) {
        localStorage.country = languages_and_countries[localStorage.language][0];
      }
    }
    $("html").attr("lang", localStorage.language);
  } catch (ex) {
    console.log(ex.stack);
    if (typeof localStorage.language != "undefined") alert("Language '" + localStorage.language + "' not found! Switching to 'en'!");
    localStorage.language = "en";
    if (languages_and_countries[localStorage.language]) localStorage.country = languages_and_countries[localStorage.language][0];
    window.get("login/setLanguage/" + localStorage.language).done(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    });
  }
}

initLanguage();

export default { local, admin_local, changeLanguage, languages, languages_and_countries };
