import { combineReducers } from "redux";
import { Reducers as gridReducers } from "react-redux-grid";
import { i18nReducer } from "react-redux-i18n";
import { store } from "..";
import { Actions } from "react-redux-grid/dist/actions";
import paymentMethodReducer from "./paymentMethodReducer";
import roleReducer from "./roleReducer";
import { getRowIndexFromStore, getRowsFromStore } from "../components/utils";
import restaurantReducer from "./restaurantReducer";
import AI from "../js/ai/ai";
import auth, { courierBackgroundColors, courierColors, get } from "../js/auth";
import EatWithMeActions, { userAgent } from "../actions/EatWithMeActions";
import JsSIP from 'jssip/lib/JsSIP';
import { makeCall, onCallAnswered, onIncomingCall, setCallInProgress, setIncomingCallInProgress, setPhoneNumber, setSession, setStatus } from "../main/tablesOverview/Softphone";

export const paymentMethodsGridStateKey = "payment-methods-grid-stateKey";
export const rolesGridStateKey = "roles-grid-stateKey";
export const restaurantGridStateKey = "restaurant-grid-stateKey";

var socket = new JsSIP.WebSocketInterface('wss://eatwithmepbx.rebelltel.hu:8089/ws');



const initState = {
  myStatus: {},
  restaurants: undefined,
  tables: [],
  ai: new AI(),
  speechRecognitionAvailable: false,
  speechRecognitionOn: false,
  restaurantSelected: localStorage.restaurantSelected,
  activeRestaurant: 0,
  deliveryUsers: [],
  daynight: typeof localStorage.daynight !== "undefined" ? localStorage.daynight === "true" : false,
  ringing: typeof localStorage.ringing != "undefined" ? JSON.parse(localStorage.ringing) : [],
  voipphoneSelected: typeof localStorage.voipphoneSelected != "undefined" ? localStorage.voipphoneSelected : "",
  userAgent: "",
  ipphonecallinprogress: false
};

const rootReducer = (state = initState, action) => {
  //console.log(action);
  var newState = state;

  switch (action.stateKey) {
    case paymentMethodsGridStateKey:
      return paymentMethodReducer(state, action);
    case rolesGridStateKey:
      return roleReducer(state, action);
    case restaurantGridStateKey:
      newState = restaurantReducer(state, action);
      return newState;
    default:
  }

  switch (action.type) {
    case "speechRecognitionOn":
      newState = {
        ...state,
        speechRecognitionOn: true
      };
      break;
    case "speechRecognitionOff":
      newState = {
        ...state,
        speechRecognitionOn: false
      };
      break;
    case "daynight":
      newState = {
        ...state,
        daynight: action.daynight
      };
      localStorage.daynight = action.daynight;
      if (!action.daynight) {
        window.location.reload();
      }
      break;
    case "speechRecognitionAvailable":
      newState = {
        ...state,
        speechRecognitionAvailable: true
      };
      break;
    case "setSpeechCommand":
      newState = {
        ...state,
        speechCommand: action.command
      };
      break;
    case "resetMyStatus":
      newState = {
        ...state,
        myStatus: { ...action.myStatus },
        tablesByRooms: getTables(action.myStatus),
        rooms: getRooms(action.myStatus),
        businessHours: getBusinessHours(action.myStatus),
        tables: action.myStatus && action.myStatus.restaurant_tables ? action.myStatus.restaurant_tables : []
      };
      break;
    case "resetRestaurants":
      newState = {
        ...state,
        restaurants: action.restaurants,
      };
      break;
    case "resetActiveRestaurant":
      newState = {
        ...state,
        activeRestaurant: Number(action.restaurant),
      };
      break;
    case "changeLanguage":
      newState = {
        ...state,
        language: action.language,
        country: action.country,
        local: action.local,
        admin_local: action.admin_local
      };
      break;
    case "restaurantSelected":
      console.log("restaurantSelected")
      newState = {
        ...state,
        restaurant: action.restaurant ? { ...action.restaurant, timestamp: new Date().getTime() } : undefined
      };
      break;
    case "updateRestaurants":
      newState = {
        ...state,
        restaurants: [...action.restaurants]
      };
      break;
    case "userAgent": {
      newState = {
        ...state,
        userAgent: action.userAgent
      };
      break;
    }
    case "voipphone": {
      newState = {
        ...state,
        voipphoneSelected: action.name
      };
      localStorage.voipphoneSelected = action.name;
      break;
    }
    case "ipphonesession": {
      newState = {
        ...state,
        ipphonesession: action.session
      };
      break;
    }
    case "ipphonestatus": {
      newState = {
        ...state,
        ipphonestatus: action.status
      };
      break;
    }
    case "ipphonephonenumber": {
      newState = {
        ...state,
        ipphonephonenumber: action.number
      };
      break;
    }
    case "ipphoneincomingcallinprogress": {
      newState = {
        ...state,
        ipphoneincomingcallinprogress: action.status
      };
      break;
    }
    case "ipphonecallinprogress": {
      newState = {
        ...state,
        ipphonecallinprogress: action.status
      };
      break;
    }
    case "ipphonemakeacall": {
      newState = {
        ...state,
        ipphonemakeacall: action.status
      };
      break;
    }
    case "ipphonenumbertocall": {
      newState = {
        ...state,
        ipphonenumbertocall: action.number
      };
      break;
    }
    case "makeACall": {
      newState = {
        ...state,
        ipphonenumbertocall: action.number,
        ipphonecallinprogress: "outgoing",
        ipphonemakeacall: true
      };
      setTimeout(() => makeCall(state.userAgent, action.number), 10);
      break;
    }
    case "phoneringing": {
      const phone = state.ringing.find(r => r.number === action.number);
      if (!phone) {
        newState = {
          ...state,
          ringing: [...state.ringing, { state: "ringingphone", timestamp: new Date().getTime(), number: action.number, details: { phone: action.number } }]
        };
        get("adminService/getUsersContaining?email=&name=&surname=&phone=" + action.number, undefined, undefined, undefined, false).done(data => {
          if (data.length > 0)
            store.dispatch(EatWithMeActions.phonedetails(action.number, data[0]))
        }).fail(() => auth.ajaxError = false)
      } else {
        phone.selected = false;
        phone.state = "ringingphone";
        phone.timestamp = new Date().getTime();
        newState = {
          ...state,
          ringing: [...state.ringing]
        };
      }
      localStorage.ringing = JSON.stringify(newState.ringing)
      break;
    }
    case "phoneend": {
      const phone = state.ringing.find(r => r.number === action.number);
      if (phone) {
        phone.state = "missedphone";
      }
      newState = {
        ...state,
        ringing: [...state.ringing]
      };
      localStorage.ringing = JSON.stringify(newState.ringing)
      break;
    }
    case "phoneremove": {
      newState = {
        ...state,
        ringing: [...state.ringing.filter(n => n.number != action.number)]
      };
      localStorage.ringing = JSON.stringify(newState.ringing)
      break;
    }
    case "phonedetails":
      const n = state.ringing.find(r => r.number === action.number)
      if (n) {
        n.details = action.details;
        newState = {
          ...state,
          ringing: [...state.ringing]
        };
      }
      localStorage.ringing = JSON.stringify(newState.ringing)
      break;
    case "phoneselected":
      const r = state.ringing.map(r => { r.selected = false; return r; })
      const n1 = r.find(r => r.number === action.number)
      if (n1) {
        n1.selected = true;
        newState = {
          ...state,
          ringing: r
        };
      }
      break;
    case "updateDeliveryUsers":
      newState = {
        ...state,
        deliveryUsers: action.deliveryUsers.map((u, ind) => {
          return { ...u, color: courierColors[ind % courierColors.length], backgroundColor: courierBackgroundColors[ind % courierColors.length] }
        })
      };
      break;
    case "updateRestaurantTables":
      newState = {
        ...state,
        tables: [...action.tables],
        pointOfSales: [...action.pointOfSales]
      };
      break;
    case "logout": {
      newState = {
        ...state,
        tables: [],
        pointOfSales: [],
        restaurants: [],
        myStatus: {}
      };
      break;
    }
    case "orders": {
      newState = {
        ...state,
        orders: action.orders
      }
      break;
    }
    case "tableOccupations": {
      newState = {
        ...state,
        tableOccupations: action.tableOccupations
      }
      break;
    }
    default:
      newState = state;
  }

  if (state?.myStatus?.restaurant_settings && state.myStatus.restaurant_settings["enabled-features"].voipphone && state.myStatus.restaurant_settings["enabled-features"].voipphone.enabled) {
    if (newState.voipphoneSelected) {
      if (state.voipphoneSelected !== newState.voipphoneSelected) {
        if (newState.userAgent) {
          newState.userAgent.stop();
          setTimeout(() => iphoneconnect(newState.voipphoneSelected), 5000);
        }
      } else if (!newState.userAgent) {
        setTimeout(() => iphoneconnect(newState.voipphoneSelected), 100);
      }
    } else {
      if (newState.userAgent) {
        setTimeout(() => {
          newState.userAgent.stop();
        }, 100);
      }
    }
  }

  return newState;
};

const iphoneconnect = (voipphoneSelected) => {
  const phoneSelected = auth.myStatus.restaurant_settings["enabled-features"].voipphone.numbers.find(n => n.name === voipphoneSelected);
  if (phoneSelected) {
    const config = {
      sockets: [socket],
      "uri": "sip:" + phoneSelected.username + "@torchiopbx.rebelltel.hu",
      authorization_user: phoneSelected.username,
      "password": phoneSelected.password,
      "register": true,
    }
    const ua = new JsSIP.UA(config);
    store.dispatch(EatWithMeActions.userAgent(ua));
    // Attach error handling
    ua.on('connected', () => {
      setStatus("Connected")
    });

    ua.on('disconnected', () => {
      setTimeout(() => {
        setStatus('SIP user agent disconnected');
        store.dispatch(EatWithMeActions.userAgent(undefined));
      }, 100);
    });

    ua.on('registrationFailed', (data) => {
      setTimeout(() => {
        setStatus('Registration failed:' + data.cause);
        store.dispatch(EatWithMeActions.userAgent(undefined));
      }, 100);
    });
    // Event: WebSocket error
    ua.on('socketError', (error) => {
      setStatus('WebSocket connection error occurred:' + error);
    });

    // Handling incoming calls
    ua.on('newRTCSession', (data) => {
      const session = data.session;
      setSession(session);
      const callerNumber = data.request.from.display_name;
      setPhoneNumber(callerNumber);
      // Check if it's an incoming call
      if (session.direction === 'incoming') {
        store.dispatch(EatWithMeActions.phoneringing(callerNumber));
        setCallInProgress("incoming");
        setIncomingCallInProgress(true);
        console.log('Incoming call from:', session.remote_identity.uri.toString());
        onIncomingCall();
        // Attach event handlers
        session.on('confirmed', () => {
          setCallInProgress("incoming-inprogress");
          console.log('Call confirmed');
        });

        session.on('ended', () => {
          console.log('Call ended');
          setCallInProgress(false);
          setIncomingCallInProgress(false);
          setPhoneNumber("");
          onCallAnswered();
        });

        session.on('failed', (e) => {
          console.error('Call failed:', e.cause);
          setCallInProgress(false);
          setIncomingCallInProgress(false);
          setPhoneNumber("");
          onCallAnswered();
        });
        /*
        // Answer the call
        session.answer({
            mediaConstraints: { audio: true, video: false },
        });*/

      }
    });
    ua.start();
  }
}

const combinedReducer = combineReducers({
  rootReducer,
  nested: combineReducers({ ...gridReducers }),
  i18n: i18nReducer
});

export const updateRow = action => {
  //this.setState({ value: { id: isNaN(target.id) ? target.id : Number(target.id) } });
  const index = getRowIndexFromStore(action.stateKey, action.row._key);
  store.dispatch(
    Actions.EditorActions.updateRow({
      stateKey: action.stateKey,
      rowIndex: index,
      values: action.row
    })
  );
};

export const deleteRow = action => {
  const rowIndex = getRowIndexFromStore(action.stateKey, action.row._key);
  store.dispatch(
    Actions.EditorActions.removeRow({
      stateKey: action.stateKey,
      rowIndex: rowIndex
    })
  );
};

export const addNewRow = ({ stateKey, row }) => {
  store.dispatch(
    Actions.GridActions.setData({
      stateKey: stateKey,
      data: [...getRowsFromStore(stateKey), row],
      editMode: "inline"
    })
  );
};

function getRoom(myStatus, roomId) {
  return myStatus && myStatus.restaurant_rooms.find(room => room.id === roomId);
}

function getTableCountFor(myStatus, roomId, seats) {
  var count = 0;
  getRoom(myStatus, roomId).tables.forEach(table => {
    if (table.seats === seats) count++;
  });
  return count;
}

function getTables(myStatus) {
  if (!myStatus || myStatus.restaurant_rooms === undefined) return;

  var tableResources = [];

  myStatus.restaurant_rooms.forEach(r => {
    if (r.tableCount) {
    } else {
      r.tableCount = {};
    }

    if (r.isActive === false) return;

    r.tables.forEach(table => {
      if (table.isActive === true) {
        r.tableCount[table.seats] = getTableCountFor(myStatus, r.id, table.seats);
      }
    });

    Object.keys(r.tableCount).forEach(seats => {
      r.tables.forEach(table => {
        if (table.isActive === true && Number(table.seats) === Number(seats))
          tableResources.push({
            id: "" + table.number,
            t: "table",
            room: r.name,
            roomId: r.id,
            order: r.order,
            seats: Number(seats),
            title: table.name !== null && typeof table.name !== "undefined" && table.name !== "" ? table.name : "" + table.number,
            tableNumber: Number(table.number),
            x: table.x,
            y: table.y
          });
      });
    });
  });

  tableResources.sort((a, b) => {
    if (a.order === b.order) {
      if (a.seats === b.seats) return a.tableNumber - b.tableNumber;
      return a.seats - b.seats;
    }
    return a.order - b.order;
  });

  myStatus && myStatus.restaurant_tables.filter(t => t.recordState != "DELETED").forEach(table => {
    if (table.roomId === null && table.isActive === true)
      tableResources.push({
        id: "" + table.number,
        room: "-",
        order: -1,
        title: table.name !== null && typeof table.name !== "undefined" && table.name !== "" ? table.name : "" + table.number,
        seats: table.seats,
        tableNumber: Number(table.number),
        x: table.x,
        y: table.y
      });
  });
  return tableResources.sort((a, b) => a.order - b.order);
}

function getRooms(myStatus) {
  if (!myStatus || myStatus.restaurant_rooms === undefined) return;
  var roomResources = [];
  var order = 0;
  [...myStatus.restaurant_rooms].sort((a, b) => a.order - b.order).forEach(r => {
    if (r.order <= order) {
      r.order = order;
    }
    order = r.order;
    order++;
    if (r.tableCount) {
    } else {
      r.tableCount = {};
    }

    if (r.isActive === false) return;

    var toproom = {
      id: "room-" + r.id,
      room: r.name,
      roomId: r.id,
      title: 0,
      order: r.order,
      children: [],
      tables: r.tables.length
    };

    r.tables.filter(t => t.recordState !== "DELETED").forEach(table => {
      if (table.isActive === true) {
        r.tableCount[table.seats] = getTableCountFor(myStatus, r.id, table.seats);
        toproom.title++;
      }
    });


    Object.keys(r.tableCount).forEach((seats, ind) => {
      var room = {
        id: "room-" + r.id + "-" + seats,
        t: "room",
        room: r.name,
        roomId: r.id,
        title: r.tableCount[seats],
        children: [],
        order: r.order,
        seats: seats,
        tables: r.tableCount[seats]
      };
      roomResources.push(room);
    });
  });
  roomResources.sort((a, b) => a.order - b.order);
  return roomResources;
}

const getBusinessHours = myStatus => {
  var businessHours = [];

  if (!myStatus) {
    return businessHours;
  }

  if (!myStatus.restaurant_settings)
    return businessHours;

  if (!myStatus.restaurant_id)
    return businessHours;

  var startTime = "0" + myStatus.restaurant_settings["business-day-starts-at"];
  if (startTime.length === 3) startTime = startTime.substring(1, 3);
  var endTime = "0" + myStatus.restaurant_settings["business-day-ends-at"];
  if (endTime.length === 3) endTime = endTime.substring(1, 3);

  if (Number(myStatus.restaurant_settings["business-day-starts-at"]) < Number(myStatus.restaurant_settings["business-day-ends-at"])) {
    businessHours.push({
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
      startTime: startTime + ":00",
      endTime: endTime + ":01",
      showStart: true,
      showEnd: true
    });
  } else {
    if (Number(endTime) !== 0) {
      businessHours.push({
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
        startTime: "00:00",
        endTime: endTime + ":00",
        showStart: false,
        showEnd: true
      });
      businessHours.push({
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
        startTime: startTime + ":00",
        endTime: "23:59",
        showEnd: false,
        showStart: true
      });
    } else {
      businessHours.push({
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
        startTime: startTime + ":00",
        endTime: "23:59",
        showEnd: true,
        showStart: true
      });
    }
  }
  return businessHours;
};

export const getTable = number => {
  return store.getState().rootReducer.tables.find(t => Number(t.number) === Number(number));
};

export default combinedReducer;
