import React, { Component } from "react";
import BackButton from "./BackButton";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import EatWithMeText from "../EatWIthMeText";
import RestaurantSelector from "../RestaurantSelector";

class SubmenuHeader extends Component {
  state = {};
  render() {
    return (
      <div className="menuItemHeader" >
        <EatWithMeText />
        <BackButton {...this.props} />
        <FlexGrowSpace />
        <RestaurantSelector myStatus={this.props.myStatus} onReload={this.props.onReload} />
        <FlexGrowSpace />
        <h3 className="myTitle">{this.props.header}</h3>
      </div>
    );
  }
}

export default SubmenuHeader;
