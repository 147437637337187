/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import $ from "jquery";
import auth, { setDropdownButton, put, getLocale, post, messageDialog, _logError, confirmDialog, getInputDialog, choiceDialog2, getSetting, _log } from "./auth";
import { getOrderById, getOrdersPaidBy, getQuantity, getTableOccupation, tmplparams, update } from "./order-list-util";
import ReactQRCode from "./react-qrcode";
import "../css/tableMatePaid.css";
import { I18n } from "react-redux-i18n";
import { getMenuItem, resultHandler } from "./admin";
import { dayClose_fiscat, dayOpen_fiscat, generate_fiscat, storno_fiscat } from "./cashiers/fiscat";
import { generate_sam4s } from "./cashiers/sam4s";
import { generate_cashcube } from "./cashiers/cashcube";
import { generate_jotam } from "./cashiers/jotam";
import { dayClose_a234, dayOpen_a234, generate_a234, storno_a234 } from "./cashiers/a234";
import { generate_sento } from "./cashiers/sento";
import StornoDialog from "../components/StornoDialog";
import { generate_flexy } from "./cashiers/flexy";
import { generate_datecs } from "./cashiers/datecs";
import { RemoveCircleOutlineRounded, RestaurantRounded } from "@material-ui/icons";
import { getAmountToPay, getOrdersToPay, tableMatePaid } from "./tables-overview";
import { redoTableMatePaid } from "./admin/tableOccupations";
import { locals } from "../langs/languages";
export const selectCurrency = (t, event) => {
  setDropdownButton(t);

  var i = $(
    $(t)
      .parent()
      .parent()
      .prevAll("input")
  );
  var amountToPay = Number(i.attr("amountToPay"));

  i.attr("placeholder", Math.ceil((100 * Number(amountToPay)) / Number($(t).attr("exchangeRate")), 2) / 100);
  i.attr("exchangeRate", $(t).attr("exchangeRate"));
  i.attr("currency", $(t).html().trim());
  i.focus();
  event.stopPropagation();
}

export const amountChanged = (t) => {
  if (t && t.value)
    t.value = t.value.replace(/\D/g, "");

  var nextInput = $(
    $(t)
      .parent()
      .nextAll("div.amountPaid")
  ).find("input");
  var amountToPay = $(t).attr("amountToPay");
  var amountPaid = $(t).val();
  var amountPaidExchangeRate = $(t).attr("exchangeRate");
  var amountRemaingExchangeRate = nextInput.attr("exchangeRate");
  nextInput.attr("placeholder", Math.max(0, Math.round((100 * (amountToPay - amountPaid * amountPaidExchangeRate)) / amountRemaingExchangeRate) / 100));
  nextInput.attr("amountToPay", Math.round(100 * Math.max(0, amountToPay - amountPaid * amountPaidExchangeRate)) / 100);
  checkPayments();
}

export const setAmount = t => {
  var amountToPay = $($(t).parents("li")[0]).attr("amountToPay");
  var paymentMethod = getPaymentMethod($(t).parent().parent().attr("paymentmethod"));
  if (paymentMethod.method.type === "teya" && typeof localStorage.teya_terminal === "undefined") {
    selectTerminal("teya");
    return;
  }
  var currencyid = $(t).parents('li').find(".paymentCurrency").val();
  var currency = auth.myStatus.currencies.find(c => c.id == currencyid);
  if (currency.javaScriptRoundFunction && paymentMethod.method.type === "cash") {
    amountToPay = eval(currency.javaScriptRoundFunction.replace("amount", amountToPay));
  }
  //console.log(currency, paymentMethod);
  $(t).parent().parent().parent().find("> *:not(.vat-details-block) input").each((ind, t) => {
    //if (t.value === amountToPay)
    $(t).val("");
  })
  $(t).parent().parent().find("input").val(
    Math.ceil(100 * amountToPay / $(t).parent().parent().find("input").attr("exchangerate")) / 100
  );
  $(t).parent().parent().find("input").focus();
  checkPayments();
};

export const createNewPartner = (event) => {
  const p = $(event.currentTarget).parents('.vat-details-block').find('.vat-details');
  var partner = p.find('input#partner_id').val();
  if (partner)
    return;
  var tax_number = p.find('input#tax_number').val();
  var name = p.find('input#name').val();
  var addresss = p.find('input#address').val();
  var postcode = p.find('input#postcode').val();
  var email = p.find('input#email').val();
  var city = p.find('input#city').val();

  if (!tax_number || !name || !addresss || !postcode || !city)
    return;

  const partnerJSON = {
    name: name,
    address: {
      address: addresss,
      city: city,
      postCode: postcode,
      countryCode: "HU"
    },
    taxcode: tax_number,
    textType: "EMPTY",
    emails: [email]
  }
  put("billingo/" + localStorage.restaurantSelected + "/partners", partnerJSON).done(partner => {
    p.find('#partner_id').val(partner.id);
    p.parent().find("#create-vat-partner").addClass("hidden");
    checkPayments();
  });
}

export const checkPayments = (orderss, tableMatePayment) => {

  var toohigh = false;
  let count = 0;
  let tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
  let tableMates = tableOccupation?.tableMates ? tableOccupation.tableMates.filter(t => t.active).filter(t => getOrdersToPay(t.id).length) : [];
  let pcount = tableMatePayment ? tableMatePayment.payments.length : tableMates.length;
  var ok = !!$("#tableMatePaidDialog li.mate.active").length && pcount > 0;
  var allDelivered = true;
  $("#tableMatePaidDialog li.mate.active").each((ind, m) => {
    var okk = true;
    count++;
    var currentAmount = 0;
    var mateId = $(m).attr("id");
    if ($("div#pos-qrcode" + mateId + " canvas").length > 0)
      ReactDOM.unmountComponentAtNode($("div#pos-qrcode" + mateId).get(0));

    if (!$(m).hasClass("active")) return;
    var type;
    var types = [];

    var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
    var amount = Number($(m).attr("amountToPay"));
    //// eslint-disable-next-line no-eval
    var amountToPay = amount; //eval(roundFunction);

    var currency;
    var originalAmountInCurrency = 0;
    var amountPaidExchangeRate = 1;
    var paymentTypes = 0;

    var cashAmount = 0;

    $(m)
      .find(".main-block > div > :not(.vat-details-block) input")
      .each(function (ind, payment) {
        if (Number($(payment).val()) > 0) {
          paymentTypes++;
          amountPaidExchangeRate = $(payment).attr("exchangeRate");
          originalAmountInCurrency = Number($(payment).val());
          currency = $(payment).attr("currency") ? $(payment).attr("currency") : auth.myStatus.restaurant_base_currency.name;
          currentAmount += Number($(payment).val() * amountPaidExchangeRate);
          types.push({
            type: $(payment).parent().attr("paymentMethod"),
            amount: Number($(payment).val()),
            currency: currency,
            exchangeRate: amountPaidExchangeRate,
            terminal: $(payment).parent().attr("paymentMethodType") === "teya" ? localStorage.teya_terminal : null
          });
          const t = $(payment).parent().find("button").attr("value");
          if (t) {
            if (getPaymentMethod(t).method.type === "cash") {
              cashAmount += Number($(payment).val() * amountPaidExchangeRate);
            }
            if (Number($(payment).val() * amountPaidExchangeRate) >= amountToPay || getPaymentMethod(t).method.type === "cash")
              type = t;
          }
        }
      });

    if (type && types.length === 1 && getPaymentMethod(type).method.type === "cash") {
      amount = amountToPay;
      amountToPay = eval(roundFunction);
    }

    var tip = 0;
    //var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
    var amount = currentAmount - amountToPay;
    //// eslint-disable-next-line no-eval
    tip = amount; //eval(roundFunction);
    const originalTip = tip;

    if (tip > 0) {
      var tip2 = tip;
      types.sort((a, b) => {
        const pma = getPaymentMethod(a.type);
        const pmb = getPaymentMethod(b.type);
        if (pma.method.type === "cash" && pmb.method.type !== "cash")
          return -1;
        if (pma.method.type !== "cash" && pmb.method.type === "cash")
          return 1;
        return 0;
      });
      types.forEach((t, ind) => {
        if (t.amount > 0) {
          const a = Math.min(t.amount, tip2);
          t.amount -= a;
          tip2 -= a;
        }
      });
    }

    $(m)
      .find("text#tip")
      .text(Number(Math.max(0, tip)));
    ok = ok && Math.round(currentAmount * 100) >= Math.round(amountToPay * 100);
    okk = okk && Math.round(currentAmount * 100) >= Math.round(amountToPay * 100);
    toohigh = toohigh || currentAmount > amountToPay * 3;


    $("#cashregisters label.active > input").prop("checked", true);

    var cashregister = $("#cashregisters input:checked").prop("id");

    const serviceVat = auth.myStatus.restaurant_settings.serviceVat;

    const containerChargeItem = getSetting("takeaway.containerChargeItem");

    const registerTip = auth.myStatus.restaurant_settings["register-tip"];

    if (auth.myStatus.restaurant_cash_registers.filter(c => c.isActive && (((auth.device.platform === "browser" || auth.device.platform === "iOS") && (c.format === "rs232" || c.format === "qrcode")) || auth.device.platform === "Android")).length && !cashregister) {
      const c = $("#cashregisters label");
      if (c.length === 1) {
        c.find("input").prop("checked", true);
        c.find("input").parent().addClass("active");
        c.trigger("click");
      } else {
        auth.vibrateOk(2000);
        $("#cashregisters").removeClass("highlight-3");
        setTimeout(() => $("#cashregisters").addClass("highlight-3"), 100);
        ok = false;
        okk = false;
      }
    }

    cashregister = cashregister ? auth.myStatus.restaurant_cash_registers.find(c => c.id == cashregister) : undefined;

    var entryOrder = 0;

    if (okk && type && cashregister /*&& paymentTypes === 1*/) {
      var paymentMethod = getPaymentMethod(type);
      var orders = orderss ? orderss : getOrdersPaidBy(mateId);
      var amounts = {};
      var payable = 0;
      var containerChargeFee = 0;

      if (!orders.length)
        return;

      if (cashAmount >= 0) {
        tip = Math.max(0, tip - cashAmount);
        const t = types.find(t => getPaymentMethod(t.type).method.type === "cash");
        if (t && t.amount)
          t.amount -= (originalTip - tip);
      }

      const detailed = cashregister.detailed && orders.filter(o => o.price < 0).length === 0;



      if (detailed) {

        orders.forEach((order, ind) => {
          if (order.recordState === "DELETED" || order.state === "cancelled" || (!orderss && order.paid))
            return;
          var key = order.menuItem.id + "-" + (order.fullPrice - Number(order.discount) - Number(order.tableMateDiscount));
          order.childOrders.filter(corder => corder.recordState !== "DELETED" && corder.state !== "cancelled").forEach(o => key += "-" + o.menuItem.id);

          if (amounts[key] == undefined) {
            var label = getQuantity(order).replace(/&nbsp;/g, " ");
            if (label.length > 7) {
              const mat = label.match(/.*\((.*)\).*/);
              if (mat)
                label = mat[1];
            }
            const name = getLocale((getMenuItem(order.menuItem.id).name)).trim();
            amounts[key] = {
              menuItem: order.menuItem.id,
              service_amount: 0,
              amount: Math.round(order.fullPrice - (order.discount + order.tableMateDiscount)),
              vat: order.vat,
              name: name.substring(0, Math.max(0, 18 - label.length - 1)) + " " + label,
              count: 1,
              entryOrder: entryOrder++
            };
            /*
            if (order.discount + order.tableMateDiscount !== 0) {
              amounts[key + "-" + 0] = {
                amount: -(order.discount + order.tableMateDiscount),
                vat: order.vat,
                name: order.discountComment,
                count: 1,
                entryOrder: entryOrder++
              };
            }*/
            order.childOrders.forEach((corder, ind) => {
              if (corder.recordState === "DELETED" || corder.state === "cancelled" || corder.paid)
                return;
              var clabel = getQuantity(corder).replace(/&nbsp;/g, " ");
              if (clabel.length > 7) {
                clabel = clabel.split(" ")[0];
              }
              const cname = getLocale(getMenuItem(corder.menuItem.id).name).trim();

              if (!corder.addition) {
                //amounts[key].amount += Math.round(corder.price);
              } else {
                amounts[key + "-" + (ind + 1)] = {
                  amount: (corder.addition ? 1 : -1) * Math.round(corder.price),
                  service_amount: 0,
                  vat: order.vat,
                  name: (corder.addition ? "+" : "-") + cname.substring(0, Math.max(0, 18 - clabel.length - 2)) + " " + clabel,
                  count: 1,
                  entryOrder: entryOrder++
                };
                amounts[key].amount -= amounts[key + "-" + (ind + 1)].amount;
                if (amounts[key].amount < 0) {
                  /*
                  const m = Math.min(amounts[key + "-" + (ind + 1)].amount, -amounts[key].amount);
                  amounts[key + "-" + (ind + 1)].amount -= m;
                  amounts[key].amount += m;
                  */
                  amounts[key].amount += amounts[key + "-" + (ind + 1)].amount;
                  amounts[key + "-" + (ind + 1)].amount = 0;
                }
              }
            });
          } else {
            amounts[key].count++;
            order.childOrders.filter(corder => corder.recordState !== "DELETED" && corder.state !== "cancelled").forEach((corder, ind) => {
              if (corder.recordState === "DELETED" || corder.state === "cancelled" || corder.paid)
                return;
              if (!corder.addition) {
                //amounts[key].amount -= Math.floor(corder.price);
              } else {
                amounts[key + "-" + (ind + 1)].count++;
              }
              //amounts[key + "-" + (ind + 1)].count++;
            });
          }
          if (order.menuItem.id != containerChargeItem)
            payable += order.fullPrice - order.discount - order.tableMateDiscount;
          else
            containerChargeFee += order.fullPrice - order.discount - order.tableMateDiscount;
          if (order.state != "delivered") allDelivered = false;
        });

        if (registerTip) {
          const serviceFee = Math.max(0, (Number(amountToPay) - Number(payable) - containerChargeFee));
          Object.values(amounts).forEach(amount => {
            if (amount.menuItem != containerChargeItem) {
              if (serviceVat)
                amount.service_amount += Math.max(Math.round(amount.amount / (payable) * serviceFee));
              else
                amount.amount += Math.floor(amount.amount / (payable) * serviceFee);
            }
          })
        }

      } else {
        var containerCharge = 0;
        orders.forEach(order => {
          if (order.recordState === "DELETED" || order.state === "cancelled" || (!orderss && order.paid))
            return;
          if (amounts[order.vat] == undefined) {
            amounts[order.vat] = {
              menuItem: order.menuItem.id,
              name: I18n.t("admin_local.sale") + " " + (order.vat === null ? "TAM" : order.vat + "%"),
              amount: Math.round(order.fullPrice - order.discount - order.tableMateDiscount),
              vat: order.vat,
              entryOrder: entryOrder++,
              count: 1,
              service_amount: 0
            };
          } else amounts[order.vat].amount += Math.round(order.fullPrice - order.discount - order.tableMateDiscount);
          if (order.menuItem.id != containerChargeItem)
            payable += order.fullPrice - order.discount - order.tableMateDiscount;
          else
            containerCharge += order.fullPrice - order.discount - order.tableMateDiscount;
          if (order.state != "delivered") allDelivered = false;
        });

        if (registerTip) {
          const serviceFee = (amountToPay - payable - containerCharge);
          Object.keys(amounts).forEach(vat => {
            if (amounts[vat].menuItem != containerChargeItem) {
              if (serviceVat)
                amounts[vat].service_amount += Math.max(0, Math.round(amounts[vat].amount / payable * serviceFee));
              else
                amounts[vat].amount += Math.round(amounts[vat].amount / payable * serviceFee);
            }
          })
        }

      }

      payable = Math.round(payable);

      const service_fee = Object.values(amounts).reduce((a, b) => a + b.service_amount, 0);

      if (orderss) {
        if (tip > 0)
          tip -= tableMatePayment.totalAmountPaid - tableMatePayment.totalAmountPayable;

        //This is just a payment to register the tip so we have to remove the already paid parts
        Object.values(amounts).forEach(a => {
          a.amount = 0;
          a.service_amount = Math.round(tip * (a.service_amount / service_fee));
        })
        tableMatePayment.payments.forEach(p => {
          const payment = types.find(t => Number(t.type) === p.paymentMethod.id);
          if (payment)
            payment.amount -= p.amount;
        })
        amountToPay = 0;
      }


      if (!Object.values(amounts).find(p => (p.amount + p.service_amount > 0)))
        return;

      const total = Object.values(amounts).map(a => (a.service_amount + a.amount) * a.count).reduce((a, b) => a + b, 0);
      if (total != amountToPay && Math.abs(total - amountToPay) > 0) {
        Object.values(amounts)[0].service_amount -= (total - amountToPay) / Object.values(amounts)[0].count;
      }

      const total2 = Object.values(amounts).map(a => (a.service_amount + a.amount) * a.count).reduce((a, b) => a + b, 0);
      if (total2 != amountToPay) {
        console.error("NOT GOOD", total2 + "!=" + (amountToPay + tip))
      }

      $(m).attr('qrcode', "");
      if (cashregister) {
        if (paymentMethod.method.type === "card" || paymentMethod.method.type === "online" || paymentMethod.method.type === "barion" || paymentMethod.method.type === "hellopay" || paymentMethod.method.type === "teya" || paymentMethod.method.type === "bizpay" || paymentMethod.method.type === "paynance") showposqrcode(m, mateId, amounts, cashregister.type, amountToPay, types, amountPaidExchangeRate, cashregister.serviceVat);
        else if (paymentMethod.method.type === "cash") {

          if (currency === "EUR") {
            //amounts[Object.keys(amounts)[0]].amount = Math.round((originalAmountInCurrency - tip / amountPaidExchangeRate) * 100) / 100;
            //console.log(amounts)
            showposqrcode(m, mateId, amounts, cashregister.type, payable, types, amountPaidExchangeRate, cashregister.serviceVat);
          } else {
            showposqrcode(m, mateId, amounts, cashregister.type, amountToPay, types, amountPaidExchangeRate, cashregister.serviceVat);
          }
        }
      }
    } else {
      if ($(m).attr('qrcode'))
        $(m).attr('qrcode', "");
    }
  });

  var allbuttons = $("#tableMatePaid button#save,#tableMatePaid button#save-and-unlock,#tableMatePaid button#print");
  var buttons = $("#tableMatePaid button#save,#tableMatePaid button#print");
  var buttons2 = $("#tableMatePaid button#save-and-unlock");

  if ($("#tableMatePaidDialog li.mate.active .vat-details-block.show").length && auth.myStatus.restaurant_settings["enabled-features"].invoice.platform === "billingo" && !$("#tableMatePaidDialog li.mate.active .vat-details-block.show #partner_id").val()) {
    ok = false;
  }

  const autoDeliverOnUnlock = auth.myStatus.restaurant_settings["tables-overview"]["autoDeliverOnUnlock"];
  allbuttons.prop("disabled", "true");
  if (ok) {
    if (toohigh) {
      buttons.removeAttr("disabled");
      buttons.removeClass("btn-negative");
      buttons.removeClass("btn-positive");
      buttons.addClass("btn-danger");
      if (count == pcount && (allDelivered || autoDeliverOnUnlock)) {
        buttons2.removeAttr("disabled");
        buttons2.removeClass("btn-positive");
        buttons2.removeClass("btn-negative");
        buttons2.addClass("btn-danger");
      }
    } else {
      buttons.removeAttr("disabled");
      buttons.removeClass("btn-negative");
      buttons.removeClass("btn-danger");
      buttons.addClass("btn-positive");
      if (count == pcount && (allDelivered || autoDeliverOnUnlock)) {
        buttons2.removeAttr("disabled");
        buttons2.removeClass("btn-negative");
        buttons2.removeClass("btn-danger");
        buttons2.addClass("btn-positive");
      }
    }
  } else {
    allbuttons.prop("disabled", "true");
    allbuttons.addClass("btn-negative");
    allbuttons.removeClass("btn-positive");
    allbuttons.removeClass("btn-danger");
  }
};

export const getcashregisterqrcode = (amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat) => {
  var url = "";
  var def = $.Deferred();
  //console.log(cashierType);
  switch (cashierType) {
    case "fiscat":
    case "fiscat2":
    case "fiscat3":
    case "fiscat4":
    case "fiscat5":
      generate_fiscat(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "sam4s":
      generate_sam4s(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "cashcube":
      generate_cashcube(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "jota_m":
      generate_jotam(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "sento":
      generate_sento(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "datecs":
      generate_datecs(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "flexy":
      generate_flexy(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    case "a234":
      generate_a234(def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
      break;
    default:
  }
  return def.promise();
}

function showposqrcode(m, mateId, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat) {
  var def = getcashregisterqrcode(amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat);
  def.promise().done(url => {
    $(m).attr('qrcode', url);
    if (auth.myStatus.restaurant_cash_registers.filter(c => c.name === cashierType && c.format === 'qrcode').length == 0)
      return;
    ReactDOM.render(
      <ReactQRCode
        url={url}
        size={
          $("div#pos-qrcode" + mateId)
            .get(0)
            .getBoundingClientRect().height
        }
      />,
      $("div#pos-qrcode" + mateId).get(0)
    );

  });
}

export const getPaymentMethod = (id) => {
  var paymentMethod = null;
  auth.myStatus.restaurant_payment_methods.forEach(pm => {
    if (pm.method.id == id) paymentMethod = pm;
  });
  return paymentMethod;
}

export const getCurrencyEchangeRate = (id) => {
  var currency = null;
  auth.myStatus.restaurant_currencies.forEach(c => {
    if (c.currency.id == id) currency = c;
  });
  if (currency == null)
    return 1;
  return currency.exchangeRate;
}

export const showPayments = () => {
  var modal = $("#tableMatePaidDialog");
  modal.empty();
  $("#tableMatePaymentsTemplate")
    .tmpl({
      tableOccupation: getTableOccupation(localStorage.tableOccupationSelected),
      ...tmplparams()
    })
    .appendTo(modal);
  var modal = $("#tableMatePayments.modal");
  modal.show();
}

export const isThereReceiptsToPrint = () => {
  try {
    return localStorage.tableOccupationSelected > 0 && getTableOccupation(localStorage.tableOccupationSelected).tableMatePayments.filter(tmp => tmp.orderCount > 0).length > 0;
  } catch (ex) { return false };
}

export const printReceipt = (tableMatePaymentId) => {
  const tableOccupation = getTableOccupation();
  const tableMatePayment = tableOccupation.tableMatePayments.find(tmp => tmp.id === tableMatePaymentId);
  function doit() {
    getQrcodeCode(tableMatePayment).done(url => {
      var cashregister = $("#cashregisters input:checked").prop("id");
      cashregister = auth.myStatus.restaurant_cash_registers.find(c => c.id == cashregister);
      const tableMatePayments = {
        id: tableMatePaymentId,
        tableOccupation: tableOccupation.id,
        qrcode: url
      }
      postPayment(cashregister, tableOccupation.id, [tableMatePayments], true, (data) => {
        update(data);
        showPayments();
      })
    })
  }
  if (tableMatePayment.printed) {
    confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.reprint_receipt")).done(doit);
  } else
    doit();

}

export const getQrcodeCode = (m) => {

  const def = new $.Deferred();

  const orderss = m.orders || m.orderIds.split(",").map(s => getOrderById(Number(s)));

  var ok = true;
  var toohigh = false;
  let count = 0;
  let pcount = 0;
  var allDelivered = true;

  var okk = true;
  count++;
  var currentAmount = 0;
  var mateId = m.tableMate.id;
  pcount++;
  var type;
  var types = [];

  var amountToPay = m.totalAmountPayable;

  const containerChargeItem = getSetting("takeaway.containerChargeItem");

  var currency;
  var originalAmountInCurrency = 0;
  var amountPaidExchangeRate = 1;
  var paymentTypes = 0;

  var cashAmount = 0;

  m.payments.forEach(payment => {
    if (payment.amount > 0) {
      paymentTypes++;
      amountPaidExchangeRate = payment.rate;
      originalAmountInCurrency = payment.amount;
      currency = payment.currency.name;
      currentAmount += Number(payment.amount * amountPaidExchangeRate);
      types.push({
        type: payment.paymentMethod.id,
        amount: payment.amount,
        currency: currency,
        exchangeRate: amountPaidExchangeRate,
        terminal: payment.paymentMethod.type === "teya" ? localStorage.teya_terminal : null
      });
      const t = payment.paymentMethod;
      if (t.type === "cash") {
        cashAmount += payment.amount * amountPaidExchangeRate;
      }
      if (payment.amount * amountPaidExchangeRate >= amountToPay || t.type === "cash")
        type = t;
    }
  });

  var tip = 0;
  var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];

  if (type && types.length === 1 && getPaymentMethod(types[0].type).method.type === "cash") {
    amount = amountToPay;
    amountToPay = eval(roundFunction);
  }

  var amount = currentAmount - amountToPay;
  // eslint-disable-next-line no-eval
  tip = amount; //eval(roundFunction);
  const originalTip = tip;

  if (tip > 0) {
    var tip2 = tip;
    types.sort((a, b) => {
      const pma = getPaymentMethod(a.type);
      const pmb = getPaymentMethod(b.type);
      if (pma.method.type === "cash" && pmb.method.type !== "cash")
        return -1;
      if (pma.method.type !== "cash" && pmb.method.type === "cash")
        return 1;
      return 0;
    });
    types.forEach((t, ind) => {
      if (t.amount > 0) {
        const a = Math.min(t.amount, tip2);
        t.amount -= a;
        tip2 -= a;
      }
    });
  }

  ok = ok && Math.round(currentAmount * 100) >= Math.round(amountToPay * 100);
  okk = okk && Math.round(currentAmount * 100) >= Math.round(amountToPay * 100);
  toohigh = toohigh || currentAmount > amountToPay * 3;

  var cashregister = $("#cashregisters input:checked").prop("id");

  const serviceVat = auth.myStatus.restaurant_settings.serviceVat;

  const registerTip = auth.myStatus.restaurant_settings["register-tip"];

  if (auth.myStatus.restaurant_cash_registers.filter(c => c.isActive && (((auth.device.platform === "browser" || auth.device.platform === "iOS") && (c.format === "rs232" || c.format === "qrcode")) || auth.device.platform === "Android")).length && !cashregister) {
    const c = $("#cashregisters label");
    if (c.length === 1) {
      c.find("input").prop("checked", true);
      c.trigger("click");
    } else {
      auth.vibrateOk(2000);
      $("#cashregisters").removeClass("highlight-3");
      setTimeout(() => $("#cashregisters").addClass("highlight-3"), 100);
      ok = false;
      okk = false;
    }
  }

  cashregister = cashregister ? auth.myStatus.restaurant_cash_registers.find(c => c.id == cashregister) : undefined;

  var entryOrder = 0;

  if (okk && type && cashregister /*&& paymentTypes === 1*/) {
    var paymentMethod = type;
    var orders = orderss ? orderss : getOrdersPaidBy(mateId);
    var amounts = {};
    var payable = 0;


    if (cashAmount >= 0) {
      tip = Math.max(0, tip - cashAmount);
      const t = types.find(t => getPaymentMethod(t.type).method.type === "cash");
      if (t && t.amount)
        t.amount -= (originalTip - tip);
    }
    const detailed = cashregister.detailed && orders.filter(o => o.price < 0).length === 0;

    if (detailed) {

      orders.forEach((order, ind) => {
        if (order.recordState === "DELETED" || order.state === "cancelled" || (!orderss && order.paid))
          return;
        var key = order.menuItem.id + "-" + (order.fullPrice - order.discount - order.tableMateDiscount);
        order.childOrders.filter(corder => corder.recordState !== "DELETED" && corder.state !== "cancelled").forEach(o => key += "-" + o.menuItem.id);

        if (amounts[key] == undefined) {
          var label = getQuantity(order).replace(/&nbsp;/g, " ");
          if (label.length > 7) {
            const mat = label.match(/.*\((.*)\).*/);
            if (mat)
              label = mat[1];
          }
          const name = getLocale((getMenuItem(order.menuItem.id).name)).trim();
          amounts[key] = {
            menuItem: order.menuItem.id,
            service_amount: 0,
            amount: Math.round(order.fullPrice - (order.discount + order.tableMateDiscount)),
            vat: order.vat,
            name: name.substring(0, Math.max(0, 18 - label.length - 1)) + " " + label,
            count: 1,
            entryOrder: entryOrder++
          };
          order.childOrders.forEach((corder, ind) => {
            if (corder.recordState === "DELETED" || corder.state === "cancelled" || corder.paid)
              return;
            var clabel = getQuantity(corder).replace(/&nbsp;/g, " ");
            if (clabel.length > 7) {
              clabel = clabel.split(" ")[0];
            }
            const cname = getLocale(getMenuItem(corder.menuItem.id).name).trim();
            if (!corder.addition) {
              //amounts[key].amount -= corder.price;
            } else {
              if (!corder.addition) {
                //amounts[key].amount += Math.round(corder.price);
              } else {
                amounts[key + "-" + (ind + 1)] = {
                  amount: (corder.addition ? 1 : -1) * Math.round(corder.price),
                  service_amount: 0,
                  vat: order.vat,
                  name: (corder.addition ? "+" : "-") + cname.substring(0, Math.max(0, 18 - clabel.length - 2)) + " " + clabel,
                  count: 1,
                  entryOrder: entryOrder++
                };
                amounts[key].amount -= amounts[key + "-" + (ind + 1)].amount;
                if (amounts[key].amount < 0) {
                  /*
                  const m = Math.min(amounts[key + "-" + (ind + 1)].amount, -amounts[key].amount);
                  amounts[key + "-" + (ind + 1)].amount -= m;
                  amounts[key].amount += m;
                  */
                  amounts[key].amount += amounts[key + "-" + (ind + 1)].amount;
                  amounts[key + "-" + (ind + 1)].amount = 0;
                }
              }
            }
          });
        } else {
          amounts[key].count++;
          order.childOrders.filter(corder => corder.recordState !== "DELETED" && corder.state !== "cancelled").forEach((corder, ind) => {
            if (corder.recordState === "DELETED" || corder.state === "cancelled" || corder.paid)
              return;
            if (!corder.addition) {
            } else {
              amounts[key + "-" + (ind + 1)].count++;
            }
          });
        }
        payable += order.fullPrice - order.discount - order.tableMateDiscount;
        if (order.state != "delivered") allDelivered = false;
      });

      // var vattip = 0;
      //console.log(amountToPay, payable);
      if (registerTip) {
        const serviceFee = (amountToPay - payable);
        Object.values(amounts).forEach(amount => {
          if (amount.menuItem != containerChargeItem) {
            if (serviceVat)
              amount.service_amount += Math.max(Math.round(amount.amount / payable * serviceFee));
            else
              amount.amount += Math.round(amount.amount / payable * serviceFee);
          }
        })
      }

    } else {
      orders.forEach(order => {
        if (order.recordState === "DELETED" || order.state === "cancelled" || (!orderss && order.paid))
          return;
        if (amounts[order.vat] == undefined) {
          amounts[order.vat] = {
            name: I18n.t("admin_local.sale") + " " + (order.vat === null ? "TAM" : order.vat + "%"),
            amount: Math.round(order.fullPrice - order.discount - order.tableMateDiscount),
            vat: order.vat,
            entryOrder: entryOrder++,
            count: 1,
            service_amount: 0
          };
        } else amounts[order.vat].amount += Math.round(order.fullPrice - order.discount - order.tableMateDiscount);
        payable += order.fullPrice - order.discount - order.tableMateDiscount;
        if (order.state != "delivered") allDelivered = false;
      });

      if (registerTip) {
        const serviceFee = (amountToPay - payable);
        Object.keys(amounts).forEach(vat => {
          if (serviceVat)
            amounts[vat].service_amount += Math.max(0, Math.round(amounts[vat].amount / payable * serviceFee));
          else
            amounts[vat].amount += Math.round(amounts[vat].amount / payable * serviceFee);
        })
      }

    }

    const total = Object.values(amounts).map(a => (a.service_amount + a.amount) * a.count).reduce((a, b) => a + b, 0);
    if (total != amountToPay && Math.abs(total - amountToPay) > 0) {
      Object.values(amounts)[0].service_amount -= (total - amountToPay) / Object.values(amounts)[0].count;
    }

    const total2 = Object.values(amounts).map(a => (a.service_amount + a.amount) * a.count).reduce((a, b) => a + b, 0);
    if (total2 != amountToPay) {
      console.error("NOT GOOD", total2, "!=", amountToPay + tip)
    }

    $(m).attr('qrcode', "");
    if (cashregister) {
      getcashregisterqrcode(amounts, cashregister.type, payable, types, amountPaidExchangeRate, serviceVat).done(def.resolve);
    }
  }
  return def.promise();
}

export const dayOpen = (cashRegister) => {

  var c;

  if (!cashRegister) {
    cashRegister = Number($("#cashregisters input:checked").prop("id"));
    c = auth.myStatus.restaurant_cash_registers.find(c => c.id === cashRegister);
  } else {
    c = auth.myStatus.restaurant_cash_registers.find(r => r.name === cashRegister);
  }

  getInputDialog(I18n.t("admin_local.cashier_dayopen"), I18n.t("admin_local.cashier_open_amount"), 0, "number").done((choice, amount) => {
    var text = "";
    switch (c.type) {
      case "fiscat":
      case "fiscat2":
      case "fiscat3":
      case "fiscat3a":
      case "fiscat4":
      case "fiscat5":

        text = dayOpen_fiscat(amount);
        break;
      case "sam4s":
        //text = dayOpen_sam4s();
        break;
      case "cashcube":
        //text = dayOpen_cashcube();
        break;
      case "jota_m":
        //text = dayOpen_jotam();
        break;
      case "sento":
        //text = dayOpen_sento();
        break;
      case "a234":
        text = dayOpen_a234(amount);
        break;
      default:
    }
    if (text) {
      sendToCashier(c, text);
    }
  })

}

export const dayClose = (cashRegister) => {

  var c;

  if (!cashRegister) {
    cashRegister = Number($("#cashregisters input:checked").prop("id"));
    c = auth.myStatus.restaurant_cash_registers.find(c => c.id === cashRegister);
  } else {
    c = auth.myStatus.restaurant_cash_registers.find(r => r.name === cashRegister);
  }

  var text = "";
  switch (c.type) {
    case "fiscat":
    case "fiscat2":
    case "fiscat3":
    case "fiscat3a":
    case "fiscat4":
    case "fiscat5":
      text = dayClose_fiscat();
      break;
    case "sam4s":
      //text = dayClose_sam4s();
      break;
    case "cashcube":
      //text = dayClose_cashcube();
      break;
    case "jota_m":
      //text = dayClose_jotam();
      break;
    case "sento":
      //text = dayClose_sento();
      break;
    case "datecs":
      //text = dayClose_sento();
      break;
    case "a234":
      text = dayClose_a234();
      break;
    default:
  }
  if (text)
    sendToCashier(c, text);
}

export const storno = () => {

  function onChange(event) {
    console.log(event);
  }

  var def = $.Deferred();
  const dialog = $("<div id='dialog'/>");
  $("body").append(dialog);
  render(<StornoDialog def={def} buttons={[I18n.t("local.ok")]} show={true} container={dialog.get(0)} />, dialog.get(0));
  def.promise().always(() => {
    dialog.remove();
  })
  def.promise().done(data => {
    var cashregister = Number($("#cashregisters input:checked").prop("id"));
    const c = auth.myStatus.restaurant_cash_registers.find(r => r.id === cashregister);
    var text = "";
    switch (c.type) {
      case "fiscat":
      case "fiscat2":
      case "fiscat3":
      case "fiscat3a":
      case "fiscat4":
      case "fiscat5":
        text = storno_fiscat(data);
        break;
      case "sam4s":
        //text = dayClose_sam4s();
        break;
      case "cashcube":
        //text = dayClose_cashcube();
        break;
      case "jota_m":
        //text = dayClose_jotam();
        break;
      case "sento":
        //text = dayClose_sento();
        break;
      case "a234":
        text = storno_a234(data);
        break;
      default:
    }
    if (text) {
      if (c.format === "http" || c.format === "rs232")
        post("adminService/" + localStorage.restaurantSelected + "/sendToCashier?cashregister=" + (c ? c.id : 0) + "&lang=" + localStorage.language, text).done(data => { });
      else
        sendToCashier(c, text);

    }


  })
  return def.promise();

}

export const sendToCashier = (c, text) => {
  if (c && (c.format === "android_http" || c.format === "android" || c.format === "android_bluetooth" || c.format === "android_rs232") && (auth.device.platform === "android" || auth.device.platform === "Android")) {
    if (c.format === "android_bluetooth") {
      //eslint-disable-next-line no-undef
      if (typeof bluetoothSerial === "undefined") {
        console.error("Bluetooth serial is not available");
        messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_adapter"));
        return;
      }
      //eslint-disable-next-line no-undef
      bluetoothSerial.requestPermission(success => {
        //eslint-disable-next-line no-undef
        bluetoothSerial.list(success => {
          console.log(success);
          const serial = success.filter(c => c.name.startsWith("ECR-"));
          if (serial.length === 1) {
            //eslint-disable-next-line no-undef
            bluetoothSerial.connect(serial[0].address, connectSuccess => {
              console.log("Bluetooth serial working", connectSuccess)
              if (auth.myStatus.restaurant_cash_registers.find(cc => cc.id == c.id).type !== "cashcube")
                text = text.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              //eslint-disable-next-line no-undef
              bluetoothSerial.write(text, function success() {
                //eslint-disable-next-line no-undef
                bluetoothSerial.disconnect(function success() { }, function error() {
                  console.error("Bluetooth Serial failed to close");
                })
              }, function error() {
                console.error("Serial failed to write");
                //eslint-disable-next-line no-undef
                bluetoothSerial.disconnect(function success() { }, function error() {
                  console.error("Bluetooth Serial failed to close");
                });
              });
            }, connectFailure => {
              console.error("Bluetooth serial not", connectFailure)
            });
          } else {
            if (serial.length > 1)
              messageDialog(I18n.t("local.warning"), I18n.t("admin_local.too_many_bluetooth_cashiers"));
            else
              messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_cashiers"));
          }
        }, failure => {
          console.error("Bluetooth serial error", JSON.stringify(failure));
        });
      });
    } else if (c.format === "android") {
      const opts = {
        baudRate: 9600,
        //dtr: true,
        //rts: true,
        sleepOnPause: false
      }
      if (c && c.type === "jota_m")
        opts.parity = 1;

      //eslint-disable-next-line no-undef
      serial.requestPermission(opts, function success() {
        console.log("Serial granted");
        //eslint-disable-next-line no-undef
        serial.open(opts, function success() {
          _log("Serial openned!");
          //eslint-disable-next-line no-undef
          serial.write(text + 0x00, function success(successMessage) {
            console.error("Serial write:" + successMessage);
            setTimeout(() => {
              //eslint-disable-next-line no-undef
              serial.close(function success() {
                console.error("Serial closed");
              }, function error() {
                console.error("Serial failed to close");
              })
            }, 8000);
          }, function error() {
            console.error("Serial failed to write");
            //eslint-disable-next-line no-undef
            serial.close(function success() { }, function error() {
              console.error("Serial failed to close");
            });
          });
        }, function error(error) {
          console.error("Serial failed to open(0)", error);
        });
      }, function error(error) {
        console.error("Serial denied", error);
      });
    } else if (c.format === "android_rs232") {
      //eslint-disable-next-line no-undef
      uart.list(dev => {
        _log(text);
        //eslint-disable-next-line no-undef
        uart.write({ dev: dev, text: text }, function success(successMessage) {
          console.error("Serial write:" + successMessage);
        }, function error(error) {
          console.error("Serial failed to write: " + JSON.stringify(error));
        });
      }, error => {
        console.error("UART list error " + JSON.stringify(error));
        var dev = "/dev/ttyS3";
        //eslint-disable-next-line no-undef
        uart.write({ dev: dev, text: text }, function success(successMessage) {
          console.error("Serial write:" + successMessage);
        }, function error(error) {
          console.error("Serial failed to write2: " + JSON.stringify(error));
        });
      })
    } else if (c.format === "android_http") {
      var commands = text.split("\r\n").filter(c => c).map(command => "command=" + encodeURIComponent(command.substring(8)));
      function send() {
        const command = commands.shift();

        post("http://127.0.0.1:15000/Request", command, {
          Accept: "gip, deflate",
          "Content-Type": "application/x-www-form-urlencoded",
          UrlEncoded: "1"
        }).done(data => {
          if (commands.length > 0)
            send();

        }).fail(data => {
          console.log(command);
          console.log(data);
        })
      }
      send();

      //payment.qrcode.split("\r\n").forEach(command => {
      //})
    }
  } else {
    post("adminService/" + localStorage.restaurantSelected + "/sendToCashier?cashregister=" + (c ? c.id : 0) + "&lang=" + localStorage.language, text).done(data => {
    });
  }
}


export const postPayment = (c, tableOccupation, tableMatePayments, printOnly, handler, serialDev = "") => {
  //eslint-disable-next-line no-undef
  //if (SerialUSB) {
  //eslint-disable-next-line no-undef
  const serial = window.serial;
  //}

  tableMatePayments.forEach(tmp => {
    if (tmp.payments) {
      if (tmp.payments.filter(p => p.amount).length === 0)
        tmp.payments = [tmp.payments[0]];
      else {
        tmp.payments = tmp.payments.filter(p => p.amount);
      }
    }
  })

  post("adminService/" + localStorage.restaurantSelected + "/" + tableOccupation + "/" + (printOnly ? "printReceipt" : "tableMatePaid") + "?cashregister=" + (c ? c.id : 0) + "&lang=" + localStorage.language, printOnly ? tableMatePayments[0] : tableMatePayments,
    undefined, undefined, undefined, undefined, 70000, undefined).done(data => {

      var closedTableOccupations = [];

      try { closedTableOccupations = JSON.parse(localStorage.receipts); } catch (ex) { }

      if (!Array.isArray(closedTableOccupations)) {
        closedTableOccupations = [];
      }


      try {
        closedTableOccupations = closedTableOccupations.filter(t => t?.tableOccupation?.tableMatePayments.filter(tmp => tmp.payments[0].date > new Date() - 30 * 60000).length);

        closedTableOccupations = closedTableOccupations.filter(t => t?.tableOccupation.id !== data?.tableOccupation?.id);

      } catch (ex) {
        console.error(JSON.stringify(closedTableOccupations));
        console.error(ex);
      }
      if (!data.error)
        closedTableOccupations.push(data);

      localStorage.receipts = JSON.stringify(closedTableOccupations);

      if (data.error) {
        window.I18n = I18n;
        var error = data.error;
        try {
          error = eval(data.error)
        } catch (Ex) { }
        messageDialog(I18n.t("local.error_message"), error);
        return;
      }

      if (c && (c.format === "android_http" || c.format === "android" || c.format === "android_bluetooth" || c.format === "android_rs232") && (auth.device.platform === "android" || auth.device.platform === "Android")) {
        if (c.format === "android_bluetooth") {
          //eslint-disable-next-line no-undef
          if (typeof bluetoothSerial === "undefined") {
            console.error("Bluetooth serial is not available");
            messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_adapter"));
            return;
          }
          //eslint-disable-next-line no-undef
          bluetoothSerial.list(success => {
            const serial = success.filter(c => c.name.startsWith("ECR-"));
            if (serial.length === 1) {
              //eslint-disable-next-line no-undef
              bluetoothSerial.connect(serial[0].address, connectSuccess => {
                console.log("Bluetooth serial working", connectSuccess)
                tableMatePayments.forEach(payment => {
                  if (payment.qrcode) {
                    var text = payment.qrcode;
                    if (auth.myStatus.restaurant_cash_registers.find(cc => cc.id == c.id).type !== "cashcube")
                      text = text.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    //eslint-disable-next-line no-undef
                    bluetoothSerial.write(text, function success() {
                      //eslint-disable-next-line no-undef
                      bluetoothSerial.disconnect(function success() { }, function error() {
                        console.error("Bluetooth Serial failed to close");
                      })
                    }, function error() {
                      console.error("Serial failed to write");
                      //eslint-disable-next-line no-undef
                      bluetoothSerial.disconnect(function success() { }, function error() {
                        console.error("Bluetooth Serial failed to close");
                      });
                    });
                  }
                });
              }, connectFailure => {
                console.error("Bluetooth serial not", connectFailure)
              });
            } else {
              if (serial.length > 1)
                messageDialog(I18n.t("local.warning"), I18n.t("admin_local.too_many_bluetooth_cashiers"));
              else
                messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_cashiers"));
            }
          }, failure => {
            console.error("Bluetooth serial error", JSON.stringify(failure));
          });
        } else if (c.format === "android") {

          const opts = {
            baudRate: 9600,
            //dtr: true,
            //rts: true,
            sleepOnPause: false
          }
          if (c && c.type === "jota_m")
            opts.parity = 1;

          //eslint-disable-next-line no-undef
          serial.requestPermission(function success() {
            console.log("Serial granted phase 2");
            //eslint-disable-next-line no-undef
            serial.open(opts, function success() {
              console.log("Serial openned phase 2");
              tableMatePayments.forEach(payment => {
                if (payment.qrcode) {
                  console.log("Trying serial write:" + payment.qrcode);
                  //eslint-disable-next-line no-undef
                  serial.write(payment.qrcode + "\r\n", function success(successMessage) {
                    console.log("Serial write:" + successMessage);
                    /*
                    setTimeout(() => {
                      //eslint-disable-next-line no-undef
                      serial.close(function success() {
                        console.error("Serial closed");
                      }, function error() {
                        console.error("Serial failed to close");
                      })
                    }, 4000);
                    */
                  }, function error() {
                    console.error("Serial failed to write");
                    //eslint-disable-next-line no-undef
                    serial.close(function success() { }, function error() {
                      console.error("Serial failed to close");
                    });
                  });
                }
              })

            }, function error(error) {
              console.error("Serial failed to open(1)", error);
            });
          }, function error(error) {
            console.error("Serial denied", error);
          });
        } else if (c.format === "android_rs232") {
          tableMatePayments.forEach(payment => {
            //eslint-disable-next-line no-undef
            uart.list(dev => {
              _log(payment.qrcode);
              //eslint-disable-next-line no-undef
              uart.write({ dev: dev, text: payment.qrcode }, function success(successMessage) {
                console.error("Serial write:" + successMessage);
              }, function error(error) {
                console.error("Serial failed to write: " + JSON.stringify(error));
              });
            }, error => {
              console.error("UART list error " + JSON.stringify(error));
              var dev = "/dev/ttyS3";
              //eslint-disable-next-line no-undef
              uart.write({ dev: dev, text: payment.qrcode }, function success(successMessage) {
                console.error("Serial write:" + successMessage);
              }, function error(error) {
                console.error("Serial failed to write2: " + JSON.stringify(error));
              });
            })
          });
        } else if (c.format === "android_http") {

        }
      }

      handler(data);
    }).fail(data => {
      auth.ajaxError = false;
      if (data.responseJSON)
        data = data.responseJSON;
      else if (data.responseText)
        data = JSON.parse(data.responseText.replaceAll("\t", "").replaceAll("\n", " ").replaceAll("\r", ""));
      messageDialog(I18n.t("local.error_message"), I18n.t(data.error));
      //console.error(data);
    });
}

export const reprintReceipts = () => {
  if (typeof localStorage.receipts === "undefined")
    return;
  var modal = $("#tableMatePaidDialog");
  modal.empty();
  var closedTableOccupations = JSON.parse(localStorage.receipts);

  if (!Array.isArray(closedTableOccupations)) {
    closedTableOccupations = [];
  }

  closedTableOccupations.reverse();

  $("#reprintReceiptsTemplate")
    .tmpl({
      closedTableOccupations: closedTableOccupations,
      ...tmplparams()
    })
    .appendTo(modal)
  var modal = $("#tableMatePayments.modal");

  function updateStorno() {
    var cashregister = Number($("#cashregisters input:checked").prop("id"));
    cashregister = auth.myStatus.restaurant_cash_registers.find(c => c.id === cashregister);
    if (cashregister) {
      switch (cashregister.type) {
        case "fiscat":
        case "fiscat2":
        case "fiscat3":
        case "fiscat3a":
        case "fiscat4":
        case "fiscat5":
        case "a234":
          modal.find("#storno").removeClass("hidden");
          modal.find("#dayOpen").removeClass("hidden");
          modal.find("#dayClose").removeClass("hidden");
          break;
        default:
          modal.find("#storno").addClass("hidden");
          modal.find("#dayOpen").addClass("hidden");
          modal.find("#dayClose").addClass("hidden");
          break;
      }
    } else {
      modal.find("#storno").addClass("hidden");
      modal.find("#dayOpen").addClass("hidden");
      modal.find("#dayClose").addClass("hidden");
    }
  }
  updateStorno();
  modal.find("#cashregisters input").on("change", updateStorno);
  modal.show();
}

export const reprintReceipt = (tableMatePaymentId) => {
  var data = JSON.parse(localStorage.receipts);
  if (!Array.isArray(data)) {
    data = [];
  }

  var data = data.find(tableOccupation => tableOccupation.tableOccupation.tableMatePayments.find(tmp => tmp.id === tableMatePaymentId));
  if (!data)
    return;
  var tableMatePayment = data.tableOccupation.tableMatePayments.find(tmp => tmp.id === tableMatePaymentId);
  if (!tableMatePayment)
    return;
  tableMatePayment.orders = data.orders.filter(o => tableMatePayment.orderIds.split(",").find(oo => oo == o.id));
  function doit() {
    getQrcodeCode(tableMatePayment).done(url => {
      var cashregister = $("#cashregisters input:checked").prop("id");
      cashregister = auth.myStatus.restaurant_cash_registers.find(c => c.id == cashregister);
      const tableMatePayments = {
        id: tableMatePaymentId,
        tableOccupation: data.tableOccupation.id,
        qrcode: url
      }
      if (cashregister && (cashregister.format === "http" || cashregister.format === "rs232"))
        postPayment(cashregister, data.tableOccupation.id, [tableMatePayments], true, (data) => {
        })
      else
        sendToCashier(cashregister, url);
      ///update(data);
      //reprintReceipts();
    })
  }
  if (tableMatePayment.printed) {
    confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.reprint_receipt")).done(doit);
  } else
    doit();

}

export const registerTip = (tableMatePaymentId) => {
  const data = JSON.parse(localStorage.receipts);
  const tableOccupation = data.find(t => t.tableOccupation.tableMatePayments.find(tmp => tmp.id === tableMatePaymentId));
  const tableMatePayment = tableOccupation.tableOccupation.tableMatePayments.find(tmp => tmp.id === tableMatePaymentId);
  redoTableMatePaid(tableMatePayment, tableOccupation.orders, (d) => {
  });
}

export const selectTerminal = (type) => {
  console.log("selectTerminal");
  const choices = auth.myStatus.restaurant_bank_terminals.filter(t => t.type === type).map(t => t.name);
  choiceDialog2(I18n.t("local.request"), I18n.t("admin_local.choose_terminal_to_use"), choices).done(choice => {
    localStorage.teya_terminal = choices[choice];
    $(".input-group.amountPaid[paymentMethodType = '" + type + "'] button.paymentMethod").html(localStorage.teya_terminal);
  });
}

