import React from 'react';
import menuitemutil from '../js/admin/menuitem';
import { avoidMultipleClick, getLocale, getShortName, isDuplicateClickEvent, lighten } from '../js/auth';
import { getAvailableQuantityLabel, showMenuItemCategory } from '../js/chooseMenuItem';
import { orderMenuItem } from '../js/orderMenuItem';
import moment from 'moment';
import orderListUtil, { playClickSound } from '../js/order-list-util';

function CategoryTemplate({
    iid,
    id,
    defaultColor,
    image,
    imageTimestamp,
    name,
    children,
    activeMenuCategories,
    activeMenuItems,
    tmplparams,
    server,
    war,
    myStatus
}) {

    const handleClick = event => {
        avoidMultipleClick(this, event);
        showMenuItemCategory(`${iid || 0}-${id}`);
    }

    return (
        <React.Fragment>
            <div
                id={`${iid || 0}-${id}`}
                className="parentCategory-0 menuItemCategory"
                level="1"
                onClick={handleClick}
                style={{
                    backgroundColor: defaultColor || undefined,
                    backgroundImage: defaultColor || "#72e104",
                }}
            >
                {!myStatus.restaurant_settings['tables-overview']['show-category-image'] &&
                    image ? (
                    <div
                        className="menuItem-image"
                        style={{
                            backgroundImage: `url('${server}/${war}/adminService/${localStorage.restaurantSelected}/getImage/${image}?timestamp=${imageTimestamp || 0}')`,
                        }}
                    >
                        <span className="menuItemCategoryLabel withImage">
                            {getLocale(name, menuitemutil.getLanguage())}
                        </span>
                    </div>
                ) : (
                    <div style={{ display: 'flex', position: 'relative', flexFlow: 'column', justifyContent: 'center', flexGrow: 1 }}>
                        <span className="menuItemCategoryLabel">{getLocale(name, menuitemutil.getLanguage())}</span>
                    </div>
                )}
            </div>

            {children && children.length > 0 ? (
                children.map((child) => {
                    if (child.isTop && child.isActive) {
                        if (child.entityType === 'Drink' || child.entityType === 'Meal') {
                            return (
                                <ItemTemplate2
                                    key={child.id}
                                    pid={`0-${id}`}
                                    d={child}
                                    level={2}
                                    {...tmplparams}
                                />
                            );
                        } else {
                            return (
                                <SubcategoryTemplate
                                    key={child.id}
                                    iid2={`0-${id}-${child.id}`}
                                    pid={`0-${id}`}
                                    d={child}
                                    {...tmplparams}
                                />
                            );
                        }
                    }
                    return null;
                })
            ) : (
                <>
                    {activeMenuCategories &&
                        activeMenuCategories.filter(item => item.isActive && item.isTop).map((category) => (
                            <SubcategoryTemplate
                                key={category.id}
                                iid2={`0-${id}-${category.id}`}
                                pid={`0-${id}`}
                                d={category}
                                level={2}
                                {...tmplparams}
                            />
                        ))}
                    {activeMenuItems &&
                        activeMenuItems.map((item) => {
                            if (item.isActive && item.isTop) {
                                return item.defaultFromDate && item.defaultToDate
                                    ? dateArray(item).map((date, index) => (
                                        <ItemTemplate2
                                            key={`${item.id}-${index}`}
                                            pid={`0-${id}`}
                                            d={{ ...item, defaultFromDate: date }}
                                            {...tmplparams}
                                        />
                                    ))
                                    : (
                                        <ItemTemplate2
                                            key={item.id}
                                            pid={`0-${id}`}
                                            d={item}
                                            {...tmplparams}
                                            defaultColor={defaultColor}
                                        />
                                    );
                            }
                            return null;
                        })}
                </>
            )}
        </React.Fragment>
    );
}



// SubcategoryTemplate Component
const SubcategoryTemplate = ({
    iid2,
    pid,
    d,
    level,
    tmplparams,
    server,
    war,
    myStatus,
}) => {
    const handleClick = (event) => {
        if (isDuplicateClickEvent(event)) return;
        playClickSound();
        showMenuItemCategory(iid2);
    };

    return (
        <React.Fragment>
            <div
                id={iid2}
                className={`parentCategory-${pid} hidden categoryContent menuItemCategory`}
                level={level}
                onClick={handleClick}
                style={{
                    backgroundColor: d.defaultColor || undefined,
                    backgroundImage: d.defaultColor || "#72e104",
                }}
            >
                {!myStatus.restaurant_settings["tables-overview"]["show-category-image"] &&
                    d.image ? (
                    <div
                        className="menuItem-image"
                        style={{
                            backgroundImage: `url('${server}/${war}/adminService/${localStorage.restaurantSelected}/getImage/${d.image}?timestamp=${d.imageTimestamp}')`,
                        }}
                    >
                        <span className="menuItemCategoryLabel withImage">
                            {getLocale(d.name, menuitemutil.getLanguage())}
                        </span>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            position: "relative",
                            flexFlow: "column",
                            justifyContent: "center",
                            flexGrow: 1,
                            filter: "inverse(1)",
                        }}
                    >
                        <span className="menuItemCategoryLabel">
                            {getLocale(d.name, menuitemutil.getLanguage())}
                        </span>
                    </div>
                )}
            </div>

            {/* Render active menu categories */}
            {d.activeMenuCategories &&
                d.activeMenuCategories.map((category) => {
                    if (category.isActive && category.isTop) {
                        return (
                            <SubcategoryTemplate
                                key={category.id}
                                iid2={`${iid2}-${category.id}`}
                                pid={iid2}
                                d={category}
                                level={d.level + 1}
                                tmplparams={tmplparams}
                                server={server}
                                war={war}
                                myStatus={myStatus}
                            />
                        );
                    }
                    return null;
                })}

            {/* Render active menu items */}
            {d.activeMenuItems &&
                d.activeMenuItems.map((item) => {
                    if (item.isActive && item.isTop) {
                        return (
                            <ItemTemplate2
                                key={item.id}
                                pid={iid2}
                                d={item}
                                {...tmplparams}
                            />
                        );
                    }
                    return null;
                })}

            {/* Render children */}
            {d.children &&
                d.children.map((child) => {
                    if (child.isTop && child.isActive) {
                        if (child.entityType === "Drink" || child.entityType === "Meal") {
                            return (
                                <ItemTemplate2
                                    key={child.id}
                                    pid={iid2}
                                    d={child}
                                    level={d.level + 1}
                                    {...tmplparams}
                                />
                            );
                        } else {
                            return (
                                <SubcategoryTemplate
                                    key={child.id}
                                    iid2={`${iid2}-${child.id}`}
                                    pid={iid2}
                                    d={child}
                                    tmplparams={tmplparams}
                                    server={server}
                                    war={war}
                                    myStatus={myStatus}
                                    showMenuItemCategory={showMenuItemCategory}
                                    playClickSound={playClickSound}
                                />
                            );
                        }
                    }
                    return null;
                })}
        </React.Fragment>
    );
};

function ItemTemplate2({
    d,
    pid,
    server,
    war,
    myStatus,
}) {

    const handleOrderClick = (event, entityType, id, quantity = undefined) => {
        if (isDuplicateClickEvent(event)) return;
        avoidMultipleClick(event.currentTarget, event);
        event.stopPropagation();
        orderMenuItem(event.currentTarget, entityType, id, quantity);
    };

    return (
        <>
            {!d.defaultFromDate ||
                d.defaultFromDate >= moment().startOf("week").valueOf() ? (
                <li
                    date={d.defaultFromDate}
                    column={moment(d.defaultFromDate).isoWeekday()}
                    week={moment(d.defaultFromDate).week()}
                    className={`parentCategory-${pid} categoryContent hidden menuItem variationHead list-group-item`}
                    level="2"
                    entityType={d.entityType}
                    menuItem={d.id}
                    id={`${pid}-${d.id}`}
                    style={{
                        backgroundColor: d.defaultColor || "#72e104"
                    }}
                >
                    <div>
                        <div
                            className="menuItemHead"
                            onClick={(event) =>
                                handleOrderClick(event, d.entityType, d.id, undefined)
                            }
                        >
                            {!myStatus.restaurant_settings["tables-overview"][
                                "show-menuitem-image"
                            ] && d.image ? (
                                <div
                                    className="menuItem-image"
                                    style={{
                                        backgroundImage: `url('${server}/${war}/adminService/${localStorage.restaurantSelected}/getImage/${d.image}?timestamp=${d.imageTimestamp}')`,
                                    }}
                                >
                                    <div style={{ position: "relative" }}>
                                        <span className="menuItem-name2">
                                            {getLocale(getShortName(d), menuitemutil.getLanguage())}
                                        </span>
                                        <span className="menuItem-name">
                                            {getLocale(getShortName(d), menuitemutil.getLanguage())}
                                        </span>
                                    </div>
                                    <span className="manufacturer">{d.manufacturer}</span>
                                    {d.labels?.length > 0 && (
                                        <div className="label">
                                            {d.labels.map((label, index) => (
                                                <span key={index}>{label.name}</span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div style={{ flexGrow: 1 }} />
                                    <span>
                                        {getLocale(getShortName(d), menuitemutil.getLanguage())}
                                    </span>
                                    <span className="manufacturer">{d.manufacturer}</span>
                                    {d.labels?.length > 0 && (
                                        <div className="label">
                                            {d.labels.map((label, index) => (
                                                <span key={index}>{label.name}</span>
                                            ))}
                                        </div>
                                    )}
                                    {d.barcodes?.map((barcode, index) => (
                                        <div
                                            key={index}
                                            style={{ display: "none" }}
                                            className="barcode"
                                            value={barcode.id}
                                        />
                                    ))}
                                    <div style={{ flexGrow: 1 }} />
                                </>
                            )}
                            {d.description !== "N/A" && (
                                <div
                                    className="menuItemInfo icon-info-outline"
                                    onClick={(event) =>
                                        orderListUtil.showMenuItemInfo(event, d.id)
                                    }
                                />
                            )}
                        </div>
                        {d.availableQuantities?.map((quantity, index) =>
                            quantity.onMenu ? (
                                <div
                                    key={index}
                                    className="list-group-item defaultQuantity"
                                    style={
                                        d.description !== "N/A"
                                            ? {}
                                            : { borderTop: "1px dashed gray" }
                                    }
                                    onClick={(event) =>
                                        handleOrderClick(
                                            event,
                                            d.entityType,
                                            d.id,
                                            quantity.quantity
                                        )
                                    }
                                >
                                    {getAvailableQuantityLabel(d, quantity) !== "" && (
                                        <div className="quantity">
                                            <span>{getAvailableQuantityLabel(d, quantity)}</span>
                                            <br />
                                        </div>
                                    )}
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price">{quantity.localPrice}</div>
                                    {quantity.localPrice !== quantity.takeawayPrice && (
                                        <div className="price icon-cup">{quantity.takeawayPrice}</div>
                                    )}
                                </div>
                            ) : null
                        )}
                    </div>
                </li>
            ) : null}
        </>
    );
}


function dateArray(m) {
    return Array.from({
        length: (m.defaultToDate - m.defaultFromDate) / (1000 * 60 * 60 * 24) + 1
    }, (_, index) => new Date(m.defaultFromDate + index * 24 * 60 * 60 * 1000)).map(d => d.getTime());
}


export default CategoryTemplate;
