const admin_local_cn = {
  confirm_customer_deletion: "您确定要删除用户 <b>{0}</b> 吗？",
  confirm_role_deletion: "您确定要撤销 <b>{1}</b> 对 <b>{2}</b> 的访问权限吗？",
  do_you_really_want_to_delete_the_menu_category: "您确定要删除菜单类别吗？",
  do_you_really_want_to_delete_the_menu_item: "您确定要删除菜单项吗？",
  failed_to_delete_user: "删除失败。<br>错误: {0}",
  failed_to_delete_user_anonimize: "该用户无法删除！您想将其匿名化吗？姓名、邮箱和密码将被删除！",
  are_you_sure_you_want_to_delete_the_table: "您确定要删除 <b>桌子 {0}</b> 吗？",
  are_you_sure_you_want_to_delete_the_room: "您确定要删除 <b>房间 {0}</b> 吗？",
  are_you_sure_you_want_to_delete: "您确定要删除吗？",
  failed_to_delete_table_in_use: "该桌子无法删除！它已经被使用！请禁用它！",
  failed_to_create_table_not_unique: "创建桌子失败。桌号已被使用！",
  failed_to_delete_drink_category_in_use: "删除饮料类别失败！它已经在使用中。请禁用它！",
  failed_to_delete_drink_in_use: "删除饮料失败！它已经在使用中。请禁用它！",
  failed_to_delete_meal_category_in_use: "删除餐品类别失败！它已经在使用中。请禁用它！",
  failed_to_delete_meal_in_use: "删除餐品失败！它已经在使用中。请禁用它！",
  failed_to_delete_discount_type_in_use: "删除折扣类型失败！它已经在使用中。请禁用它！",
  failed_to_delete_label_in_use: "删除标签失败！它已经在使用中！",

  table_number_label: "桌号",
  number_of_seats: "可用座位",
  shareable: "可共享",
  free_seats: "空闲座位",
  active: "激活",
  _active: "激活",
  table_editor: "桌子编辑",

  add: "添加",
  edit: "编辑",
  delete: "删除",
  activate: "激活",
  deactivate: "禁用",
  make_shareable: "使桌子可共享",
  make_unshareable: "使桌子不可共享",
  make_pos: "设置为销售点",
  make_non_pos: "不是销售点",
  lock: "占用",
  new_lock: "+ 占用",
  lock_pickup: "新取货",
  lock_homedelivery: "新送货上门",
  unlock: "解锁",

  tables: "桌子",
  enter_table_number: "请输入桌号",
  enter_table_seats: "请输入可用座位数",
  table_is_shareable: "桌子是可共享的",
  table_is_active: "桌子是激活的",
  table_free_seats: "可用空闲座位",

  unit_price: "单价",
  _unit_price: "价格",

  drinks: "饮料",
  drink_name: "名称",
  drink_quantity_type: "数量类型",
  drink_available_quantity: "可用数量",
  drink_quantity_name: "名称",
  drink_quantity: "数量",
  drink_price: "价格",
  drink_editor: "饮料编辑器",
  drink_category_name: "类别",
  drink_category_editor: "饮料类别编辑器",
  add_category: "新类别",
  _add_category: "新类",
  add_item: "新项目",
  _add_item: "新项目",

  meals: "餐点",
  meal_name: "名称",
  meal_quantity_type: "数量类型",
  meal_available_quantity: "可用数量",
  meal_quantity_name: "名称",
  meal_quantity: "数量",
  meal_price: "价格",
  meal_editor: "餐点编辑器",
  meal_category_name: "类别",
  meal_category_editor: "餐点类别编辑器",
  add_meal_category: "新类别",
  add_meal: "新餐点",

  available_quantity: "可用数量",
  _available_quantity: "数量",

  description: "描述",
  enter_description: "如有需要，请输入描述",

  language: "语言",
  default_language: "默认",
  not_defined: "???",
  close: "关闭",
  close_order: "关闭订单",
  save_and_new: "保存并复制",
  pay: "支付",
  save_and_unlock: "支付并解锁",

  rejection_reason: "拒绝原因",
  typical_rejection_reasons: "典型拒绝原因",
  custom_reason: "自定义原因",


  table_number: "桌号: {0}",
  team_number: "团队: {0}",
  organizer: "组织者: {0}",
  order_number: "订单号: {0}",
  oldest_order: "最新订单: {0}",
  table_occupation_number: "占用号: {0}",

  orders_tab: "订单列表",
  by_person_tab: "按人",
  by_timing_tab: "按时间",
  by_drink_or_meal_tab: "按餐品或饮料",

  inherit: "继承",
  meal_timing: "时间安排",
  timing_inherit: "继承",
  timing_asap: "尽快",
  timing_appetizer: "开胃菜",
  timing_maincourse: "主菜",
  timing_desert: "甜点",
  timing_last: "最后",
  timing: "时间",
  timing_null: "",

  production_lines: "生产线",
  production_line_editor: "生产线编辑",

  production_line: "生产线",
  _production_line: "生产线",
  production_line_waiting: "依赖生产线",
  production_line_length: "队列",
  production_line_title: "{0} 生产线",
  all_production_lines: "所有生产线",
  default_production_line: "默认生产线",
  _default_production_line: "默认生产线",
  production_line_name: "名称",
  enter_production_line_name: "请输入生产线名称",
  production_line_is_active: "生产线已激活",
  are_you_sure_you_want_to_delete_the_production_line: "您确定要删除生产线 <b>{0}</b> 吗？",
  failed_to_delete_production_line_in_use: "删除生产线失败！它已在使用中，请禁用它！",
  failed_to_create_production_line_not_unique: "创建生产线失败！名称不唯一！",

  vat_categories: "增值税类别",
  vat_category_name: "增值税类别名称",
  vat_category: "增值税类别",
  _vat_category: "增值税",
  vat_category_editor: "增值税类别编辑",
  enter_vat_category_name: "请输入增值税类别名称",
  percent_name: "百分比",
  enter_percent: "请输入增值税百分比（例如 27）",
  default_vat_category: "增值税",
  _default_vat_category: "增值税",
  default_takeaway_vat_category: "外卖增值税",
  _default_takeaway_vat_category: "外卖增值税",

  occupy: "占用",
  number_of_persons: "人数",
  unoccupy: "释放",
  new_order: "新订单",
  submit_new_orders: "提交新订单",
  submit_and_accept_new_orders: "提交并接受新订单",
  submit_and_accept_and_print_new_orders: "打印新订单",
  submit_and_accept_and_print_and_pay_new_orders: "支付",
  paying: "支付中",
  cancel_paying: "取消账单",
  ordering_allowed: "账单已撤销，允许再次下单！",
  paid: "已支付",

  please_choose_mate_wanting_to_pay: "谁想支付？",
  please_choose_mate_who_paid: "谁已支付？",
  print_invoice: "打印发票",

  roles: "角色",
  currencies: "货币",
  payment_methods: "支付方式",
  connected_restaurants: "已连接餐厅",
  connected_restaurant: "已连接餐厅",
  alias: "全局 ID",

  role: "角色",
  payment_method: "支付方式",
  _payment_method: "支付方式",
  user: "用户",
  users: "用户",
  entity_type: "实体类型",
  entity: "实体",
  entities: "实体们",

  restaurant_editor: "餐厅",
  name: "名称",
  _name: "名称",
  languages: "语言",
  town: "城镇",
  longitude: "经度",
  latitude: "纬度",

  exchange_rate: "汇率",
  _exchange_rate: "汇率",

  please_select_a_restaurant: "请选择餐厅",

  invoice_heading: "发票抬头",
  invoice_footer: "发票页脚",
  enter_invoice_heading: "请输入发票的抬头（HTML 格式）！",
  settings: "设置",

  not_allowed_order_paid: "支付订单时不允许进行此操作！",
  not_allowed_order_is_being_paid: "正在支付的订单不允许进行此操作！",

  more_than_a_day: "> 1 天",

  active_average_acceptance_duration: "平均订单接受时间",
  active_average_delivering_duration: "平均订单配送时间",
  active_max_acceptance_duration: "最高订单接受时间",
  active_max_delivering_duration: "最高订单配送时间",
  active_min_acceptance_duration: "最低订单接受时间",
  active_min_delivering_duration: "最低订单配送时间",

  mandatory: "必选",
  included: "可移除",
  optional: "选项",
  references: "参考",
  isTop: "菜单上",
  _isTop: "菜单",

  failed_to_get_restaurant_layout: "未定义餐厅布局！请先上传布局！",

  qr_code: "二维码",
  person: "人员",

  printers: "打印机",
  printer: "打印机",
  label_printer: "标签打印机",
  connected_from: "已连接",

  collaborating_production_lines: "协作生产线",
  short_name: "简称",
  do_you_really_want_to_print_the_orders_until_timing: "您确定只打印到 <b>{0}</b> 这道菜的订单吗？",
  do_you_really_want_to_print_only_the_selected_orders: "您确定只打印选中的订单吗？",
  do_you_really_want_to_reprint_the_orders_until_timing: "您确定要 <b><u>重新打印</u></b> 订单直到 <b>{0}</b> 吗？",
  do_you_really_want_to_reprint_only_the_selected_orders: "您确定要 <b><u>重新打印</u></b> 选中的订单吗？",
  enable_edit_mode: "修改餐桌布局",
  disable_edit_mode: "恢复餐桌布局",
  change_table_number: "更改桌号",
  save_layout: "保存餐桌布局",
  layout_succesfully_saved: "餐桌布局已保存！",
  table: "桌子",
  seat: "座位",
  rotate_right: "右旋（45°）",
  rotate_left: "左旋（45°）",
  duplicate: "复制",
  enter_barion_pos_key: "请输入您的 Barion 秘密密钥（POSKey）",

  printer_server_name: "打印服务器名称",
  printInvoice: "打印发票",
  printTableSlip: "打印桌号二维码",
  printVATInvoice: "打印增值税发票",
  printer_actions: "打印操作",
  format: "格式",

  quantity_types: "服务类型",

  enter_category: "请输入类别名称",
  enter_Drink_menuitem: "请输入饮品名称",
  enter_Meal_menuitem: "请输入餐点名称",

  scan_to_pay_table: "用您的手机扫描\r\n查看桌号 {0} 的消费。\r\n并在线结账！",
  scan_to_join_table: "!LF!ESC|cA!ESC|4CTable {0}!LF!LF!ESC|4C!ESC|cA扫描并下单！LF!ESC|cA并在线管理您的消费！",

  print_receipt: "打印收据",
  choose_customer_for: "为人员 {0} 选择顾客。",
  search_pattern: "搜索模式",
  clear: "清除",

  discount_types: "折扣类型",
  discount: "折扣",
  _discount: "折扣",
  scope: "范围",
  when: "时间",
  how_much: "金额",
  cumulative: "累计",
  categories_or_menuitems: "菜单项或类别",
  labels: "标签",
  fromDate: "开始日期",
  toDate: "结束日期",
  fromTime: "开始时间",
  datetime: "日期和时间",
  discount_percent: "百分比",
  discount_amount: "固定金额",
  on_day: "星期几",
  day1: "星期日",
  day2: "星期一",
  day3: "星期二",
  day4: "星期三",
  day5: "星期四",
  day6: "星期五",
  day7: "星期六",
  per_item: "按项",
  label: "标签",
  are_you_sure_you_want_to_delete_the_production_line: "您确定要删除标签 <b>{0}</b> 吗？",
  enter_labels: "选择标签",
  discounts: "折扣",
  apply_type_discount: "应用折扣",
  manual: "手动",
  automatic: "自动",
  to_whom: "适用对象",
  store: "仓库",
  stores: "仓库",
  input_stores: "输入仓库",
  output_stores: "输出仓库",
  cost: "成本",
  unit_price_estimation: "建议单价",
  cost_estimation: "成本估算",
  suppliers: "供应商",
  supplier: "供应商",
  incomingStock: "入库",
  incomingStocks: "入库记录",
  date: "日期",
  default_store: "默认仓库",
  invoice_id: "发票编号",
  delivery_note_id: "送货单编号",
  supplier_code: "供应商代码",
  _supplier_code: "供应商代码",
  product: "产品",
  quantity: "数量",
  _quantity: "数量",
  quantity_type: "数量类型",
  _quantioty_type: "数量类型",
  expiry_date: "有效期",
  net_unit_price: "净单价",
  brutto_unit_price: "毛单价",
  net_price: "净价格",
  brutto_price: "毛价格",
  _net_unit_price: "净单价",
  _brutto_unit_price: "毛单价",
  _net_price: "净价格",
  _brutto_price: "毛价格",
  total_incoming_stock_entries: "<span>{0}</span> <span class='browser-only'> 总记录数</span>",
  entry_count: "记录数量",
  _entry_count: "记录",
  incoming_stock_entry_text_input: "文本输入",
  are_you_sure_you_want_to_delete_the_incoming_stock: "您确定要删除选中的入库记录吗？",
  are_you_sure_you_want_to_delete_the_inventory: "您确定要删除选中的库存记录吗？",
  delete_all_order_history: "删除所有订单历史记录",


  "tableOccupations": "桌面占用",
  "traffic": "流量",
  "delete_traffic": "删除流量",
  "until_when_delete_the_traffic": "您希望删除直到哪一天的流量（格式：YYYY-MM-DD）",
  "are_you_sure_you_want_to_delete_traffic_until": "您确定要删除直到 <strong>{0}</strong> 的流量吗？",
  "menu": "菜单",
  "time": "时间",
  "seqnr": "序列号",
  "_seqnr": "序号",
  "duration": "持续时间",
  "_duration": "时长",
  "person_count": "人数",
  "amount_paid": "已支付",
  "tip": "小费",
  "_tip": "小费",
  "cancelled_orders": "已删除",
  "ordered_by": "点餐人",
  "orders": "订单",
  "_orders": "订单号",
  "drink": "饮品",
  "meal": "餐点",
  "last_status": "最后状态",
  "_last_status": "最后状态",
  "deleted": "已删除",
  "_deleted": "已删",
  "yes": "是",
  "no": "否",
  "order_sequence_number": "订单号",
  "_order_sequence_number": "订单号",
  "is_paid": "已支付",
  "paid_by": "支付人",
  "_paid_by": "已支付",
  "price": "价格",
  "amount_to_pay": "应付金额",
  "payments": "支付",
  "currency": "货币",
  "amount": "金额",
  "replace": "替换",
  "menuitem_ordered": "订单项",
  "daily_closing": "每日结算",
  "table_occupations": "桌面",
  "count": "数量",
  "test_system": "测试系统",
  "test_mode": "测试模式",
  "multiple_physical_printers_try_to_server_the_virtual_server": "为虚拟打印机 {0} 配置了多个物理打印机！ '{1}' 和 '{2}'！",
  "The device communications channel could not be opened, check the device and retry.": "打印机似乎已关闭或配置不正确。",
  "pay_himself": "自付",
  "unknown_person_pays": "人员 {0} 支付",
  "change_table": "更换桌子",

  "confirm_person_change_to_empty_table": "人员 {0} 是否更换到空桌 {1}？<br/>作为新占用还是继续当前占用？",
  "confirm_person_change_to_table": "人员 {0} 是否加入到已占用桌 {1}？",
  "confirm_table_change_to_table": "整个桌子是否加入到已占用桌 {0}？",
  "confirm_person_change_to_shared_table": "人员 {0} 是否加入到已占用桌 {1}？<br/>作为新占用还是加入现有占用？",
  "confirm_person_change_to_multiple_occupation_table": "人员 {0} 是否加入到已占用桌 {1}？",

  "new_occupation": "作为新占用",
  "extend_occupation": "延续当前占用",
  "joins": "加入",
  "joins_table1": "加入第一个占用，共 {0} 人",
  "joins_table": "加入第 {0} 个占用，共 {1} 人",
  "new_independent_occupation": "新占用",
  "target_table_same_and_source": "目标桌与源桌相同！",
  "sitting_at_table": "坐在桌号 {0}",
  "confirm_additional_table": "是否将桌号 {0} 添加到当前占用？",
  "confirm_change_to_table": "是否将所有人更换到桌号 {0}？",
  "confirm_change_to_homedelivery": "更改为外卖配送？",
  "confirm_change_to_pickup": "更改为自取？",
  "confirm_table_change_to_shared_table": "目标桌是共享桌，请选择可用选项。",
  "default": "默认",
  "role_names": {
    "admin": "管理员",
    "waiter": "服务员",
    "cook": "厨师",
    "store": "库存管理员",
    "printerserver": "打印服务器",
    "finance": "财务",
    "courier": "快递员"
  },
  "restaurant": "餐厅",
  "pending": "待处理",
  "suspend_ordering": "暂停点餐",
  "continue_ordering": "继续点餐",
  "split_invoice": "拆分发票",
  "_split_invoice": "拆分",
  "pays": "支付",
  "comments": "备注",
  "invoice_printed": "发票成功发送至打印机",
  "room": "房间",
  "move_forward": "向前移动",
  "move_backward": "向后移动",
  "move_top": "移动到顶部",
  "move_back": "移动到底部",
  "all": "全部",
  "isInventoryItem": "库存物品",
  "isInventoryItem2": "库存物品/原材料",
  "isInventoryItem3": "库存物品",
  "_isInventoryItem": "库存物品",
  "total": "总计",
  "menuitems": "菜单项",
  "menuitem": "菜单项",
  "item": "项目",
  "_item": "项目",
  "category": "类别",
  "_category": "类别",
  "you_need_admin_right_for_this_action": "您需要管理员权限来执行此操作！",
  "pricePerCostRatio": "单价 / 成本比",
  "price_estimation": "建议价格",
  "add_option": "新选项",
  "option": "选项",
  "_option": "选项",
  "enter_cost": "设置成本",
  "enter_price": "设置价格：例如 500 或 40%",

  "checkbox_option": "下单时可以从类别中选择多个选项",
  "checkbox_option2": "保持为单独选项类别，下单时可以从类别中选择多个选项",
  "optional_radio_option": "下单时最多选择一个选项",
  "mandatory_radio_option": "下单时必须选择一个选项",

  "deletion_not_allowed": "无法删除",
  "paid_order_cannot_be_deleted": "已支付的订单无法删除！",
  "order_cannot_be_deleted_while_mate_is_paying": "正在支付的人的订单无法删除！<br/>请先取消该人的支付状态！",
  "order_cannot_be_deleted_while_order_was_already_selected_for_payment_one": "已选择支付的订单只能由管理员删除！",
  "original_quantity": "原始数量",
  "recalculateConsumption": "重新计算库存",
  "recalculateConsumptionAll": "重新计算所有库存",
  "_recalculateConsumption": "重新计算库存",
  "inventory": "库存盘点",
  "incoming_stock": "入库",
  "stock": "库存",
  "inventory_surplus": "库存盈余",
  "inventory_shortage": "库存短缺",
  "minute": "分钟",
  "hour": "小时",
  "day": "天",
  "week": "周",
  "month": "月",
  "consumption": "销售量",
  "price_goal": "目标价格",
  "today": "今天",
  "yesterday": "昨天",
  "last_7_days": "过去7天",
  "last_30_days": "过去30天",
  "last_3_months": "过去3个月",
  "this_week": "本周",
  "this_month": "本月",
  "this_quarter": "本季度",
  "last_week": "上周",
  "last_month": "上月",
  "last_quarter": "上季度",
  "customRange": "自定义范围",
  "failed_to_execute_action": "操作失败！",
  "no_inventory_quantity": "未选择库存数量进行处理！",
  "income": "收入",
  "profit_margin": "利润率",
  "_profit_margin": "利润",
  "there_is_a_loop_in_the_additions": "菜单项之间存在引用循环！",

  "are_you_sure_you_want_to_delete_the_floorplan": "您确定要删除楼层图 <b>{0}</b> 吗？",
  "floorplans": "楼层图",
  "tooltip_printed": "已打印",
  "tooltip_printfailed": "打印错误！",
  "tooltip_partprinted": "打印错误！正在重新尝试打印！",
  "tooltip_printing": "打印进行中！",
  "tooltip_notprinted": "尚未打印！",
  "controlled_production_lines": "整体更改状态",
  "producing_rules": "打印后的状态",
  "pos_producing_rules": "销售点打印后的状态",
  "manualOnly": "手动",
  "afterPrint": "打印后",
  "install_printer_server": "安装打印服务器",
  "failed_to_print_kitchen_slip": "打印厨房凭条失败！",
  "failed_to_print_invoice": "打印发票失败！",
  "ok": "确定",
  "secret_key": "密钥",
  "global_id": "全球ID",
  "server_url": "WAN URL",
  "local_url": "LAN URL",
  "backup_url": "备份URL",
  "enabled-features": "启用的功能",
  "online": "在线",
  "_online": "在线",
  "visible_online": "对客人可见",
  "book_online": "可供客人预订",
  "make_nonVisibleOnline": "隐藏桌子",
  "make_visibleOnline": "使桌子对客人可见",
  "make_nonBookOnline": "禁用客人占用",
  "make_bookOnline": "启用客人占用",
  "mate_already_joined_table_in_this_restaurant": "{0} 已经加入桌号 {1}！",
  "confirm_not_printing": "您确定要跳过打印订单吗？",
  "save": "保存",
  "save_and_close": "保存并关闭",
  "save_and_open": "保存并测试",
  "table_types": {
    "rectangle_two_seats": "两座桌子",
    "rectangle_four_seats": "四座桌子",
    "rectangle_six_seats": "六座桌子",
    "circle_four_seats": "四座圆桌",
    "rounded_four_seats": "四座圆形桌子",
    "rounded_six_seats": "六座圆形桌子",
    "person": "人",
    "persons": "人",
    "pool_table": "台球桌"
  },
  "seat_types": {
    "normal_chair": "普通椅子",
    "wooden_chair": "木椅"
  },
  "tip_amount_seems_excesive": "金额似乎过高！继续吗？",
  "non_printer_configured": "未配置打印机",
  "disposed_quantity": "已处置数量",
  "comment": "评论",
  "entries": "条目",
  "entry": "条目",
  "other": "其他",
  "created_by": "创建人",
  "modified_by": "修改人",
  "start_count": "预期数量",
  "change": "找零",
  "changeit": "更改",
  "end_count": "实际数量",
  "inventory_disposal": "库存处置",
  "extra_frame_left": "左框内容",
  "extra_frame_right": "右框内容",
  "extra_frame_top": "上框内容",
  "extra_frame_bottom": "下框内容",
  "empty": "空",
  "serving": "服务中",
  "drinkbar": "饮料吧",
  "point_of_sale": "POS",
  "new_sale": "新销售",
  "invoice": "发票",
  "payment": "支付",
  "for_all": "适用于所有",
  "for_selected_none": "适用于选定",
  "for_selected_order": "适用于选定订单",
  "for_selected_tableMate": "适用于选定人员",
  "cancel": "取消",
  "persons": "人数",
  "_persons": "人",
  "phonenumber": "电话号码",
  "company": "餐厅/公司名称",
  "company_name": "公司名称",
  "address": "地址",
  "city": "城市",
  "country": "国家",
  "provide_phonenumber": "请输入您的电话号码",
  "provide_company": "请输入您的餐厅和/或公司名称",
  "provide_address": "请输入您的地址",
  "provide_city": "请输入您的城市",
  "provide_country": "请输入您的国家",
  "manufacturer": "制造商",
  "for_store": "适用于商店",
  "are_you_sure_you_want_to_delete_the_store": "您确定要删除商店 <b>{0}</b> 吗？",
  "stock_movement": "库存移动",
  "from_store": "来源商店",
  "to_store": "目标商店",
  "_from_store": "来源",
  "_to_store": "目标",
  "moved_quantity": "转移数量",
  "view": "查看",
  "list": "列表",
  "calendar": "日历",
  "thanks_for_buying": "感谢购买",
  "sale": "销售",
  "add_room": "添加房间",
  "room_editor": "房间",
  "seats": "座位",
  "room_tables": "{0} 张桌子",
  "next": "下一步",
  "back": "返回",
  "room_tables_seats": "{0} 张桌子，{1} 个座位",
  "book_a_table": {
    "title": "预定桌子",
    "number_of_seats": "{0} 个座位",
    "seats": "座位",
    "tables": "桌子",
    "room": "房间",
    "rooms": "房间",
    "provide_email": "电子邮件",
    "provide_name_or_email": "输入客人姓名或电子邮件",
    "provide_phone": "电话号码",
    "provide_name": "姓名",
    "provide_comment": "评论",
    "not_enough_free_tables": "没有足够的空桌子！",
    "not_free_table": "没有空桌子！"
  },
  "activate_booking": "激活预订",
  "cancel_booking": "取消预订",
  "cancel_b": "取消",
  "extensions": "扩展",
  "table_extentions_placeholder": "+table_number=total_seats_count 例如 +4=8,+4+5=12",
  "quantity_must_be_unique": "可用数量必须唯一！",
  "menus": {
    "tableOverview": "桌面概览",
    "bar": "酒吧",
    "serving": "服务",
    "productionLines": "生产线",
    "reports": "报告",
    "stockManagement": "库存管理",
    "administration": "管理",
    "restaurants": "餐厅",
    "users": "用户",
    "currencies": "货币",
    "paymentMethods": "支付方式",
    "manage_reports": "管理报告",
    "monitoring": "监控",
    "drinks": "饮品",
    "meals": "餐点",
    "tables": "桌子",
    "VATCategories": "增值税类别",
    "printers": "打印机",
    "servings": "份数",
    "discounts": "折扣",
    "labels": "标签",
    "profile": "我的个人资料",
    "stockList": "库存管理",
    "shoppingList": "购物清单",
    "incomingStocks": "进货",
    "stockMovements": "库存流动",
    "inventory": "库存",
    "inventoryScrapping": "库存报废",
    "suppliers": "供应商",
    "stores": "商店",
    "superAdminMenu": "超级管理员",
    "pointOfSales": "销售点",
    "tableBooking": "桌面预订",
    "stockReport": "库存报告",
    "orders": "订单",
    "orders_quick_action": "设置订单状态",
    "cashRegisters": "收银机",
    "bankTerminals": "银行终端",
    "scales": "秤",
    "daily_closing": "日结",
    "support": "支持",
    "updateSettings": "更新设置",
    "barcodes": "条形码",
    "wolt": "Wolt",
    "foodpanda": "FoodPanda",
    "falatozz": "Falatozz",
    "wolt_administration": "Wolt 管理",
    "advertisements": "广告",
    "products": "产品",
    "menuitems": "菜单项",
    "traffic": "流量",
    "todaytraffic": "今日流量",
    "comments": "评论",
    "analytics": "分析",
    "plans": "计划",
    "opening_hours": "营业时间",
    "bi": "商业智能",
    "mautic": "CRM",
    "send_reseller_fees": "发送分销商费用",
    "send_reseller_fees_to_me": "只发送分销商费用给我",
    "check_subscriptions": "检查订阅",
    "waiterScreen": "服务员屏幕",
    "guestScreen": "客人屏幕",
    "voipphone": "VOIP 电话"
  },
  "cashRegister": "收银机",
  "stable_versions": "稳定版本",
  "test_versions": "测试版本",
  "status": "状态",
  "test": "测试",
  "Grid": {
    "Pager": {
      "Button": {
        "backButtonText": "返回",
        "nextButtonText": "下一步"
      },
      "Description": {
        "no_records": "没有可用的 %{recordType}",
        "records_displayed": "%{firstIndex} 到 %{lastIndex} 共 %{total} %{recordType} 显示"
      }
    }
  },
  "map": "地图",
  "basic_data": "基本数据",
  "restaurant_settings": {
    "default_language": "默认语言"
  },
  "show_deleted": "显示已删除项目",
  "image": "图片",
  "select_a_product": "选择一个产品",
  "select_a_category": "选择一个类别",
  "select_a_quantity_type": "选择一个数量类型",
  "select_a_vat_category": "选择一个增值税类别",
  "select_a_store": "选择一个商店",
  "select_a_supplier": "选择一个供应商",
  "source_and_target_store_should_be_different": "源商店和目标商店应不同",
  "custom_range": "自定义范围",
  "initial_stock": "起始库存",
  "final_stock": "结束库存",
  "outgoing_stock": "库存流动",
  "scrapping": "报废",
  "shortage": "短缺",
  "surplus": "过剩",
  "minStock": "最小库存",
  "maxStock": "最大库存",
  "total_stock_cost": "库存购买价值",
  "total_stock_value": "库存销售价值",
  "initialCost": "起始成本",
  "finalCost": "结束成本",
  "averageCost": "平均成本",
  "initialPrice": "起始价格",
  "averagePrice": "平均价格",
  "finalPrice": "结束价格",
  "minPrice": "最小价格",
  "maxPrice": "最大价格",
  "netto_income": "净收入",
  "vat": "增值税",
  "brutto_income": "总收入",
  "customer_details": "客户详细信息",
  "tomorrow": "明天",
  "available": "可用",
  "_available": "A",
  "order_summary": "订单摘要",
  "product_serving": "类型",
  "download": "下载",
  "upload": "上传",
  "order_no": "订单号",
  "no_matches_found": "没有找到匹配项",
  "could_not_find_this_order": "订单号 {0} 未找到!",
  "could_not_find_this_order2": "订单未找到!",
  "open": "打开",
  "fix_quantity": "固定数量",
  "are_you_sure_you_want_to_delete_the_printer": "您确定要删除打印机 <b>{0}</b> 吗?",
  "are_you_sure_you_want_to_delete_the_quantity_type": "您确定要删除服务 <b>{0}</b> 吗?",
  "are_you_sure_you_want_to_delete_the_vat_category": "您确定要删除增值税类别 <b>{0}</b> 吗?",
  "are_you_sure_you_want_to_delete_the_discount_type": "您确定要删除折扣类型 <b>{0}</b> 吗?",
  "are_you_sure_you_want_to_delete_the_cashregister": "您确定要删除收银机 <b>{0}</b> 吗?",
  "are_you_sure_you_want_to_delete": "您确定要删除",
  "connection": "连接",
  "type": "类型",
  "delete_order_history": "删除所有历史订单",
  "ready_for_delivery": "准备送餐",
  "table_ready_for_delivery": "!ESC|bC!ESC|4C桌号 {0}!LF!ESC|bC!ESC|4C订单号 {1}!LF!ESC|bC!ESC|4C{2}!LF!ESC|bC!ESC|4C送餐!",
  "proportional_addition_tooltip": "按比例增加。实际增加量将是基础项目的选定数量与附加项的数量的乘积。",
  "you_are_not_authorized_to_access_this_restaurant_with_an_app": "根据您的订阅，您无法访问此单位。",
  "you_are_not_authorized_to_access_this_feature": "根据您的订阅，您无法访问此功能。",
  "barcode": "条形码",
  "test_scale": "测试秤",
  "tare_weight": "皮重",
  "gross_weight": "毛重",
  "full_inventory": "完整库存",
  "all_items": "所有",
  "new": "新增",
  "parent_category": "父类别",
  "choose_parent_category": "选择父类别",
  "save_my_details_for_later_use": "我同意我的个人数据由 {0} 和云餐厅管理系统 EatWithMe 存储和管理。",
  "saved_my_details_for_later_use": "我同意我的个人数据由 {0} 和云餐厅管理系统 EatWithMe 存储和管理。",
  "agree": "提供我的同意",
  "accept_homedelivery_terms": "我同意家庭送货条款",
  "accept_pickup_terms": "我同意自取条款",
  "accept_privacy_terms": "我接受隐私政策",
  "privacy_policy": "隐私政策",
  "privacy_policy_link": "https://eatwithme.online/privacy-policy/",
  "door": "楼层/门",
  "backup": "备份",
  "withdraw": "撤回",
  "tax_number": "税号",
  "tax_number_pattern": "(^$)|(^\\d\\d\\d\\d\\d\\d\\d\\d-\\d-\\d\\d$)",
  "email": "电子邮件",
  "postcode": "邮政编码",
  "postcode_pattern": "^\\d\\d\\d\\d$",
  "not_empty_pattern": "\\w+",
  "email_pattern": "[^@]+@[^@]+(\\.[^@]+)+",
  "address_pattern": "^.+ (street|square|park) \\d+\\.?$",
  "address_pattern2": "[^,].+ (hrsz|arok|atjaro|dulo|dulout|erdosor|fasor|fordulo|gat|hatarsor|határút|kapu|körönd|körtér|körút|köz|lakótelep|lejáró|lejtő|lépcső|liget|mélyút|orom|ösvény|park|part|pincesor|rakpart|sétány|sikátor|sor|sugárút|tér|udvar|ut|utca|üdülőpart)",
  "address_placeholder": "格式：名称 (街道|广场|等) 编号。",
  "repay_invoice_dialog": "您是否希望修改支付详情?",
  "except": "除外",
  "first_modify_start_date": "首先更改开始时间",
  "uncategorized_items": "没有设置类别的产品",
  "wolt": {
    "reload": "从 Wolt 刷新",
    "upload_selected_elements": "上传选定的产品到 Wolt",
    "resync_selected_elements": "重新同步选定的产品到 Wolt",
    "delete_selected_elements": "从 Wolt 删除选定的产品",
    "elements_in_sync": "同步中的产品数量",
    "elements_in_unsync": "未同步的产品数量",
    "elements_in_to_upload": "缺少的产品数量",
    "elements_in_to_delete": "Wolt 上的多余产品数量",
    "delete_menu_items": "从 Wolt 删除产品",
    "delete_category": "从 Wolt 删除产品类别",
    "delete_category_items": "删除仅在此类别下存在的 Wolt 产品",
    "sync_category_items": "重新同步在 EatWithMe 和 Wolt 中均已存在的产品",
    "upload_category_items": "上传缺少的产品到 Wolt",
    "sync_menu_item": "同步产品到 Wolt",
    "delete_menu_item": "从 Wolt 删除产品",
    "upload_menu_item": "上传产品到 Wolt",
    "upload_category": "上传产品类别到 Wolt"
  },
  "vat_invoice": "增值税发票",
  "there_already_exists_a_table_with_this_number": "您已经放置了这个桌号!",
  "access_tokens": "访问令牌",
  "generate_access_token": "生成访问令牌",
  "printer_server": "打印机服务器",
  "purchase_price": "净购买价格",
  "selling_price": "毛销售价格",
  "unit_of_consumption": "销售/使用单位",
  "picture": "图片",
  "homedelivery": "家庭送货",
  "pickup": "自取",
  "onsite": "现场消费",
  "by_occupation_start": "按订单时间",
  "by_payment": "按支付时间",
  "are_you_sure_you_cancel_the_payment": "您确定要取消支付详情注册吗?",
  "value_difference": "价值差异",
  "total_value_difference": "总价值差异",
  "subscription": "订阅",
  "print": "打印",
  "something_strange_happened_resync": "发生了意外情况! 正在重新同步...",
  "payment_due_in": "{0} 天内到期的付款!",
  "payment_was_due_in": "{0} 天前付款到期!",
  "continue_in": "{0} 分钟后此警告将关闭，请等待...",
  "profitandloss": {
    "open_stock": "开盘库存",
    "cost": "成本",
    "purchase": "购买",
    "shortage": "短缺/过剩",
    "scrapping": "报废",
    "closing_stock": "结算库存",
    "stock_change": "库存变动",
    "income": "收入",
    "profit": "利润",
    "profitp": "利润 (%)",
    "count": "销售数量",
    "consumption": "销售数量",
    "minPrice": "最低价格",
    "maxPrice": "最高价格",
    "avgPrice": "平均列表价格",
    "avgDiscount": "平均折扣",
    "avgActualPrice": "平均价格",
    "minCost": "最低成本",
    "maxCost": "最高成本",
    "avgCost": "平均成本"
  },
  "service_fee": "服务费",
  "service_fee_net": "净服务费",
  "fiscat_service_fee": "服务费",
  "income_without_cost": "不包含成本的收入?",
  "not_used_ingredient": "未使用的原料",
  "disconnected": "未连接",
  "error": "连接错误",
  "cover-open": "封面打开",
  "out-of-paper": "纸用完了",
  "refresh_my_data": "更新我的数据",
  "skin_picker": "主题选择器",
  "skins": [
    { "id": "default", "name": "默认" },
    { "id": "fancy", "name": "花哨" },
    { "id": "flat", "name": "平面" },
    { "id": "night", "name": "夜间" },
    { "id": "old", "name": "老式" }
  ],
  "draft": "草稿",
  "detailed": "详细账单",
  "service_fee_vat": "分离的服务费",
  "too_many_bluetooth_cashiers": "一次只能配对一个蓝牙收银机！",
  "no_bluetooth_cashiers": "请连接或配对一个蓝牙收银机！",
  "no_bluetooth_adapter": "蓝牙接口不可用！",
  "no_serial_cashiers": "请连接一个带有 USB-串口的收银机！",
  "locked": "已锁定",
  "addlogin": "添加额外用户登录",
  "syncronize_barcodes": "同步",
  "download_barcodes": "下载",
  "upload_barcodes": "上传",
  "local_product": "关联产品",
  "name_of_customer": "设置顾客姓名",
  "product_modified_save_it": "已修改的产品！",
  "drop": "丢弃",
  "opened": "打开",
  "opening_time": "开门时间",
  "planned_closing_time": "计划关闭时间",
  "open_day": "营业日",
  "continue_without_open_day": "继续无营业日",
  "timetable": "营业时间",
  "closed": "已关闭",
  "failed_to_send_order": "发送订单失败：{0}",
  "resend": "重试",
  "closed": "已关闭",
  "opened": "已打开",
  "local_addition_tooltip": "适用于本地消费",
  "takeaway_addition_tooltip": "适用于外卖或送货上门",
  "top_category": "顶级分类",
  "_top_category": "顶级类",
  "takeaway": "外卖",
  "cashier": "收银员",
  "download_template": "上传模板",
  "user_missing": "未注册该电子邮件地址的用户",
  "revert_table_occupation": "您确定要恢复吗？",
  "revert_table_occupation_multi_payment": "您确定要恢复吗？有多个付款人，支付可以在支付选项卡上恢复！",
  "revert": "恢复",
  "revert_table_mate_payment": "也恢复支付",
  "revert_payment": "您确定要恢复支付吗？",
  "no_inventory_quantity_selected": "库存项但未选择库存数量！",
  "multiple_inventory_quantity_selected": "您确定要设置多个单独的库存数量吗！",
  "preorder_possible": "预订",
  "allowed_installments": {
    "start": "预付款",
    "by_each": "每日",
    "end": "结束时"
  },
  "installments_strategy": "付款计划",
  "payer": "付款人",
  "couriers": "快递员",
  "courier": "快递员",
  "access_denied": "访问被拒绝：{0}",
  "days": ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
  "do_you_realy_want_to_order_so_many": "您确定要订购<strong>{0}</strong>件吗？",
  "you_cannot_order_so_many": "您不能一次性订购<strong>{0}</strong>件！",
  "day_must_be_open": "首先开启营业日才能进行此操作！",
  "ntak": {
    "ntak": "NTAK",
    "taxid": "税号",
    "ntak_id": "NTAK ID",
    "save_ntak_id": "保存 NTAK ID",
    "cert_request_generate": "生成新的证书请求",
    "cert_request_download": "下载证书请求",
    "cert_upload": "上传证书",
    "cert_request": "证书请求（CSR）",
    "test": "测试连接",
    "cert": "证书（CER）",
    "key": "私钥（KEY）",
    "categories": "NTAK",
    "status": "NTAK 状态",
    "status_error": "NTAK 错误",
    "default_ntak_main_category": "NTAK 主分类",
    "_default_ntak_main_category": "主分类",
    "default_ntak_sub_category": "NTAK 子分类",
    "_default_ntak_sub_category": "子分类",
    "mainCategoryNotSet": "没有为任何产品设置 NTAK 主分类！它可以在类别级别设置（将继承），也可以在产品级别设置。",
    "subCategoryNotSet": "没有为任何产品设置 NTAK 子分类！它可以在类别级别设置（将继承），也可以在产品级别设置。",
    "main_categories": [
      {
        "id": 5, "name": "食品", "shortName": "食品",
        "sub_categories": [
          { "id": 1, "name": "早餐" },
          { "id": 2, "name": "三明治" },
          { "id": 3, "name": "开胃菜" },
          { "id": 4, "name": "汤" },
          { "id": 5, "name": "主菜" },
          { "id": 6, "name": "配菜" },
          { "id": 7, "name": "泡菜/沙拉", "shortName": "泡菜/沙拉" },
          { "id": 8, "name": "甜点" },
          { "id": 9, "name": "小吃" },
          { "id": 30, "name": "主菜配配菜" },
          { "id": 31, "name": "食品套餐" },
          { "id": 10, "name": "其他" }
        ]
      },
      {
        "id": 1, "name": "本地制作的非酒精饮料", "shortName": "本地饮料",
        "sub_categories": [
          { "id": 11, "name": "柠檬水/糖浆/现榨饮料", "shortName": "柠檬水/糖浆/现榨" },
          { "id": 12, "name": "非酒精鸡尾酒", "shortName": "非酒精鸡尾酒" },
          { "id": 13, "name": "茶/热巧克力" },
          { "id": 14, "name": "咖啡" },
          { "id": 32, "name": "饮料套餐" }
        ]
      },
      {
        "id": 2, "name": "非本地制作的非酒精饮料", "shortName": "非本地饮料",
        "sub_categories": [
          { "id": 15, "name": "水" },
          { "id": 16, "name": "碳酸软饮" },
          { "id": 17, "name": "非碳酸软饮" },
          { "id": 33, "name": "非碳酸软饮" },
          { "id": 32, "name": "饮料套餐" }
        ]
      },
      {
        "id": 3, "name": "酒精饮料", "shortName": "酒精饮料",
        "sub_categories": [
          { "id": 18, "name": "鸡尾酒" },
          { "id": 19, "name": "利口酒" },
          { "id": 20, "name": "烈酒" },
          { "id": 21, "name": "啤酒" },
          { "id": 22, "name": "葡萄酒" },
          { "id": 23, "name": "香槟" },
          { "id": 32, "name": "饮料套餐" }
        ]
      },
      {
        "id": 4, "name": "其他", "shortName": "其他",
        "sub_categories": [
          { "id": 10, "name": "其他" },
          { "id": 26, "name": "送货费" },
          { "id": 27, "name": "环保包装", "shortName": "环保包装" },
          { "id": 28, "name": "塑料包装", "shortName": "塑料包装" },
          { "id": 29, "name": "折扣" },
          { "id": 34, "name": "非餐饮" }
        ]
      }
    ]
  },
  "more_addresses": "其他地址",
  "payment_received_by": "付款接收者",
  "product_receipe_modified_recalculate_it": "配方已更改。您是否想重新计算过去的库存",
  "minute": "分钟",
  "id": "内部编号",
  "activate_sound": "铃声已激活",
  "barcode_not_found": "未知的条形码！",
  "linked_product": "链接的自有产品",
  "new_in_central_database": "来自全球数据库",
  "exists_in_central_database": "存在于全球数据库",
  "doesnt_exists_in_central_database": "不存在于全球数据库",
  "local_value": "本地值",
  "global_value": "全球值",
  "tooltip": {
    "set_new_incoming_order": "新订单待确认！",
    "set_accepted_order": "订单已确认！您可以通过双击再次确认订单！",
    "set_order_in_production": "订单准备中！您可以通过双击设置此状态！",
    "set_order_ready": "订单准备好！您可以通过双击设置此状态！",
    "set_order_under_delivering": "订单配送中！您可以通过双击设置此状态！",
    "set_order_delivered": "订单已交付！您可以通过双击设置此状态！",
    "header_new_incoming_order": "新订单待确认！",
    "header_accepted_order": "订单已确认！您可以通过双击重新确认所有待处理或选中的订单！",
    "header_order_in_production": "订单准备中！您可以通过双击设置此状态应用到所有订单或选中的订单！",
    "header_order_ready": "订单准备好！您可以通过双击设置此状态应用到所有订单或选中的订单！",
    "header_order_under_delivering": "订单配送中！您可以通过双击设置此状态应用到所有订单或选中的订单！",
    "header_order_delivered": "订单已交付！您可以通过双击设置此状态应用到所有订单或选中的订单！",
    "header_filter_new_incoming_order": "筛选新订单",
    "header_filter_accepted_order": "筛选已确认的订单",
    "header_filter_order_in_production": "筛选准备中的订单",
    "header_filter_order_ready": "筛选已准备好的订单",
    "header_filter_order_assigned": "已分配给快递员的订单",
    "header_filter_order_under_delivering": "筛选配送中的订单",
    "header_filter_order_delivered": "筛选已交付的订单",
    "unlink_courier": "双击从快递员处移除订单！",
    "link_courier": "双击将选中的订单分配给此快递员！",
    "header_print_receipt": "双击打印所有未打印的订单或选中的订单！",
    "header_print_invoice": "双击打印所有或选中订单的发票！",
    "header_unlock": "双击解锁或锁定所有可解锁（已交付并付款）订单！",
    "print_receipt": "双击打印订单！",
    "reprint_receipt": "双击重新打印订单！",
    "print_invoice": "双击打印发票！",
    "unlock": "双击解锁或锁定订单！",
    "delivery_time": "计划交付时间",
    "pickup_time": "计划取件时间",
    "couriers": "可能的快递员（拥有快递员权限的用户）。此权限可以在管理系统中授予已注册的用户。",
    "printing": "打印中",
    "printfailed": "打印尝试失败。双击重新打印。",
    "printed": "打印成功！双击打印副本。",
    "unprinted": "订单尚未打印。双击打印。",
    "qrcode": "双击打印快递员的区块。",
    "register_payment": "双击注册付款详情。",
    "paid": "发票已支付。",
    "user_comment": "为您的客户设置永久性评论。此评论将在下次订单中显示！",
    "comment": "仅为此订单设置评论！",
    "address": "请提供完整地址，例如：'Árpád köz 2.' 如果系统无法找到地址，系统将接受任何以 '/' 开头的地址，例如：'/在火车站入口处'"
  },
  "tablesoverview": {
    "tooltip": {
      "printing": "打印中",
      "printfailed": "打印尝试失败。请重新打印。",
      "printed": "打印成功。",
      "unprinted": "订单尚未打印。"
    }
  },
  "menuitems": {
    "tooltip": {
      "expected_profit": "预期利润率",
      "suggested_price": "建议价格",
      "unit_price": "本地消费单价",
      "is_inventory_item": "在销售过程中不能选择作为包装选项。此单元是库存管理的基础。可以以此单元接收并库存。",
      "non_inventory_item": "在销售过程中不能选择作为包装选项。普通产品数量。不是库存单元。",
      "on_menu_is_inventory_item": "可以在销售过程中选择作为包装选项。此单元是库存管理的基础。可以以此单元接收并库存。",
      "on_menu_non_inventory_item": "可以在销售过程中选择作为包装选项。普通产品数量。不是库存单元。",
      "def_is_inventory_item": "默认但不能选择作为包装选项。此设置不佳。请选择默认可销售包装！此单元是库存管理的基础。可以以此单元接收并库存。",
      "def_non_inventory_item": "默认但不能选择作为包装选项。此设置不佳。请选择默认可销售包装！普通产品数量。不是库存单元。",
      "def_on_menu_is_inventory_item": "默认并可以在销售过程中选择作为包装选项。此单元是库存管理的基础。可以以此单元接收并库存。",
      "def_on_menu_non_inventory_item": "默认并可以在销售过程中选择作为包装选项。普通产品数量。不是库存单元。",
      "unit_cost": "净购进价",
      "profit_ratio": "利润率",
      "on_menu": "主产品，可直接下单",
      "not_on_menu": "非主产品，不能直接下单，仅作为其他产品的选项",
      "active": "激活的产品",
      "deactivated": "已停用的产品",
      "online": "产品在在线商店可见",
      "not_online": "产品在在线商店不可见",
      "available_online": "产品可在线订购",
      "not_available_online": "产品当前不可在线订购",
      "default_inventory_item": "库存管理的产品（原材料）。从其类别继承此属性。",
      "default_not_inventory_item": "非库存管理的产品。从其类别继承此属性。",
      "inventory_item": "库存管理的产品（原材料）",
      "not_inventory_item": "非库存管理的产品（原材料）",
      "not_free_quantity": "在订购时只能选择预定义的包装选项（数量）。",
      "free_quantity": "在订购时，售出的数量可以自由修改（例如，称重商品时需要）。",
      "sold_inventory": "售出库存管理的成品，即售出的产品也是已库存的原材料。",
      "sold_inventory_ingredient": "售出库存管理的成品，即售出的产品也是已库存的原材料，且为另一产品的原料。",
      "sold_inventory_ingredient_option": "售出库存管理的成品，即售出的产品也是已库存的原材料，且为另一产品的原料，并作为选项。",
      "sold_inventory_option": "售出库存管理的成品，即售出的产品也是已库存的原材料，且为另一产品的选项。",
      "sold_service": "售出服务，产品未库存（非原材料），且不包含其他产品（空配方），因此为服务。",
      "sold_service_ingredient": "售出服务子产品，产品未库存（非原材料），且不包含其他产品（空配方），因此为服务，但在其他产品的配方中有所引用。",
      "sold_service_ingredient_option": "售出服务原料选项，即产品本身未库存（非原料），且不包含其他产品（空配方），因此为服务，且其他产品的配方中引用了它作为选项。",
      "sold_service_option": "售出服务选项，即产品本身未库存（非原料），且不包含其他产品（空配方），因此为服务，且其他产品引用它作为选项。",
      "sold_complex_product": "售出复杂产品，由其他产品组成",
      "sold_complex_product_option": "售出复杂产品，由其他产品组成，并作为另一个产品的选项",
      "sold_complex_product_ingredient": "售出复杂子产品，由其他产品组成",
      "sold_complex_product_ingredient_option": "售出复杂子产品和选项",
      "sold_linked_product": "售出链接产品",
      "sold_linked_product_ingredient": "售出链接产品原料",
      "sold_linked_product_ingredient_option": "售出引用产品，子产品，选项，即它仅引用一个产品",
      "sold_linked_product_option": "售出引用产品，选项，即它仅引用一个产品",
      "_complex_product": "未直接售出的复杂产品，由其他产品组成",
      "_complex_product_option": "未直接售出的复杂产品，由其他产品组成，并作为另一个产品的选项",
      "_complex_product_ingredient": "未直接售出的复杂产品，由其他产品组成，并在其他产品的配方中引用",
      "_complex_product_ingredient_option": "未直接售出的复杂产品，由其他产品组成，并在其他产品的配方中引用，并作为选项",
      "_linked_product": "未直接售出的引用产品（价格不同、特殊数量或仅是另一产品的副本）",
      "_linked_product_option": "未直接售出的引用产品（价格不同、特殊数量或仅是另一产品的副本），并作为另一个产品的选项",
      "_linked_product_ingredient": "未直接售出的引用产品（价格不同、特殊数量或仅是另一产品的副本），并包含在另一产品的配方中",
      "_linked_product_ingredient_option": "未直接售出的引用产品（价格不同、特殊数量或仅是另一产品的副本），并在另一产品的配方中引用，并作为选项",
      "_service_ingredient": "未直接售出的服务，已在另一产品的配方中引用",
      "_service_ingredient_option": "未直接售出的服务，已在另一产品的配方中引用，并作为选项",
      "_service__option": "未直接售出的服务，已在另一产品中作为选项引用",
      "_inventory_ingredient": "未直接售出的库存原料，已在另一产品的配方中引用",
      "_inventory_ingredient_option": "未直接售出的库存原料，已在另一产品的配方中引用，并作为选项",
      "_inventory_option": "未直接售出的库存原料，已在另一产品中作为选项引用",
      "_inventory": "未直接售出的库存原料，未被其他产品使用/引用",
      "_service": "未售出的服务，未被其他产品引用为配方或选项",
      "no_inventory_quantity_selected": "产品是库存单元，但未选择库存数量。",
      "to_many_inventory_quantity_selected": "产品有多个库存数量，因此系统无法确定应从哪个数量中减少库存。请仅选择一个库存数量。"
    },
    "sold_inventory": "售出库存产品",
    "sold_inventory_ingredient": "售出库存产品原料",
    "sold_inventory_ingredient_option": "售出库存产品原料选项",
    "sold_inventory_option": "售出库存产品选项",
    "sold_service": "售出服务",
    "sold_service_ingredient": "售出服务子产品",
    "sold_service_ingredient_option": "售出服务子产品及选项",
    "sold_service_option": "售出服务选项",
    "sold_linked_product": "售出链接产品",
    "sold_linked_product_ingredient": "售出引用库存产品原料",
    "sold_linked_product_ingredient_option": "售出引用库存产品原料选项",
    "sold_complex_product": "售出复杂产品",
    "sold_complex_product_option": "售出复杂产品及选项",
    "sold_complex_product_ingredient": "售出复杂子产品",
    "sold_complex_product_ingredient_option": "售出复杂子产品及选项",
    "_complex_product": "未售出复杂产品",
    "_complex_product_option": "未售出复杂产品及选项",
    "_complex_product_ingredient": "未售出复杂产品子产品",
    "_complex_product_ingredient_option": "未售出复杂产品子产品及选项",
    "_linked_product": "未售出引用产品",
    "_linked_product_option": "未售出引用产品及选项",
    "_linked_product_ingredient": "未售出引用产品子产品",
    "_linked_product_ingredient_option": "未售出引用产品子产品及选项",
    "_service_ingredient": "未售出服务子产品",
    "_service_ingredient_option": "未售出服务子产品及选项",
    "_service_option": "未售出服务选项",
    "_inventory": "未售出库存未使用原料",
    "_inventory_ingredient": "未售出库存原料",
    "_inventory_ingredient_option": "未售出库存原料及选项",
    "_inventory_option": "未售出库存选项",
    "_service": "未售出服务"
  },
  "_settings": {
    "hu": "匈牙利语",
    "en": "英语",
    "states": ["已删除", "已选择", "已下单", "已确认", "进行中", "准备中", "配送中", "已交付"],
    "day": "天",
    "fromday": "起始日期",
    "today": "结束日期",
    "minute": "分钟",
    "days": ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日", "日期"],
    "from_hour": "起始小时",
    "to_hour": "结束小时",
    "email": "电子邮件地址",
    "payment_method": {
      "title": "可用支付方式",
      "meth": {
        "title": "方式"
      },
      "term": {
        "title": "支付条款",
        "description": "网页商店的支付条款"
      }
    },
    "description_header": {
      "title": "网页商店头部内容"
    },
    "description_footer": {
      "title": "网页商店底部内容"
    },
    "business_day_starts_at": {
      "title": "营业日开始",
      "description": "以小时计"
    },
    "business_day_ends_at": {
      "title": "营业日结束",
      "description": "以小时计，可能少于开始时间，例如，营业日结束时间为凌晨五点"
    },
    "service_fee": {
      "title": "服务费",
      "description": "例如，如果通常应用12%的服务费，填写12。服务费的应用和费率可在发票打印时设置或调整。"
    },
    "timezone": {
      "title": "时区"
    },
    "compulsory_service_fee": {
      "title": "强制服务费",
      "description": "对于非现金收银台，自动应用服务费，可在打印发票前进行调整。"
    },
    "compulsory_service_fee_for_pos": {
      "title": "收银机强制服务费",
      "description": "对于收银机台，自动应用服务费。"
    },
    "serviceVat": {
      "title": "分开服务费",
      "description": "在发票/收据上按增值税类别分开显示服务费。"
    },
    "register_tip": {
      "title": "注册小费",
      "description": "如果支付金额超过应付金额，注册差额作为小费。"
    },
    "printing": {
      "title": "打印设置",
      "invoice": {
        "title": "发票布局"
      },
      "showOrderNumber": {
        "title": "在常规桌子上显示订单号",
        "description": "默认情况下适用于现金订单。"
      },
      "showAllComments": {
        "title": "在发票上显示所有评论",
        "description": "默认情况下，仅显示修改成本的评论。"
      }
    },
    "drinks": {
      "title": "设置: 饮料",
      "enabled": {
        "title": "启用"
      },
      "default": {
        "title": "默认",
        "description": "服务员和网页商店点餐时的默认饮料或餐点显示。"
      },
      "label": {
        "title": "标签",
        "description": "在网页商店上显示的标签。"
      }
    },
    "meals": {
      "title": "设置: 餐点",
      "enabled": {
        "title": "启用"
      },
      "default": {
        "title": "默认",
        "description": "服务员和网页商店点餐时的默认饮料或餐点显示。"
      },
      "label": {
        "title": "标签",
        "description": "在网页商店上显示的标签。"
      }
    }
  },
  "menu": {
    "title": "点餐界面，产品选择器",
    "show_category_image": {
      "title": "显示产品类别图片"
    },
    "show_menuitem_image": {
      "title": "显示产品图片"
    }
  },
  "takeaway": {
    "title": "外卖",
    "enabled": {
      "title": "启用"
    },
    "default": {
      "title": "默认",
      "description": "启用收银台的自动外卖"
    },
    "vat": {
      "title": "外卖默认增值税"
    },
    "price": {
      "title": "单独外卖价格",
      "description": "在产品页面上指定额外的外卖价格选项。"
    },
    "containerChargeItem": {
      "title": "押金产品标识符"
    },
    "packageChargeItem": {
      "title": "包装产品标识符"
    },
    "systemFeeItem": {
      "title": "系统使用费产品标识符"
    }
  },
  "timings": {
    "title": "课程",
    "enabled": {
      "title": "启用"
    },
    "enabled_online": {
      "title": "启用网页商店的现场订单"
    },
    "langs": {
      "title": "语言标签",
      "lang": {
        "title": "语言"
      },
      "name": {
        "title": "标签"
      },
      "name_online": {
        "title": "网页商店上的标签"
      }
    }
  },
  "price_calculations": {
    "title": "价格计算逻辑",
    "rounding": {
      "title": "四舍五入",
      "description": "例如，1 -> 四舍五入到最接近的整数，50 -> 四舍五入到50或100。"
    },
    "lower_price": {
      "title": "降价逻辑",
      "description": "默认的组件/选项可以在下单时被移除/禁用或替换。",
      "above": {
        "title": "降价条件",
        "description": "如果新价格比原价低了指定的百分比或金额，则降价。举例来说，100%意味着任何降价都会生效。"
      },
      "measure": {
        "title": "计量类型"
      }
    },
    "increase_price": {
      "title": "涨价逻辑",
      "above": {
        "title": "涨价条件",
        "description": "如果新价格比原价高了指定的百分比或金额，则涨价。举例来说，0%意味着任何涨价都会生效。"
      },
      "measure": {
        "title": "计量类型"
      }
    }
  },
  "enabled_features": {
    "homedelivery": {
      "zip": {
        "title": "邮政编码",
        "description": "系统接受哪些邮政编码（正则表达式）"
      },
      "dayends": {
        "title": "日终时间",
        "description": "在网页商店中，最多可以到什么时间下单以便当天送达"
      },
      "timeahead": {
        "title": "提前时间",
        "description": "对于手动或网页商店订单，您最多提前多少小时保证送达"
      },
      "min-duration": {
        "title": "送达时间段持续时间",
        "description": "送达时间段的持续时间（以小时为单位）"
      },
      "homedelivery_menuitem": {
        "title": "首页配送费用的产品ID",
        "description": "在应用邮政编码基础的送货费时，系统使用哪个产品。如果该产品尚未存在，请创建一个名为“家庭送货费”的新产品，保存后重新打开编辑，记下其ID并在此处输入。"
      },
      "phone_order_fee": {
        "title": "系统使用费",
        "description": "电话订单的系统使用费"
      },
      "webshop_order_fee": {
        "title": "系统使用费",
        "description": "网页商店订单的系统使用费"
      },
      "instantOrderOnly": {
        "title": "仅限即时订单",
        "description": "仅接受网页商店中的即时订单"
      },
      "copy_order_email": {
        "title": "电子邮件通知",
        "description": "谁应该收到有关网页商店订单和状态变更的电子邮件通知"
      },
      "send_email_on": {
        "title": "状态变更时发送电子邮件",
        "description": "当网页商店订单达到哪个状态时，向上述地址发送电子邮件通知"
      },
      "price": {
        "title": "送货费用",
        "description": "您可以选择根据邮政编码和订单金额自动收取送货费",
        "name": "名称",
        "zip": "邮政编码",
        "above_amount": "金额大于",
        "price": "送货费用",
        "except": {
          "title": "除外",
          "description": "排除带标签的产品"
        },
        "only": {
          "title": "仅限",
          "description": "仅限带标签的产品"
        },
        "area": {
          "title": "地图",
          "description": "在地图上绘制送货区域"
        }
      },
      "orderedStatus": {
        "title": "订单后状态",
        "description": "网页商店中订单商品的初始状态"
      },
      "available-timeslots": {
        "title": "送货时间段",
        "description": "这些时间段可供网页商店配送使用"
      },
      "terms-and-conditions": {
        "title": "通用条款与条件",
        "description": "在下单时向客户说明家庭配送条件"
      },
      "delays": {
        "title": "预计送达时间",
        "description": "为Wolt/Foodpanda确认的准备/送货时间（以分钟为单位）"
      },
      "blockWaiterClose": {
        "title": "阻止服务员关闭",
        "description": "只有管理员可以关闭家庭送货"
      },
      "printInvoiceOnOrder": {
        "title": "自动打印发票",
        "description": "新订单提交后自动打印发票"
      },
      "allowed_installments": {
        "title": "分期付款选项",
        "description": "菜单订单的分期付款选项",
        "start": "预付款",
        "by_each": "每日付款",
        "end": "最后付款"
      },
      "user_allowed_installments": {
        "title": "网页商店的分期付款选项",
        "description": "网页商店菜单订单的分期付款选项",
        "start": "预付款",
        "by_each": "每日付款",
        "end": "最后付款"
      },
      "table-number": {
        "title": "桌号",
        "description": "处理家庭送货订单时的桌号"
      }
    },
    "pickup": {
      "dayends": {
        "title": "日终时间",
        "description": "在网页商店中，最多可以到什么时间下单以便当天取餐"
      },
      "timeahead": {
        "title": "提前时间",
        "description": "对于手动或网页商店订单，您最多提前多少小时保证订单准备"
      },
      "min-duration": {
        "title": "取餐时间段持续时间",
        "description": "取餐时间段的持续时间（以小时为单位）"
      },
      "instantOrderOnly": {
        "title": "仅限即时订单",
        "description": "仅接受网页商店中的即时订单"
      },
      "phone_order_fee": {
        "title": "系统使用费",
        "description": "电话订单的系统使用费"
      },
      "webshop_order_fee": {
        "title": "系统使用费",
        "description": "网页商店订单的系统使用费"
      },
      "copy_order_email": {
        "title": "电子邮件通知",
        "description": "谁应该收到有关网页商店订单和状态变更的电子邮件通知"
      },
      "send_email_on": {
        "title": "状态变更时发送电子邮件",
        "description": "当网页商店订单达到哪个状态时，向上述地址发送电子邮件通知"
      },
      "orderedStatus": {
        "title": "订单后状态",
        "description": "网页商店中订单商品的初始状态"
      },
      "available-timeslots": {
        "title": "取餐时间段",
        "description": "这些时间段可供网页商店取餐使用"
      },
      "terms-and-conditions": {
        "title": "通用条款与条件",
        "description": "在下单时向客户说明取餐订单条件"
      },
      "delays": {
        "title": "预计取餐时间",
        "description": "为Wolt/Foodpanda确认的准备时间（以分钟为单位）"
      },
      "allowed_installments": {
        "title": "分期付款选项",
        "description": "菜单订单的分期付款选项",
        "start": "预付款",
        "by_each": "每日付款",
        "end": "最后付款"
      },
      "user_allowed_installments": {
        "title": "网页商店的分期付款选项",
        "description": "网页商店菜单订单的分期付款选项",
        "start": "预付款",
        "by_each": "每日付款",
        "end": "最后付款"
      },
      "table-number": {
        "title": "桌号",
        "description": "处理取餐订单时的桌号"
      }
    },
    "prebooking": {
      "defaultDuration": {
        "title": "默认持续时间",
        "description": "预定时的默认持续时间（以分钟为单位）"
      },
      "bookingDelay": {
        "title": "预定提前时间",
        "description": "只能在当前时间之后的这段时间内进行预定"
      },
      "showBefore": {
        "title": "提前显示时间",
        "description": "在地面平面图上提前多少分钟显示预定"
      },
      "maxLateDuration": {
        "title": "允许的迟到时间",
        "description": "允许的宽限时间（以分钟为单位），超过此时间未到达的预定将被删除"
      },
      "minBookingMinutes": {
        "title": "最低预定时间",
        "description": "网页上预定的最短时间（以分钟为单位）"
      },
      "maxBookingMinutes": {
        "title": "最大预定时间",
        "description": "网页上预定的最长时间（以分钟为单位）"
      },
      "defaultBookingMinutes": {
        "title": "网页商店的默认持续时间",
        "description": "在网页商店上进行预定时的默认持续时间（以分钟为单位）"
      },
      "slotMinutes": {
        "title": "持续时间",
        "description": "这些持续时间可以在网页上选择"
      },
      "seats": {
        "title": "人数",
        "description": "这些人数可以在网页上选择"
      },
      "openingHours": {
        "title": "营业时间",
        "description": "网页上的营业时间",
        "slots": {
          "title": "时间段",
          "description": "营业时间的时间段（例如，从10:00到14:00）"
        }
      }
    },
    "tables-overview": {
      "autolock": {
        "title": "自动屏幕锁定",
        "description": "设置时间后，屏幕会锁定，并且只能通过密码或密码解锁。"
      },
      "timeout": {
        "title": "锁定延迟",
        "description": "屏幕应在多少秒后锁定"
      },
      "hideMenuCategoryImage": {
        "title": "隐藏类别图片",
        "description": "在点餐屏幕上隐藏类别图片"
      },
      "hideMenuItemImage": {
        "title": "隐藏产品图片",
        "description": "在点餐屏幕上隐藏产品图片"
      },
      "bigOrderNumber": {
        "title": "大号订单号",
        "description": "在厨房收据上打印比默认设置更大的订单号"
      },
      "perPersonKitchenReceipt": {
        "title": "每人分开的厨房收据",
        "description": "例如，当每个人的订单需要单独端送时"
      },
      "bigAdditions": {
        "title": "厨房收据上的大号字体",
        "description": "厨房收据上的大号+/-行"
      },
      "autoDeliverOnUnlock": {
        "title": "自动交付模式",
        "description": "释放桌子后，任何未删除的项目将自动设置为‘已交付’状态，并从库存中扣除"
      },
      "unlockOnPayment": {
        "title": "自动释放",
        "description": "如果没有未付款项，支付后自动释放桌子"
      },
      "lessKioskButtons": {
        "title": "现金注册模式中的按钮更少",
        "description": "在现金注册模式中显示最少的按钮"
      },
      "print_accepted_customer_orders": {
        "title": "确认后自动打印",
        "description": "在确认后自动打印网店订单"
      },
      "delayed_orders_browser": {
        "title": "来自浏览器的延迟订单提交",
        "description": "订单只有在确认后才会发送到服务器。"
      },
      "delayed_orders_mobile": {
        "title": "来自移动应用的延迟订单提交",
        "description": "订单只有在确认后才会发送到服务器。"
      },
      "pos_delayed_orders_browser": {
        "title": "POS模式下来自浏览器的延迟订单提交",
        "description": "订单只有在确认后才会发送到服务器。"
      },
      "pos_delayed_orders_mobile": {
        "title": "POS模式下来自移动应用的延迟订单提交",
        "description": "订单只有在确认后才会发送到服务器。"
      },
      "always_allow_delete": {
        "title": "允许删除",
        "description": "即使是服务员权限也允许删除"
      },
      "block_waiter_print_invoice": {
        "title": "阻止打印发票",
        "description": "阻止服务员打印发票"
      },
      "block_waiter_register_payment": {
        "title": "阻止登记支付",
        "description": "阻止服务员登记支付"
      },
      "block_waiter_print_daily_closing": {
        "title": "禁止快速流量数据",
        "description": "禁止服务员查询当前流量数据"
      },
      "waiter_print_daily_closing_full": {
        "title": "完整的快速流量数据",
        "description": "为服务员提供完整的当前流量数据"
      },
      "drinkOrderStatus": {
        "title": "点饮品时的订单状态",
        "description": ""
      },
      "mealOrderStatus": {
        "title": "点餐时的订单状态",
        "description": ""
      },
      "table-qrcode": {
        "title": "桌面打印二维码的功能",
        "description": "",
        "enumNames": ["无", "仅支付", "连接"]
      },
      "auto-table-qrcode": {
        "title": "自动打印二维码",
        "description": "随着第一笔订单的打印，自动打印二维码"
      },
      "notify-waiter-when-part-ready": {
        "title": "当部分菜品准备好时通知服务员",
        "description": "当一道菜在多个生产线准备时，即使不是所有生产线都准备好了，也要通知服务员"
      },
      "notify-customer-when-part-ready": {
        "title": "当部分菜品准备好时通知顾客",
        "description": "当一道菜在多个生产线准备时，即使不是所有生产线都准备好了，也要通知顾客"
      },
      "states": {
        "title": "筛选状态",
        "description": "考虑哪些订单状态"
      },
      "statesShown": {
        "title": "显示的状态",
        "description": "打开桌面时显示哪些状态（列）"
      },
      "newOrdersStatesShown": {
        "title": "新订单录入时显示的状态",
        "description": "新订单录入时显示哪些状态（列）"
      },
      "newOrdersKioskStatesShown": {
        "title": "现金注册模式下显示的状态",
        "description": "在现金注册模式下显示哪些状态（列）"
      }
    }
  },
  "receipts": "收据",
  "receipt": "收据",
  "save_update_address": "保存并更新地址",
  "sort_abc": "排序",
  "hellopay": {
    "error": {
      "1": "未知或其他错误",
      "2": "超时或中断，未找到卡片",
      "3": "无效或损坏的卡片",
      "4": "卡片余额不足",
      "5": "插入了错误的卡片",
      "6": "无法撤销，因为不是最后一次交易",
      "7": "不是HelloPay卡",
      "8": "卡片通信错误",
      "9": "平板通信错误",
      "10": "用户处于菜单中",
      "11": "外部标识符验证失败",
      "12": "交易加载失败",
      "13": "权限不足",
      "14": "终端状态不正确（由OTP/Unipass控制）"
    }
  },
  "saltplay": {
    "error": {
      "CANCELLING": "正在取消卡片支付",
      "CANCELLED": "信用卡支付已取消",
      "FAILED": "卡片支付失败",
      "NEW": "卡片支付失败，终端未响应"
    }
  },
  "reprint_receipt": "您真的想重新打印收据吗？",
  "cashier_open_amount": "开启现金",
  "cashier_dayopen": "开班",
  "cashier_dayclose": "关班",
  "storno": "冲销",
  "stornoreason_1": "客户取消",
  "stornoreason_2": "输入错误",
  "stornoreason_3": "输入错误的支付方式",
  "stornoreason_4": "产品缺货",
  "stornoreason_5": "发票类型错误",
  "stornoreason_6": "支付方式失败",
  "stornoreason_7": "客户数据错误/输入错误",
  "stornoreason_8": "测试购买",
  "stornoreason_0": "其他",
  "ap": "AP号",
  "receiptNumber": "收据号",
  "customerTaxNum": "客户税号",
  "customerName": "客户姓名",
  "customerPostCode": "客户邮政编码",
  "customerTown": "客户城市",
  "customerAddress": "客户地址",
  "dont_print": "不打印",
  "history": "历史",
  "action_not_allowed": "您没有执行此操作的权限",
  "already_paid_reprint_invoice": "账单已结清！您真的想重新打印发票吗？",
  "documentation": "文档",
  "user_comment": "其他客人信息",
  "booking_comment": "备注",
  "channels": {
    "console": "控制台",
    "webshop_homedelivery": "网上商店",
    "webshop_pickup": "网上商店",
    "webshop_onsite": "网上商店",
    "wolt": "Wolt",
    "foodora": "Foodora",
    "falatozz": "Falatozz",
    "console_homedelivery": "",
    "console_pickup": ""
  },
  "auto_translate": "自动翻译",
  "webshopOrder": "网上商店订单",
  "webshop": "网上商店",
  "authenticate": "认证",
  "reauthenticate": "重新认证",
  "store": "商店",
  "terminal": "终端",
  "choose_terminal_to_use": "选择使用的银行终端",
  "restart_printerserver": "重新连接打印机",
  "reboot_printerserver": "重新启动本地打印机服务器",
  "restart_printer": "请重启以下打印机: {0}",
  "restart_needed": "(重新)启动打印机",
  "External HealthCheck: Successful": "打印机看起来正常",
  "Internal HealthCheck: Successful": "打印机看起来正常",
  "charwidth": "字符宽度",
  "the_table_is_not_occupied": "这个桌子是空的！",
  "do_you_want_to_catch_incoming_calls": "您是否希望我们使用来电识别为此电话捕捉外卖/自取数据？如果是，请在浏览器中使用相同的用户登录！",
  "upload_successful": "上传成功",
  "pointout": "积分累积",
  "pointin": "积分兑换",
  "if": "条件",
  "waiterOrder": "服务员订单",
  "customerOrder": "消费者订单（网上商店）",
  "localConsumption": "本地消费",
  "takeawayConsumption": "外卖消费",
  "default_cashregister_change": "<strong>{0}</strong> 应该是这台机器的默认收银机吗？",
  "container_charge": "容器费",
  "label_count": "标签数量",
  "tooltip_label_count": "使用标签打印机时，为此产品打印的标签数量",
  "package_charge": "包装费",
  "action_failed": "操作失败。可能是某些内容未更新；让我们同步并再次尝试操作！",
  "container_charge_onsite": "本地消费时也收费",
  "id": "ID",
  "product_search": "产品搜索",
  "reprint": "重新打印",
  "print_qrcode": "打印二维码",
  "minimum_price": "最终价格不能低于基础价格，即使在下单时关闭默认选项。例如，披萨即使去掉了某些配料，价格也不能变便宜。",
  "stockAnalitics": "库存分析",
  "productAnalitics": "产品分析",
  "cartsize": "购物车大小",
  "customer_orders": "新客户订单",
  "deliverable_tables": "已准备好的餐点",
  "assistance_needed": "叫服务员",
  "fiscal_receipt_already_printed": "此财政收据已打印（收据号：{0}）",
  "multiplicator": "倍数",
  "_multiplicator": "倍",
  "invoice_count": "原始数量",
  "auto_import_incoming_stocks": "自动入库",
  "auto_commit_imcoming_stocks": "自动提交",
  "invoice_not_found": "在线发票系统中未找到发票号",
  "online_szamla_feature": "您的订阅中当前未激活在线发票模块。该模块可以下载在在线发票系统中收到的增值税发票，并根据需要自动/半自动记录。",
  "receipe": "食谱",
  "mandatory_recipe": "产品必须包含的成分。例如，披萨底部。",
  "included_recipe": "产品中包含的成分，但可以在订购时去除或替换。例如，卡布奇诺中的牛奶可以用乳糖不耐症牛奶替换。",
  "optional_recipe": "在订购时可以增强产品或替代默认成分的额外选项。",
  "auto_import_incoming_stocks_tooltip": "根据供应商的到货发票自动生成库存条目。",
  "auto_commit_incoming_stocks_tooltip": "根据供应商的到货发票自动生成最终（非草稿）库存条目，并即时更新库存。",
  "no_permission_to_perform_this_action": "您没有执行此操作的权限。",
  "events": "事件",
  "lastSale": "最后使用",
  "lastBuy": "最后购买",
  "lastInventory": "最后库存",
  "nextInventory": "下次计划库存",
  "criticalStock": "危及业务运营的临界数量",
  "minimumStock": "通常进行补货时的最小数量",
  "refillStock": "通常补货时的目标数量",
  "below_criticalStock": "产品低于临界数量，危及业务运营",
  "below_minimumStock": "产品低于最小数量，通常会进行补货",
  "daysAgo": "{0}天前",
  "inDays": "{0}天后",
  "monthsAgo": "{0}个月前",
  "inMonths": "{0}个月后",
  "yearsAgo": "{0}年前",
  "inYears": "{0}年后",
  "new": "新",
  "refresh": "刷新",
  "differences_in_additions": "配方选项差异",
  "differences_in_name": "命名差异",
  "differences_in_availableQuantities": "可用数量差异",
  "differences_in_unitPrice": "单价差异",
  "pickupyourorder": "您的订单已准备好",
  "add_user": "新增用户",
  "add_role": "新增角色",
  "courier_off": "下班",
  "courier_ready": "等待送货",
  "courier_pause": "暂停",
  "courier_delivering": "正在送货",
  "courier_delivery_continue": "继续送货",
  "courier_delivery_pause": "暂停送货",
  "package": "包裹",
  "delivery_count": "送货数量",
  "delivery_addresses": "地址数量",
  "delivery_distance": "距离（公里）",
  "delivery_avg_time": "平均时间",
  "confirm_position_update": "刷新地址的GPS坐标",
  "refresh_position": "刷新",
  "distance_to_big": "距离该位置设置的地址超过{0}公里！您现在真的是在此地址吗？如果是，我们是否根据您当前位置更新地址的GPS坐标？",
  "delivered_refresh_position": "已交付并更新GPS",
  "getting_location": "正在确定您当前的位置...",
  "phone_order_fee": "电话订单系统使用费",
  "webshop_order_fee": "网上商店订单系统使用费",
  "order_fee": "系统使用费",
  "enter_phone_number": "输入电话号码",
  "not_selected": "未选择",
  "voipphone-Registration failed:Authentication Error": "注册失败：认证错误",
  "voipphone-undefined": "离线",
  "do_you_really_delete_the_customer": "您真的想删除此客户吗？",
  exit_rearrange_mode: "退出重新排列模式",
  rearrange_fields: "重新排列字段",
  reset_to_defaults: "重置为默认值",
};
export default admin_local_cn;

